import { useQuery } from "@apollo/client";


const useQueryTaxonomies = (query) => {

  const { loading: loadingQuery, error: errorQuery, data } = useQuery(query, {
    variables: {

    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  return {
    loadingQuery,
    errorQuery,
    dataQuery: data && data.taxonomies,
  }
};

export default useQueryTaxonomies;


