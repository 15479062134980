import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';


const useQueryShowrooms = (query) => {



  let history = useHistory();
  const { eventId, exhibitionEventTab, numPage } = useParams();

  let routePage;
  const exbnEvntStands = eventId && {
    column: "EE_ID",
    value: 1,
  }

  if (eventId === undefined)
  {
    routePage = `/showrooms/`;
  } else
  {
    routePage = `/exhibition-events/${ eventId }/${ exhibitionEventTab }/`
  }

  const [ pageSize, setPageSize] = useState(10);
  const [ searchVal, setSearchVal ] = useState("");
  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });

  const [ filter, setFilter ] = useState({ ...exbnEvntStands });


  const { loading, error, data } = useQuery(query, {
    variables: {
      ...order,
      where: { ...filter },
      text: searchVal,
      first: pageSize,
      page: +numPage ? +numPage : 1
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { stands } = !!data && data;
  const { paginatorInfo } = !!data && stands;
  const { total, currentPage } = !!data && paginatorInfo;

  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter, page) => {

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;

    const value = (Object.values(filters)[ 0 ] && Object.values(filters)[ 0 ][ 0 ]);

    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });


    if (value !== null)
    {
      setFilter({
        ...filter,
        AND: [
          {
            column: "STATUS",
            value
          }
        ]
      })
    } else
    {
      setFilter({ ...exbnEvntStands })
    }


    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    eventId,
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: stands,
    setSearchVal,
    routePage,
    history,
    pagination,
    handleTableChange
  }
};

export default useQueryShowrooms;
