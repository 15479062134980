import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";


const useMutationExbnMetaDelete = (mutation, dataQuery) => {


  const [ _setMutationExbnMetaDelete,
    { loading, error } ] = useMutation(mutation,
        {
          update(cache, { data }) {

            const {
              eeMetaDelete: {
                label,
                message,
              }
            } = data;


            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
        });

  return {
    _setMutationExbnMetaDelete,
    loadingExbnMetaDelete: loading,
    errorMutationExbnMetaDelete: error,
  }
};

export default useMutationExbnMetaDelete;