import React from "react";
import UserProfileForm from '../../../forms/user-form/user-profile-form';

const ProfileTab = ({ getUser, loadingUser, reFetch }) => {


  return (
    <>
      <UserProfileForm loadingUser={ loadingUser } reFetch={ reFetch } getUser={ getUser } />
    </>
  );
};

export default ProfileTab;