import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";


const useMutationProductCreateAndMetaSet = (mutation, dataQuery) => {

  const [ _setMutationProductCreateAndMetaSet,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            productMetaSet: {
              meta_fields
            },
            productCreateOrUpdate: {
              label,
              message,
              product
            }

          } = data;


          cache.modify({
            id: cache.identify(dataQuery),
            fields: {
              meta_fields() {
                return meta_fields;
              },
              product() {
                return product;
              }
            }
          });


          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationProductCreateAndMetaSet,
    loadingMutationProductCreateAndMetaSet: loading,
  }
};

export default useMutationProductCreateAndMetaSet;