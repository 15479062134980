import React from "react";
import NormalProfileForm from './normal-profile-form';
import useMutationMetaSet from './hooks/use-mutation-meta-set';


import { USER_META_SET } from '../../../../graphql/mutation/user-gql'

import './user-profile-form.scss';


const UserProfileForm = ({ getUser, loadingUser }) => {

  const {
    _setMutationVariable,
    loadingMutationMetaSet,
  } = useMutationMetaSet(USER_META_SET, getUser);




  return (
    <NormalProfileForm
      loadingUser={ loadingUser }
      loadingMutationMetaSet={ loadingMutationMetaSet }
      getUser={ getUser }
      setMutationVariable={ _setMutationVariable }
    />
  );
};

export default UserProfileForm;
