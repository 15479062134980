import React from "react";
import { Layout, Select } from 'antd';
import TranslationKeysTable from "./translation-keys-table";
import SearchForm from "../../forms/search-form"
import TranslationKeysForm from '../../forms/localization-forms/translation-keys-form';
import EditModal from './../../service/edit-modal';
import useQueryKeys from './hooks/use-query-keys';
import { GET_TRANSLATION_KEYS } from "../../../graphql/query/languages-gql";

import './translation-keys.scss';


const { Sider, Content } = Layout;
const { Option } = Select;


const TranslationKeys = () => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
    history,
    setSearchVal,
    setSearchKey,
    routePage,
    pagination,
    handleTableChange
  } = useQueryKeys(GET_TRANSLATION_KEYS);


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Translation keys</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <EditModal valueBtn={ 'Add key' } titleModal={ 'Create Translation Key' }>
            <TranslationKeysForm dataQuery={ dataQuery } loadingQuery={ loadingQuery } />
          </EditModal>
          <Select
            onChange={
              (value) => {
                setSearchKey(`${ value }`);
              }
            }
            showSearch
            style={ { width: 165 } }
            placeholder="Search by"
            optionFilterProp="children"
          >
            <Option value="key">Key</Option>
            <Option value="value">Translation</Option>
          </Select>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>
      <TranslationKeysTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        handleTableChange={ handleTableChange }
      />
    </>
  );
};

export default TranslationKeys;

