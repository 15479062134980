import React from "react";
import { Form, Select, Spin } from "antd";
import useQueryTerms from './hooks/use-query-terms';
import { GET_TERMS } from '../../../graphql/query/terms-gql';

const { Option } = Select;


const TaxanomyInput = ({
  id,
  name,
  initialValue,
  placeholder = '',
  staticTitle = {
    condition: false,
    title: ''
  }
}) => {



  let {
    dataQuery,
    loadingQuery,
  } = useQueryTerms(GET_TERMS, id);


  const { terms = [] } = !loadingQuery && dataQuery;
  const taxonomyTitle = terms.length !== 0 ? terms[ 0 ].taxonomy.title : false


  const termsItem = terms.map(
    ({ id, title }) => {
      return (
        <Option
          key={ id }
          label={ title }
        >
          { title }
        </Option>
      )
    }
  )


  return (
    <>
      {loadingQuery &&
        <div className="block-loader">
          <Spin size="small" />
        </div>
      }
      {
        !!taxonomyTitle && (
          <Form.Item
            name={ name }
            label={ `${ staticTitle.condition ? staticTitle.title : taxonomyTitle }` }
            initialValue={ initialValue }>

            <Select
              mode="multiple"
              dropdownClassName="taxonomy-dropdown"
              allowClear
              placeholder={ `${ placeholder } ${ staticTitle.condition ? staticTitle.title : taxonomyTitle }` }
              optionFilterProp={ "label" }
            >
              { termsItem }
            </Select>

          </Form.Item>
        )
      }
    </>
  )
}

export default TaxanomyInput;

