import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";


const useMutationStandDelete = (mutation) => {

  const history = useHistory();

  const [ _setMutationStandDelete, { loading } ] = useMutation(mutation,
    {
      update(cache, { data }) {

        const {
          standDelete: {
            label,
            message
          }
        } = data;

        history.push(`/showrooms/`);

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
    });

  return {
    _setMutationStandDelete,
    loadingStandDelete: loading,
  }
};

export default useMutationStandDelete;