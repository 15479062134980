import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";

const useMutationProductCreateUpdate = (mutation, dataQuery) => {

  let history = useHistory();

  const [ _setMutationProductCreateUpdate,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            productCreateOrUpdate: {
              label,
              message,
              product
            }
          } = data;


          if (!!dataQuery)
          {
            cache.modify({
              id: cache.identify(dataQuery),
              fields: {
                product() {
                  return product;
                },
              },
            });
          }

          successNotification({
            title: label.toUpperCase(),
            description: message
          });

          history.push(`/products/${product.id}`);
        },
      });




  return {
    _setMutationProductCreateUpdate,
    loadingMutationProductCreateUpdate: loading,
  }
};

export default useMutationProductCreateUpdate;