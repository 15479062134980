import React from 'react';
import Icon from '@ant-design/icons';


const ArrowSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M24.6,31.4c-0.4,0-0.7-0.1-1-0.4L11.1,19.5C10.4,19,10.4,18,11,17.4c0.6-0.6,1.5-0.7,2.1-0.1l11.6,10.6l11.6-10.6
        	c0.6-0.6,1.6-0.5,2.1,0.1c0.6,0.6,0.5,1.6-0.1,2.1L25.7,31C25.4,31.3,25,31.4,24.6,31.4z"
        />
    </svg>
);

const ArrowFilledSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <polygon points="24.6,21.7 32.1,21.7 24.6,30.7 17.1,21.7 "/>
    </svg>
);

const ArrowIcon = props => {

    if(props.type === "filled"){
        return <Icon className={ props.className } component={ ArrowFilledSvg } />;
    }

    return <Icon className={ props.className } component={ ArrowSvg } />;

};


export default ArrowIcon;

