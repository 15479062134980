import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";
import { GET_USER } from './../../../../../graphql/query/user-gql';

const useMutationMetaSet = (mutation, getUser) => {

  const [ _setMutationVariable,
    { loading: loadingMutationMetaSet,
      error: errorMutation, data } ] = useMutation(mutation,
        {
          update(_, { data }) {

            const {
              userMetaSet: {
                label,
                message
              }
            } = data;

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
          refetchQueries: [
            {
              query: GET_USER,
              variables: { userAdmId: getUser.id },
            },
          ]
        });


  return {
    _setMutationVariable,
    loadingMutationMetaSet,
    errorMutation,
    dataMutation: data && data.nameMutatiom
  }
};

export default useMutationMetaSet;