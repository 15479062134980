import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
// import metaFields from '../../../../utils/meta-fields';

const useMutationStandMetaUpload = (mutation, dataQueryStand, form) => {

  // const { normalize } = metaFields;

  const [ _setMutationStandUpload,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            standMetaUpload: {
              label,
              message,
              stand: {
                logo,
                meta_fields
              },
            }
          } = data;

          // const meta = normalize(meta_fields);

          cache.modify({
            id: cache.identify(dataQueryStand),
            fields: {
              stand: {
                logo() {
                  return logo;
                },
                meta_fields() {
                  return meta_fields;
                },
              }

            },
          });

          if (form)
          {
            form.setFieldsValue({
              "company_logo-image-unset": logo,
              // "company_image_1-image-unset-page_headers": meta.company_image_1.meta_value,
              // "page_image_1-image--about_company": meta.page_image_1.meta_value
            });
          }


          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationStandUpload,
    loadingMutationStandUpload: loading,
  }
};

export default useMutationStandMetaUpload;
