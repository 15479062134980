import React from "react";
import RequestHandler from "../../request-handler"
import { Form, Select } from "antd";

//Hooks
import useQueryExbnEvnts from './hooks/use-query-exbn-evnts';

//Query
import { GET_EXBN_EVNT_ALL } from '../../../graphql/query/exhibition-gql';

//CSS
import './select-exbn-evnt.scss';



const SelectExbnEvnt = ({ disabledSelect, eventStatus = true }) => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
  } = useQueryExbnEvnts(GET_EXBN_EVNT_ALL);


  const { Option } = Select;

  const selectSearchField = dataQuery && (
    dataQuery.map((item) => {
      return (
        <Option key={ item.id } value={ item.id }>
          { item.title }
          <span>; Status: { item.status }</span>
        </Option>
      )
    })
  );


  return (
    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQuery }
    >
      <Form.Item

        name="exhibitionEvent"
        label="Exhibition event"
        initialValue={ eventStatus || 0 }
        rules={ !disabledSelect && [ { required: true, message: 'Please select exhibition event' } ] }
      >
        <Select
          className="select-input-space-between"
          dropdownClassName="select-dropdown-space-between"
          disabled={ disabledSelect }
          showSearch
          placeholder="Select exhibition event"
          optionFilterProp="children"
        >
          {
            !eventStatus && (
              <Option key="0" value={ 0 }>
                <span>No event</span>
              </Option>
            )
          }
          { selectSearchField }
        </Select>
      </Form.Item>
    </RequestHandler>
  )
};

export default SelectExbnEvnt;