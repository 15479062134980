import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";

const useMutationStandCreateUpdate = (mutation, dataQuery) => {

  let history = useHistory();
  const { standTab } = useParams();

  const [ _setMutationVariableCreateUpdate,
    { loading: loadingMutationCreateUpdate,
      error: errorMutationCreateUpdate } ] = useMutation(mutation,
        {
          update(cache, { data }) {
            const {
              standCreateOrUpdate: {
                label,
                message,
                stand
              }
            } = data;


            cache.modify({
              id: cache.identify(dataQuery),
              fields: {
                stand() {
                  return stand;
                },
              },
            });

            successNotification({
              title: label.toUpperCase(),
              description: message
            });

            !standTab && history.push(stand.id);
          },
        });


  return {
    _setMutationVariableCreateUpdate,
    loadingMutationCreateUpdate,
    errorMutationCreateUpdate,
  }
};

export default useMutationStandCreateUpdate;