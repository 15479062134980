import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import {  ApolloProvider } from "@apollo/client";

import client from "./graphql/client";

import App from "./components/app";
import Authentication from "./components/authentication";
import ScrollToTop from "./components/service/scrollToTop";


import  './scss/vendors/antd/antd.less';
import  './scss/default.scss';


ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <Authentication>
        <ScrollToTop />
        <App />
      </Authentication>
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);
