import React from "react";
// import { Link } from "react-router-dom";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Button } from "antd";
import logo from "../../../assets/desktop_logo.png";


// Hooks
import useMutationLogin from './hooks/use-mutation-login';

//Mutation
import { USER_LOGIN } from "../../../graphql/mutation/user-gql";

//CSS
import "./login-form.scss";

const NormalLoginForm = ({ loginLoading, loginRequest, setAuthToken }) => {

  const [ form ] = Form.useForm();


  const formSubmit = ({ values }) => {

    if (!!values)
    {
      loginRequest({
        variables: {
          userLoginAdmInput: {
            username: values.username,
            password: values.password
          }
        }
      });
    }
  };

  return (
    <div className="form-holder">
      <div className="logo-img">
        <img src={ logo } alt="logo" />
      </div>
      <h2>
        Sign in
        </h2>
      <Form
        className="login-form"
        form={ form }
        onFinish={ (values) => {
          formSubmit({ values, loginRequest, setAuthToken });
        } }
      >
        <Form.Item
          name="username"
          rules={ [
            { required: true, message: "Please input your username!" }
          ] }
        >
          <Input
            prefix={ <UserOutlined className="form-icon" /> }
            type="string"
            placeholder="Username"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={ [
            { required: true, message: "Please input your Password!" }
          ] }
        >
          <Input.Password
            prefix={ <LockOutlined className="form-icon" /> }
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          {/* <Link to="/forgot-password/" className="login-form-link">
            Forgot password?
            </Link> */}
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-submit"
            loading={ loginLoading }
          >
            Sign in
            </Button>
        </Form.Item>
      </Form>
      {/* <div className="divider">
                    <div /><div>OR</div><div />
                </div>
                <div className="sign-link-wrap">
                    <p>You don't have account? </p>
                    <Link to="/registration/" className="sign-link">Sign Up</Link>
                </div> */}
    </div>
  );
}

const LoginForm = (props) => {

  const {
    loading,
    loginRequest
  } = useMutationLogin(USER_LOGIN);


  return (

    <NormalLoginForm
      { ...props }
      loginRequest={ loginRequest }
      loginLoading={ loading }
    />

  );
};

export default LoginForm;
