// import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";
import { GET_PACKAGE_REQUESTS } from "../../../../../graphql/query/packages-gql";


const useMutationPackageRequest = (mutation, dataQuery) => {

  const { numPage } = useParams();

  const [ _setMutationPackageRequest,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            packageRequestUpdate: {
              label,
              message,
            }
          } = data;

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },

        refetchQueries: [
          {
            query: GET_PACKAGE_REQUESTS,
            variables: {
             status: 'waiting',
              page: +numPage ? +numPage : 1
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "cache-and-network",
          },
        ]
      });


  return {
    _setMutationPackageRequest,
    loadingMutationPackageRequest: loading,
  }
};

export default useMutationPackageRequest;