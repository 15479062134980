import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tabs, Typography } from "antd";
import EventAboutForm from './../../forms/exbn-evnt-form/event-about-form';
import EventSettingsForm from './../../forms/exbn-evnt-form/event-settings-form';
import EventThemeForm from './../../forms/exbn-evnt-form/event-theme-form';
import EventContactForm from '../../forms/exbn-evnt-form/event-contact-form';
import Showrooms from '../../showrooms/stands';
import { GET_EXBN_EVNT } from './../../../graphql/query/exhibition-gql';

import './exhibition-event-tabs.scss';




const ExhibitionEventTabs = () => {


    const { eventId = false, exhibitionEventTab } = useParams();
    let history = useHistory();

    const { data, loading } = useQuery(GET_EXBN_EVNT, {
        variables: {
            eventId
        },
        // fetchPolicy: "cache-and-network",
    });

    const { exhibition_event = false } = data !== undefined ? data : {};

    const { TabPane } = Tabs;

    return (
        <div className="tabs-form event-tabs">
            {
                !loading && (!exhibition_event ?
                    <h3>Add Exhibition Event</h3> :
                    <h3>Edit Exhibition Event:  <Typography.Link>{ exhibition_event.title }</Typography.Link></h3>)
            }

            <Tabs
                tabPosition="top"
                activeKey={ exhibitionEventTab ? exhibitionEventTab : 'about' }
                onTabClick={ (key) => {
                    changeTab(history, key, eventId);
                } }
            >

                <TabPane tab="About" key="about">
                    <EventAboutForm
                        exbnEvnt={ exhibition_event }
                        loadingQueryEvent={ loading } />
                </TabPane>
                { !!eventId && (
                    <>
                        <TabPane tab="Settings" key="settings">
                            <EventSettingsForm
                                exbnEvnt={ exhibition_event }
                                loadingQueryEvent={ loading } />
                        </TabPane>

                        <TabPane tab="Theme" key="theme">
                            <EventThemeForm
                                exbnEvnt={ exhibition_event }
                                loadingQueryEvent={ loading } />
                        </TabPane>

                        <TabPane tab="Contact & Legal" key="contact-legal">
                            <EventContactForm
                                exbnEvnt={ exhibition_event }
                                loadingQueryEvent={ loading } />
                        </TabPane>

                        <TabPane tab="Showrooms" key="showrooms">
                            <Showrooms eeId={ exhibition_event?.id } />
                        </TabPane>
                    </>
                ) }
            </Tabs>
        </div>
    );
};


export default ExhibitionEventTabs;


//Pure functions

const changeTab = (history, tabKey, eventId) => {

    if (!!eventId)
    {
        if (tabKey === 'about')
        {
            history.push(`/exhibition-events/${ eventId }`);
        } else
        {
            history.push(`/exhibition-events/${ eventId }/${ tabKey }`);
        }
    } else
    {
        history.push(`/exhibition-events/add-event`);
    }
};