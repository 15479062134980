import { useState, useEffect } from "react";
import metaFields from '../../../../utils/meta-fields';



const RadioValue = (dataQuery, metaKey, defaultVal = '0') => {


  const { getValue, normalize } = metaFields;
  const meta = normalize(dataQuery.meta_fields);

  const defaultRadioVal = getValue(meta, metaKey, defaultVal);
  const [ radioValue, setRadioValue ] = useState(defaultRadioVal);


  useEffect(() => {
    setRadioValue(defaultRadioVal)
  }, [ defaultRadioVal ]);


  return {
    radioValue,
    setRadioValue,
    defaultRadioVal
  }
}


export default RadioValue;


