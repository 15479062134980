import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";

const useMutationStandDeleteExhibitor = (mutation, dataQuery) => {

  const [ _setMutationVariableDeleteExhibitor,
    { loading: loadingMutationDeleteExhibitor,
      error: errorMutationDeleteExhibitor } ] = useMutation(mutation,
    {
      update(cache, { data }) {
        const {
          standDeleteExhibitor: {
            label,
            message,
            stand
          }
        } = data;

        cache.modify({
          id: cache.identify(dataQuery),
          fields: {
            stand() {
              return stand;
            },
          },
        });

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
    });


  return {
    _setMutationVariableDeleteExhibitor,
    loadingMutationDeleteExhibitor,
    errorMutationDeleteExhibitor,
  }
};

export default useMutationStandDeleteExhibitor;