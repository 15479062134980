import React from 'react';
import Icon from '@ant-design/icons';


const CheckSvg = () => (
    <svg fill="#fff"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M21.2,34L21.2,34c-0.4,0-0.8-0.2-1.1-0.4l-10-10c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l9,9l15.7-15.7
	        c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1L22.3,33.5C22,33.8,21.6,34,21.2,34z"
        />
    </svg>
);

const CheckIcon = props => {
    return <Icon component={ CheckSvg } />;

};


export default CheckIcon;