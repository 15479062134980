import React from "react";
import NormalCompanyPageForm from './normal-company-page-form';
// import useQueryStandMetaByGroup from '../hooks/use-query-stand-meta-group';
import useMutationStandMetaSet from '../hooks/use-mutation-stand-meta-set';


import {
  STAND_META_SET
} from '../../../../graphql/mutation/showroom-gql'


const CompanyPageForm = ({ dataQueryStand, loadingQueryStand }) => {

  const {
    _setMutationVariableStandMetaSet,
    loadingMutationStandMetaSet,

  } = useMutationStandMetaSet(STAND_META_SET, dataQueryStand);

  return (
    <NormalCompanyPageForm
      dataQueryStand={ dataQueryStand }
      loadingQueryStand={ loadingQueryStand }
      loadingMutationStandMetaSet={ loadingMutationStandMetaSet }
      setMutationVariableStandMetaSet={ _setMutationVariableStandMetaSet }
    />
  );
};


export default CompanyPageForm;
