import { gql } from "@apollo/client";

export const GET_AUTH_TOKEN = gql`
query GetAuthToken {
  authToken @client
}
`;

export const GET_ME = gql`
query GetMe {
  me {
    id
    status
    name
    surname
    email
    email_original
    locale
    role
    avatar
  }
}
`;


export const GET_USER = gql`
query GetUser($userAdmId: ID) {
  user(id: $userAdmId) {
    id
    email
    name
    surname
    email_original
    avatar
    locale
    status
    role
    created_at
    updated_at
    stand{
      id
      ee_id
      title
      logo
      meta_fields {
        id
        stand_id
        meta_key
        meta_value
        meta_type
      }
    }
    meta_fields {
      meta_key
      meta_value
    }
    exhibition_event {
      id
      title
      meta_fields {
        id
        ee_id
        meta_key
        meta_type
        meta_value
        meta_group
      }
    }
  }
}
`;


export const GET_USERS = gql`
  query GetUsers(
    $text: String,
    $first: Int,
    $page: Int,
    $orderBy: [QueryUsersOrderByOrderByClause!],
    $where: QueryUsersWhereWhereConditions
    ) {
      users(
      text: $text,
      first: $first,
      page: $page,
      orderBy: $orderBy,
      where: $where
    ) {
      data {
        id
        name
        avatar
        email
        created_at
        status
        role
        avatar
        email_original
        surname
        updated_at
        locale
        exhibition_event {
          id
          title
        }
        stand{
          id
          title
        }
      }
      paginatorInfo {
        total
        perPage
        lastPage
        currentPage
      }
    }
  }
`;

export const GET_USER_ROLES = gql`
query GetUserRoles {
  roles {
    id
    slug
    label
  }
}
`;


export const GET_USERS_MODERATOR = gql`
  query GetUsersModerator(
    $text: String,
    $where: QueryUsersWhereWhereConditions
    ) {
      users(
      text: $text,
      where: $where
    ) {
      data {
        id
        name
        surname
        email_original
      }
    }
  }
`;

