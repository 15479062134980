import React from "react";
import { LockOutlined } from '@ant-design/icons';
import { Button, Input, Form } from "antd";
import { errorNotification } from "../../result";
import useMutationChangePassword from './hooks/use-mutation-change-password';
import { USER_CHANGE_PASSWORD } from "../../../graphql/mutation/user-gql";

import './change-password-form.scss';



const NormalChangePasswordForm = ({ getUser, setMutationVarChangePass, loadingMutationChangePass }) => {

    const formItemLayout = {
        labelCol: { lg: { span: 4 } },
        wrapperCol: { span: 24, sm: { span: 20 } },
    };



    const formSubmit = ({ values, setMutationVarChangePass }) => {

        setMutationVarChangePass({
            variables: {
                userId: getUser.id,
                userInput: {
                    new_password: values.password,
                    new_password_confirmation: values.confirm
                }
            }
        }).catch((errorMutation) => {
            errorNotification(errorMutation);
        });
    };


    return (
        <Form
            className="profile-form"
            layout="vertical"
            onFinish={ (values) => {
                formSubmit({ values, setMutationVarChangePass });
            } }
        >
            <Form.Item
                label="New"
                name="password"
                rules={ [
                    { min: 8, message: 'Password must be at least 8 characters' },
                    { required: true, message: 'Please input new Password' },
                ] }
                { ...formItemLayout }
                hasFeedback
            >
                <Input.Password
                    prefix={ <LockOutlined className="form-icon" /> }
                    type="password"
                    placeholder="Enter new password"
                />
            </Form.Item>

            <Form.Item
                label="Confirm"
                name="confirm"
                dependencies={ [ 'password' ] }
                hasFeedback
                rules={ [
                    {
                        required: true,
                        message: 'Please confirm Password'
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value)
                            {
                                return Promise.resolve();
                            }

                            return Promise.reject('The two passwords that you entered do not match!');
                        },
                    }),
                ] }
                { ...formItemLayout }
            >
                <Input.Password
                    prefix={ <LockOutlined className="form-icon" /> }
                    type="password"
                    placeholder="Repeat new password"
                />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={ loadingMutationChangePass }
                    type="primary"
                    htmlType="submit"
                    style={ { marginTop: "10px" } }>
                    Change Password
                </Button>
            </Form.Item>
        </Form>
    );
}

const ChangePasswordForm = ({ getUser }) => {


    const {
        _setMutationVarChangePass,
        loadingMutationChangePass,

    } = useMutationChangePassword(USER_CHANGE_PASSWORD);

    return (
        <>
            <NormalChangePasswordForm
                getUser={ getUser }
                setMutationVarChangePass={ _setMutationVarChangePass }
                loadingMutationChangePass={ loadingMutationChangePass }
            />
        </>
    )
}

export default ChangePasswordForm;