import React from "react";
import { Link } from "react-router-dom";
import { Table, Typography, Button } from "antd";
import mobileSize from "../../../../utils/mobile-size";
import SelectExbnEvnt from '../../../forms/select-exbn-evnt';
import TableColumnSelectFilter from '../../../service/table-column-select-filter';

import "./packages-table.scss"


const { Column } = Table;
const { Text } = Typography;





const PackagesTable = ({
  dataQuery,
  loadingQuery,
  pagination,
  handleTableChange,
  setExhibitionEventId }) => {


  return (
    <div className="holder-table packages-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        // scroll={{ x: 800, y: 300 }}
        loading={ loadingQuery }
        dataSource={ dataQuery }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" sorter="true" dataIndex="id" key="id" />
        <Column title="Title" key="title" render={
          item => !item.title ?
            <Link
              to={ {
                pathname: `/packages/${ item.id }`,
                state: {
                  breadcrumbSlug: 'Product name is not filled'
                }
              } }
            >
              <Text type="secondary">Product name is not filled</Text>
            </Link> :
            <Link
              to={ {
                pathname: `/packages/${ item.id }`,
                state: {
                  breadcrumbSlug: item.title
                }
              } }
            >
              { `${ item.title }` }
            </Link> } />

        <Column title="Description" dataIndex="description" key="description" />
        <Column title="Price" dataIndex="price" key="price" />

        { TableColumnSelectFilter({
          setFilter: setExhibitionEventId,
          columnTitle: 'Exhibition event',
          columnKey: 'Exhibition event',
          columnSelectFilter: <SelectExbnEvnt />,
          columnRender: item =>
            <Link
              to={ {
                pathname: `exhibition-events/${ item.exhibition_event?.id }`,
                state: {
                  breadcrumbSlug: item.exhibition_event?.title
                }
              } }
            >
              { `${ item.exhibition_event?.title }` }
            </Link>
          ,
          columnFormOnFinish: (values, setFilter) => {
            setFilter(values.exhibitionEvent)
          },
        })
        }

        <Column title="PDF" render={ item => !item.pdf ? 'No' : 'Yes' } />
        <Column title="Created" sorter="true" dataIndex="created_at" key="created_at" />
        <Column title="Action" render={ item =>
          <Link
            to={ {
              pathname: `/packages/${ item.id }`,
              state: {
                breadcrumbSlug: item.title
              }
            } }
          >
            <Button type="primary">Edit</Button>
          </Link> } />
      </Table>
    </div>
  );
};

export default PackagesTable;

