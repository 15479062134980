import React from "react";
import { Form, Select } from "antd";
import metaFields from '../../../../utils/meta-fields';
import './exbn-products-form.scss';


const { Option } = Select;



const ExbnProductsForm = ({ metaExbnEvent }) => {


  const { getValue, normalize } = metaFields;
  const meta = normalize(metaExbnEvent.meta_fields);


  return (

    <>
      <Form.Item
        name="product_types-string--ee_products"
        label="Product types"
        initialValue={ getValue(meta, "product_types").length ? getValue(meta, "product_types") : [] }
      >
        <Select
          mode="multiple"
          allowClear
          placeholder="Please select Product types"
        >
          <Option value="book">Book</Option>
          <Option value="author">Author</Option>
          <Option value="product">Product</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="product_types_platinum-string--ee_products"
        label="Product types (platinum)"
        initialValue={ getValue(meta, "product_types_platinum").length ? getValue(meta, "product_types_platinum") : [] }
      >
        <Select
          mode="multiple"
          allowClear
          placeholder="Please select platinum Product types"
        >
          <Option value="book">Book</Option>
          <Option value="author">Author</Option>
          <Option value="product">Product</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="product_extra_blocks-string--ee_products"
        label="Product extra blocks"
        initialValue={ getValue(meta, "product_extra_blocks").length ? getValue(meta, "product_extra_blocks") : []}
      >
        <Select
          mode="multiple"
          allowClear
          placeholder="Please select Product extra blocks"
        >
          <Option value="top">Top</Option>
          <Option value="author">Author</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="product_extra_blocks_platinum-string--ee_products"
        label="Product extra blocks (platinum)"
        initialValue={ getValue(meta, "product_extra_blocks_platinum").length ? getValue(meta, "product_extra_blocks_platinum") : [] }
      >
        <Select
          mode="multiple"
          allowClear
          placeholder="Please select platinum Product extra blocks"
        >
          <Option value="top">Top</Option>
          <Option value="author">Author</Option>
        </Select>
      </Form.Item>
    </>
  );
}

export default ExbnProductsForm;
