import { gql } from "@apollo/client";


export const STAND_CREATE_UPDATE = gql`
mutation StandCreateOrUpdate($standInput: StandCreateOrUpdateAdminInput!) {
  standCreateOrUpdate(input: $standInput) {
    label
    message
    stand {
      id
      title
      status
      description
      keywords
      exhibitors {
       id
       name
       email
       role
       stand_permission {
        visible
        permission
      }
    }
  }
}
}`;


export const STAND_META_SET = gql`
mutation StandMetaSet(
$stand_id: ID!,
$input: [StandMetaInput]
){
  standMetaSet(
  stand_id: $stand_id,
  input: $input
  ){
    label
    message
    stand {
      id
      ee_id
    }
    meta_fields {
      meta_key
      meta_value
      meta_group
    }
  }
}
`;


export const STAND_CREATE_AND_META_SET = gql`
mutation StandCreateAndMetaSet(
$stand_id: ID!,
$metaInput: [StandMetaInput]
$standInput: StandCreateOrUpdateAdminInput!
$term_id: [ID!]
){

  standMetaSet(
  stand_id: $stand_id,
  input: $metaInput
  ){
    label
    message
    stand {
      id
      ee_id
    }
    meta_fields {
      meta_key
      meta_value
      meta_group
    }
  }

  standCreateOrUpdate(input: $standInput) {
    label
    message
    stand {
      id
      title
      status
      description
      keywords
    }
  }

  termAddStand(
  stand_id: $stand_id,
  term_id: $term_id
  ){
    label
    message
    terms {
      id
      title
      taxonomy_id
    }
  }
}
`;

export const STAND_META_UPLOAD = gql`
mutation StandMetaUpload($input: StandUploadFileInput) {
  standMetaUpload(input: $input) {
    label
    message
    stand {
      id
      ee_id
      logo
      keywords
      showcase
      description
      title
      status
      meta_fields {
        id
        stand_id
        meta_key
        meta_type
        meta_value
        meta_group
      }
    }
  }
}`
;

export const STAND_DELETE_EXHIBITOR = gql`
mutation StandDeleteExhibitor (
$stand_id: ID!,
$user_id: ID!
){
  standDeleteExhibitor(
  stand_id: $stand_id,
  user_id: $user_id
  ){
    label
    message
    stand {
      id
      ee_id
      exhibitors{
        id
      }
    }
  }
}`
;


export const STAND_DELETE = gql`
mutation StandDelete($stand_id: ID!){
  standDelete(stand_id: $stand_id){
    label
    message
  }
}
`;
