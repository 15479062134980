import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
import { GET_TAXONOMY } from "../../../../graphql/query/taxonomies-gql";



const useMutationTermDelete = (mutation, dataQuery) => {

  dataQuery = dataQuery && dataQuery

  const [ _setMutationTermDelete,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {

          const {
            taxonomyTermDelete: {
              label,
              message
            }
          } = data;


          const filteredArr = dataQuery.taxonomy_terms.filter(
            (item) => item.id !== dataQuery.taxonomy_terms.id
          )


            cache.writeQuery({
              query: GET_TAXONOMY,
              variables: {
                id: dataQuery.id
              },
              data: {
                taxonomy: {
                  taxonomy_terms: [
                    ...filteredArr
                  ],
                  __typename: dataQuery.__typename
                }
              }
            })

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });

  return {
    _setMutationTermDelete,
    loadingTermDelete: loading,
  }
};

export default useMutationTermDelete;