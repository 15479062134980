import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Avatar } from "antd";
import { FileImageOutlined } from '@ant-design/icons';

import mobileSize from "../../../utils/mobile-size";
import isEmpty from 'lodash/isEmpty';


import "./user-table.scss"


const { Column } = Table;


const UserTable = ({ pagination, users, loading, handleTableChange }) => {

  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table user-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        // scroll={{ x: 800, y: 300 }}
        loading={ loading }
        dataSource={ users.data }
        rowKey={ user => user.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" sorter="true" dataIndex="id" key="id" />

        <Column
          className="table-avatar-link"
          title="Avatar"
          render={ user => {

            return (
              <Link
                to={ {
                  pathname: `/users/id-${ user.id }-${ user.name }-${ user.surname }`.toLowerCase(),
                  state: {
                    // breadcrumbSlug: `${ user.name } ${ user.surname }`
                  }
                } }>
                { !user.avatar ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ user.avatar } /> }
              </Link>
            )
          } } />

        <Column title="Full name" sorter="true" key="name" render={ user =>
          <Link
            to={ {
              pathname: `/users/id-${ user.id }-${ user.name }-${ user.surname }`.toLowerCase(),
              state: {
                // breadcrumbSlug: `${ user.name } ${ user.surname }`
              }
            } }>
            { `${ user.name } ${ user.surname }` }
          </Link>
        } />
        <Column title="E-Mail" dataIndex="email_original" key="email_original" />

        <Column title="Exhibition event" key="event"
          render={
            user => {
              const eventStatus = (!isEmpty(user.exhibition_event));
              const arrExbEvnt = eventStatus && user.exhibition_event.map(item =>
                (
                  <Link
                    key={ item.id }
                    to={ {
                      pathname: `/exhibition-events/${ item.id }`,
                      state: {
                        breadcrumbSlug: item.title
                      }
                    } }>
                    { `${ item.title }` }
                  </Link>
                )
              );

              return (
                arrExbEvnt
              )
            }
          }
        />

        <Column title="Showroom" key="event" className="column-description-core"
          render={
            user => {
              const standStatus = (!isEmpty(user.stand));
              const arrStand = standStatus && user.stand.map(item =>
                (
                  <Link
                    key={ item.id }
                    to={ {
                      pathname: `/showrooms/${ item.id }`,
                      state: {
                        breadcrumbSlug: item.title
                      }
                    } }
                  >
                    { `${ item.title }` }
                  </Link>
                )
              );

              return (
                arrStand
              )
            }
          }
        />
        <Column title="Status" sorter="true" dataIndex="status" key="status" />
        <Column title="Role" dataIndex="role" key="ROLE"
          filterMultiple={ false }
          filters={ [
            { text: "Administrator", value: "admin" },
            { text: "Editor", value: "editor" },
            { text: "Exhibitor", value: "exhibitor" },
            { text: "Visitor", value: "visitor" }
          ] }
        />
        <Column title="Created" sorter="true" dataIndex="created_at" key="created_at" />
        <Column title="Action" render={ user => <Link to={ `/users/id-${ user.id }-${ user.name }-${ user.surname }`.toLowerCase() }><Button type="primary">Edit</Button></Link> } />
      </Table>
    </div>
  );
};

export default UserTable;