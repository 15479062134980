import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  UserOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  BarChartOutlined,
  GlobalOutlined
} from '@ant-design/icons';

import { Menu } from "antd";
import "./main-menu.scss";

const { SubMenu } = Menu;


const MainMenu = ({ menuToggle }) => {

  const { location: { pathname } } = useHistory();
  const openKeys = pathname.split('/')[ 1 ];
  const selectedKeys = pathname.split('/')[ 2 ] || pathname;


  return (
    <Menu className="main-menu" mode="inline"
      defaultOpenKeys={ [ openKeys ] }
      defaultSelectedKeys={ selectedKeys }
    >
      <Menu.Item key="dashboard"
        onClick={ menuToggle }
      >
        <Link to="/">
          <HomeOutlined />
          <span>Dashboard</span>
        </Link>
      </Menu.Item>

      <SubMenu
        key="users"
        title={
          <span>
            <UserOutlined />
            <span>Users Setup</span>
          </span>
        }
      >
        <Menu.Item key="/users" onClick={ menuToggle }>
          <Link to="/users">All users</Link>
        </Menu.Item>
        <Menu.Item key="add-user" onClick={ menuToggle }>
          <Link to="/users/add-user">Add user</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="exhibition-events"
        title={
          <span>
            <UnorderedListOutlined />
            <span>Exhibition Event</span>
          </span>
        }
      >
        <Menu.Item key="/exhibition-events" onClick={ menuToggle }>
          <Link to="/exhibition-events">All events</Link>
        </Menu.Item>
        <Menu.Item key="add-event" onClick={ menuToggle }>
          <Link to="/exhibition-events/add-event">Add event</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="showrooms"
        title={
          <span>
            <UnorderedListOutlined />
            <span>Showrooms</span>
          </span>
        }
      >
        <Menu.Item key="/showrooms" onClick={ menuToggle }>
          <Link to="/showrooms">All showrooms</Link>
        </Menu.Item>
        <Menu.Item key="add-showroom" onClick={ menuToggle }>
          <Link to="/showrooms/add-showroom">Add showroom</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="products"
        title={
          <span>
            <UnorderedListOutlined />
            <span>Products</span>
          </span>
        }
      >
        <Menu.Item key="/products" onClick={ menuToggle }>
          <Link to="/products">All products</Link>
        </Menu.Item>
        <Menu.Item key="add-product" onClick={ menuToggle }>
          <Link to="/products/add-product">Add product</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="taxonomy"
        title={
          <span>
            <UnorderedListOutlined />
            <span>Taxonomy</span>
          </span>
        }
      >
        <Menu.Item key="/taxonomy" onClick={ menuToggle }>
          <Link to="/taxonomy">All taxonomies</Link>
        </Menu.Item>
        <Menu.Item key="add-taxonomy" onClick={ menuToggle }>
          <Link to="/taxonomy/add-taxonomy">Add taxonomy</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="packages"
        title={
          <span>
            <UnorderedListOutlined />
            <span>Packages</span>
          </span>
        }
      >
        <Menu.Item key="/packages" onClick={ menuToggle }>
          <Link to="/packages">All Packages</Link>
        </Menu.Item>
        <Menu.Item key="add-package" onClick={ menuToggle }>
          <Link to="/packages/add-package">Add Package</Link>
        </Menu.Item>
        <Menu.Item key="package-requests" onClick={ menuToggle }>
          <Link to="/packages/package-requests">Package requests</Link>
        </Menu.Item>
        <Menu.Item key="package-requests-resolved" onClick={ menuToggle }>
          <Link to="/packages/package-requests-resolved">Package requests resolved</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="analytics"
        title={
          <span>
            <BarChartOutlined />
            <span>Analytics</span>
          </span>
        }
      >
        <Menu.Item key="/customer-behavior" onClick={ menuToggle } disabled>
          <Link to="/customer-behavior">Customer behavior</Link>
        </Menu.Item>
        <Menu.Item key="/exhibition-statistics" onClick={ menuToggle } disabled>
          <Link to="/exhibition-statistics">Exhibition statistics</Link>
        </Menu.Item>
        <Menu.Item key="/finance-statistics" onClick={ menuToggle } disabled>
          <Link to="/finance-statistics">Finance statistics</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="localization"
        title={
          <span>
            <GlobalOutlined />
            <span>Localization</span>
          </span>
        }
      >
        <Menu.Item key="languages" onClick={ menuToggle } >
          <Link to="/localization/languages">Languages</Link>
        </Menu.Item>
        <Menu.Item key="translation-keys" onClick={ menuToggle } >
          <Link to="/localization/translation-keys">Translation keys</Link>
        </Menu.Item>
        <Menu.Item key="translations" onClick={ menuToggle }>
          <Link to="/localization/translations">Translations</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="settings"
        title={
          <span>
            <SettingOutlined />
            <span>Admin Settings</span>
          </span>
        }
      >
        <Menu.Item key="/sustem-settings" onClick={ menuToggle } disabled>
          <Link to="/sustem-settings">Sustem Settings</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard-config" onClick={ menuToggle } disabled>
          <Link to="/dashboard-config">Dashboard Config.</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default MainMenu;
