import { useState } from "react";
import { useLazyQuery } from "@apollo/client";



const useQueryStandUser = (query = true) => {

  const [ searhText, setSearchText ] = useState('');

  const [ getUsers, { loading, error, data } ] = useLazyQuery(query, {
    variables: {
      text: searhText,
    },
    // notifyOnNetworkStatusChange: true,
    // fetchPolicy: "cache-and-network",
  });


  const onSearch = (value) => {

    if (value.length > 2 || value.length === 0)
    {
      setSearchText(value);
      getUsers();
    }
  }


  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: data && data.users.data,
    onSearch
  };
}

export default useQueryStandUser;
