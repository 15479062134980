import { gql } from "@apollo/client";


export const PRODUCT_CREATE_UPDATE = gql`
mutation ProductCreateOrUpdate($input: ProductCreateOrUpdateInput!) {
  productCreateOrUpdate(input: $input) {
    label
    message
    product {
      id
      title
      description
      cover
      type
      is_searchable
      is_top
      status
      ee_id
      stand_id
      created_at
      updated_at
    }
  }
}
`;


export const PRODUCT_META_SET = gql`
mutation ProductMetaSet(
$product_id: ID!,
$input: [ProductMetaInput]
){
  productMetaSet(
  product_id: $product_id,
  input: $input
  ){
    label
    message
    product{
      id
      ee_id
      stand_id
      title
    }
    meta_fields {
      meta_key
      meta_value
      meta_group
    }
  }
}
`;

export const PRODUCT_CREATE_AND_META_SET = gql`
mutation ProductCreateAndMetaSet(
$product_id: ID!,
$metaInput: [ProductMetaInput],
$productInput: ProductCreateOrUpdateInput!,
$term_id: [ID!]
){

  productMetaSet(
  product_id: $product_id,
  input: $metaInput
  ){
    label
    message
    product{
      id
      ee_id
      stand_id
      title
    }
    meta_fields {
      meta_key
      meta_value
      meta_group
    }
  }

  productCreateOrUpdate(
  input: $productInput
  ) {
    label
    message
    product {
      id
      title
      description
      cover
      type
      is_searchable
      is_top
      status
      ee_id
      stand_id
      created_at
      updated_at
    }
  }

  termAddProduct(
  product_id: $product_id,
  term_id: $term_id
  ){
    label
    message
    terms {
      id
      title
      taxonomy_id
    }
  }
}
`;

export const PRODUCT_META_UPLOAD = gql`
mutation ProductMetaUpload($input: ProductUploadFileInput){
  productMetaUpload(input: $input){
    label
    message
    product {
      cover
      id
      title
      meta_fields {
        id
        product_id
        meta_key
        meta_value
        meta_type
        meta_group
      }
    }
  }
}
`;


export const PRODUCT_DELETE = gql`
mutation ProductDelete($id: ID!){
  productDelete(id: $id){
    label
    message
  }
}
`;


export const PRODUCT_META_DELETE = gql`
mutation ProductMetaDelete(
$product_id: ID!,
$meta_key: String
){
  productMetaDelete(
  product_id: $product_id,
  meta_key: $meta_key
  ){
    label
    message
    product {
     id
     meta_fields {
      id
      meta_key
      meta_value
    }
  }
}
}
`;

export const PRODUCT_META_IMAGE_DELETE = gql`
mutation ProductMetaDelete(
$product_id: ID!
$meta_key: String
$metaInput: [ProductMetaInput]
){
  productMetaDelete(
  product_id: $product_id
  meta_key: $meta_key
  ){
    label
    message
    product {
     id
     meta_fields {
      id
      meta_key
      meta_value
    }
  }
}
productMetaSet(
product_id: $product_id
input: $metaInput
){
  product{
    id
    cover
    title
    description
    type
    is_searchable
    is_top
    status
    meta_fields{
      id
      product_id
      meta_key
      meta_type
      meta_value
      meta_group
    }
    stand{
      id
      logo
      title
    }
    updated_at
    created_at
  }
}
}
`;


