
import { useQuery } from "@apollo/client";



const useQueryUserModerator = (query) => {

  const { loading, error, data } = useQuery(query, {
    variables: {
      where: {
        column: 'ROLE',
        operator: 'IN',
        value: [
          "admin", "editor"
        ]
      },
    },
  });



  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: data && data.users.data,
  };
}

export default useQueryUserModerator;

