import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";
import { GET_TRANSLATION_KEYS } from "../../../../../graphql/query/languages-gql";

const useMutationKeysCreate = (mutation) => {

  const { numPage } = useParams();

  const [ _setMutationVariableCreate,
    { loading: loadingMutationCreate,
      error: errorMutationCreate } ] = useMutation(mutation,
        {
          update(_, { data }) {
            const {
              translationKeyCreate: {
                label,
                message
              }
            } = data;

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
          refetchQueries: [
            {
              query: GET_TRANSLATION_KEYS,
              variables: {
                first: 10,
                page: +numPage ? +numPage : 1
              },
              notifyOnNetworkStatusChange: true,
              fetchPolicy: "cache-and-network",
            },
          ]
        });


  return {
    _setMutationVariableCreate,
    loadingMutationCreate,
    errorMutationCreate,
  }
};

export default useMutationKeysCreate;

