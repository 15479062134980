import React from "react";
import "./authentication.scss";
import AuthorizationPage from "../../pages/authorization/authorization-page";


const Authentication = ({ children }) => {

  const data = localStorage.getItem("authToken");

  const authToken = data !== undefined ? data : false;

  return (
    <>
      { authToken ? children : <AuthorizationPage /> }
    </>
  )
};



export default Authentication;
