import { gql } from "@apollo/client";




export const PACKAGE_DELETE = gql`
  mutation PackageDelete($id: ID!){
    packageDelete(id: $id){
      label
      message
    }
  }
`;


export const PACKAGE_RULE_DELETE = gql`
  mutation PackageRulesDelete($id: ID!){
    packageRulesDelete(id: $id){
      label
      message
    }
  }
`;


export const PACKAGE_FILE_DELETE = gql`
  mutation PackageUpdloadPDFDelete($id: ID!){
    packageUpdloadPDFDelete(id: $id){
      label
      message
      package {
        id
      }
    }
  }
`;


export const PACKAGE_CREATE_UPDATE = gql`
  mutation PackageCreateOrUpdate(
  $input: PackageCreateOrUpdateInput!
  ){
    packageCreateOrUpdate(input: $input){
      label
      message
      package {
        id
        title
        price
        ee_id
        pdf
        description
        created_at
        updated_at
      }
    }
  }
`;


export const PACKAGE_FILE_UPLOAD = gql`
  mutation PackageUpdloadPDF($id: ID!, $file: Upload!){
    packageUpdloadPDF(id: $id, file: $file){
      label
      message
      package {
        id
        pdf
      }
    }
  }
`;


export const PACKAGE_RULE_CREATE_UPDATE = gql`
  mutation PackageRuleCreateOrUpdate(
    $id: ID,
    $title: String,
    $package_id: ID!,
    $rule_key: String!,
    $order: Int,
    $min_value: Int,
    $max_value: Int,
    $type: PackageRuleConstantStatusEnum,
    $active: Boolean
  ){
    packageRuleCreateOrUpdate(
      id: $id,
      title: $title,
      package_id: $package_id,
      rule_key: $rule_key,
      order: $order,
      min_value: $min_value,
      max_value: $max_value,
      type: $type,
      active: $active
      )
      {
        label
        message
        package_rule {
          id
          title
          package_id
          rule_key
          order
          min_value
          max_value
          type
          active
          created_at
          updated_at
      }
    }
  }
`;


export const PACKAGE_REQUEST_UPDATE = gql`
  mutation PackageRequestUpdate(
    $id: ID!,
    $status: PackageRequestStatusEnum,
    $message_answer: String
    ){
    packageRequestUpdate(
      id: $id,
      status: $status,
      message_answer: $message_answer
      ){
      label
      message
      package_request {
        id
        package_id
        stand_id
        user_id
        moderator_id
        status
      }
    }
  }
`;


export const PACKAGE_STAND = gql`
  mutation StandPackage(
    $id: ID!,
    $package_id: ID!
    ){
      standPackage(
        id: $id,
        package_id: $package_id
        ){
      label
      message
      stand {
        id
        title
        package {
          id
          title
         }
         package_request {
            id
            package {
              id
              title
            }
            user {
              id
              name
              surname
            }
            moderator {
              id
              name
              surname
            }
            message_answer
            status
            created_at
          }
      }
    }
  }
`;

export const PACKAGE_RULES_ORDER_SET = gql`
  mutation PackageRulesOrderSet(
    $package_id: ID!,
    $ids: [ID!]!
    ){
    packageRulesOrderSet(
      package_id: $package_id,
      ids: $ids
      ){
      label
      message
      package_rules {
        id
        title
        package_id
        rule_key
        order
        min_value
        max_value
        type
        active
        created_at
        updated_at
      }
    }
  }
`;






