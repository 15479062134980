import React from "react";
import { Layout, Select } from 'antd';
import TranslationsTable from "./translations-table";
import SearchForm from "../../forms/search-form"
import useQueryTrnslns from './hooks/use-query-trnslns';
import { GET_TRANSLATIONS } from "../../../graphql/query/languages-gql";



import './translations.scss';

const { Sider, Content } = Layout;
const { Option } = Select;


const Translations = () => {

  const {
    loadingQuery,
    // errorQuery,
    dataQuery,
    dataQueryLang,
    history,
    setSearchVal,
    setSearchKey,
    keyTranslation,
    routePage,
    pagination,
    handleTableChange,
  } = useQueryTrnslns(GET_TRANSLATIONS);



  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Translations</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <Select
            onChange={
              (value) => {
                setSearchKey(`${ value }`);
              }
            }
            showSearch
            style={ { width: 165 } }
            placeholder="Search by"
            optionFilterProp="children"
          >
            <Option value="tr_key">Key</Option>
            <Option value="value">Translation</Option>
          </Select>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>

      <TranslationsTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        dataQueryLang={ dataQueryLang }
        loadingQuery={ loadingQuery }
        handleTableChange={ handleTableChange }
        keyTranslation={ keyTranslation } />

    </>
  );
};

export default Translations;
