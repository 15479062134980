import React from 'react';
import Translations from '../components/localization/translations';


const LocalizationTrnslnsPage = () => {

    return (
        <div className="page-holder bg-white">
            <Translations />
        </div>
    );
};

export default LocalizationTrnslnsPage;