import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
// import { GET_EXBN_EVNT } from './../../../../../graphql/query/exhibition-gql';

const useMutationEeMetaSet = (mutation, /*refetchQuery*/) => {

  const [ _setMutationVariableEeMetaSet,
    { loading } ] = useMutation(mutation,
      {
        update(_, { data }) {

          const {
            eeMetaSet: {
              label,
              message
            }
          } = data;

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationVariableEeMetaSet,
    loadingMutationEeMetaSet: loading
  }
};

export default useMutationEeMetaSet;