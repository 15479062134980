import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
import { GET_STANDS } from "../../../../graphql/query/showrooms-gql";



const useMutationStandDelete = (mutation, dataQuery) => {

  const history = useHistory();

  const [ _setMutationStandDelete, { loading } ] = useMutation(mutation,
    {
      update(cache, { data }) {

        const {
          standDelete: {
            label,
            message
          }
        } = data;

        history.push(`showrooms`);

        const filteredArr = dataQuery.data.filter(
          (item) => item.id !== dataQuery.data.id
        )

        cache.writeQuery({
          query: GET_STANDS,
          variables: {

          },
          data: {
            stands: {
              data: [
                ...filteredArr
              ],
              __typename: dataQuery.__typename
            }
          }
        })


        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
    });

  return {
    _setMutationStandDelete,
    loadingStandDelete: loading,
  }
};

export default useMutationStandDelete;