import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";
import { GET_LANGUAGES } from '../../../../../graphql/query/languages-gql';

const useMutationLangCreate = (mutation) => {

  const [ _setMutationVariableCreate,
    { loading: loadingMutationCreate,
      error: errorMutationCreate } ] = useMutation(mutation,
    {
      update(_, { data }) {
        const {
          languageCreateOrUpdate: {
            label,
            message
          }
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      refetchQueries: [
        {
          query: GET_LANGUAGES,
          notifyOnNetworkStatusChange: true,
          fetchPolicy: "cache-and-network",
        },
      ]
    });


  return {
    _setMutationVariableCreate,
    loadingMutationCreate,
    errorMutationCreate,
  }
};

export default useMutationLangCreate;