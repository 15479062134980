import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tabs, Typography } from "antd";
import AccountTabSettings from './tabs/account-settings-tab';
import ProfileTab from './tabs/profile-tab';
import ProfileTabChangePassword from './tabs/profile-change-password-tab.js';
import { GET_USER } from './../../../graphql/query/user-gql';

import './profile-tabs.scss';

const ProfileTabs = () => {

    const { id = false, profileTab } = useParams();
    let history = useHistory();


    const { data, loading, error } = useQuery(GET_USER, {
        variables: {
            userAdmId: id
        },
        // fetchPolicy: "cache-and-network",
    });


    const user = !!data && (data.user)
    const fullName = `${ user.name }-${ user.surname }`.toLowerCase()

    const { TabPane } = Tabs;

    return (
        <div className="tabs-form profile-tabs">

            { !!user && <h3>Edit account: <Typography.Link>{ user.name } { user.surname }</Typography.Link></h3> }

            <Tabs
                tabPosition="top"
                activeKey={ profileTab ? profileTab : 'account-settings' }
                onTabClick={ (key) => {
                    changeTab(history, key, id, fullName);
                } }
            >

                <TabPane tab="Account Settings" key="account-settings">
                    <AccountTabSettings
                        userId={ id }
                        getUser={ user }
                        loadingUser={ loading }
                        errorUser={ error }
                    />
                </TabPane>
                { !!id && !error && (
                    <>
                        <TabPane tab="Profile" key="profile">
                            <ProfileTab getUser={ user } loadingUser={ loading } />
                        </TabPane>

                        <TabPane tab="Change Password" key="change-password">
                            <ProfileTabChangePassword getUser={ user } />
                        </TabPane>
                    </>
                ) }
            </Tabs>
        </div>
    );
};


export default ProfileTabs;


//Pure functions

const changeTab = (history, tabKey, id, fullName) => {

    if (!!id)
    {
        if (tabKey === 'account-settings')
        {
            history.push(`/users/id-${ id }-${ fullName }/`);
        } else
        {
            history.push(`/users/id-${ id }-${ fullName }/${ tabKey }/`);
        }
    } else
    {
        history.push(`/users/add-user`);
    }
};
