import React from "react";
import NormalAccountForm from './normal-account-form';
import useMutationAccountSet from './hooks/use-mutation-account-set';
import useMutationUpdateUser from './hooks/use-mutation-update-user';
import useMutationUserDelete from './hooks/use-mutation-meta-user-delete';


import {
  USER_CREATE,
  USER_UPDATE,
  USER_DELETE
} from '../../../../graphql/mutation/user-gql'


import './user-account-form.scss';


const UserAccountForm = ({ getUser, loadingUser, errorUser, userId }) => {

  const {
    _setMutationVariableCreate,
    loadingMutationCreate,

  } = useMutationAccountSet(USER_CREATE, getUser);

  const {
    _setMutationVariableUpdate,
    loadingMutationUpdate,

  } = useMutationUpdateUser(USER_UPDATE, getUser);


  const {
    _setMutationVariableUserDelete,
    loadingUserDelete,

  } = useMutationUserDelete( USER_DELETE, getUser );



  return (
    <NormalAccountForm
      userId={ userId }
      getUser={ getUser }
      errorUser={ errorUser }
      loadingUser={ loadingUser }
      loadingMutationCreate={ loadingMutationCreate }
      loadingMutationUpdate={ loadingMutationUpdate }
      loadingUserDelete={ loadingUserDelete }
      setMutationVariableCreate={ _setMutationVariableCreate }
      setMutationVariableUpdate={ _setMutationVariableUpdate }
      setMutationVariableUserDelete={ _setMutationVariableUserDelete }
    />
  );
};


export default UserAccountForm;
