import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';


const useQueryPackages = (query) => {

  const routePage = `/packages/`;

  const [ pageSize, setPageSize ] = useState(10);
  const [ exhibitionEventId, setExhibitionEventId ] = useState(undefined);

  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });


  let history = useHistory();
  const { numPage } = useParams();


  const { loading, error, data } = useQuery(query, {
    variables: {
      // title: String,
      // price: NumericRange,
      // created_at: DateRange,
      ee_id: +exhibitionEventId || undefined,
      first: pageSize,
      page: +numPage ? +numPage : 1,
      ...order
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });


  const packages = !loading && data?.packages;
  const { paginatorInfo } = !loading && packages;
  const { total, currentPage } = !loading && paginatorInfo;

  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter, page) => {

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;


    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });


    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: packages.data,
    pagination,
    setExhibitionEventId,
    handleTableChange
  }
};

export default useQueryPackages;
