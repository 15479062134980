import React from "react";
import { Form, Button, Input, InputNumber, Spin } from "antd";
import { errorNotification } from "../../../result";

import EntityRemoveButton from '../../../service/delete-entity';
import metaFields from '../../../../utils/meta-fields';

const NormalTermsUpdateForm = ({
  dataQuery,
  loadingQuery,
  setMutationTermsUpdate,
  loadingMutationTermsUpdate,
  setMutationTermDelete,
  loadingTermDelete
}) => {

  const [ form ] = Form.useForm();
  dataQuery = dataQuery && dataQuery


  const formSubmit = ({ values }) => {

    let fieldValues = metaFields.parseFormArray(values);

    setMutationTermsUpdate({
      variables: {
        input: [ ...fieldValues ]
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };

  const arrTerms = dataQuery.taxonomy_terms.map(
    (item) => {


      return (
        <div key={ item.id }>
          <Form.Item className="form-group">

            <Form.Item className="form-group">
              <Form.Item
                label="Term label"
                initialValue={ item.title }
                name={ `title-field-${ item.id }` }
              >
                <Input placeholder="Enter Term Title" />
              </Form.Item>

              <Form.Item
                label="Sort number"
                initialValue={ item.sort_number }
                name={ `sort_number-field-${ item.id }` }
              >
                <InputNumber placeholder="Sort number" />
              </Form.Item>

              <Form.Item
                hidden
                initialValue={ item.id }
                name={ `id-field-${ item.id }` }
              >
                <Input />
              </Form.Item>

              <Form.Item
                hidden
                initialValue={ item.taxonomy_id }
                name={ `taxonomy_id-field-${ item.id }` }
              >
                <Input />
              </Form.Item>
            </Form.Item>
            <div className="d-flex justify-content-between align-items-center" >
              <ul className="count-box">
                <li><span>Linked objects</span></li>
                <li><strong>{ item.count_obj || 0 }</strong></li>
              </ul>
              <EntityRemoveButton
                nameEntity={ 'term' }
                dataNameEntity={ `${ item.title }` }
                loading={ loadingTermDelete }
                deleteMutation={ setMutationTermDelete }
                variables={
                  {
                    id: item.id,
                  }
                }
              />
            </div>
          </Form.Item>
        </div>
      )
    }
  )


  return (

    <div className="form-container lg">
      { loadingQuery ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
          <Form
            className="form-container lg"
            form={ form }
            onFinish={ (values) => {
              formSubmit({ values });
            } }
            layout="vertical"
          >

            { arrTerms }
            <Button loading={ loadingMutationTermsUpdate } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
          </Form>
        )
      }
    </div>
  );
};


export default NormalTermsUpdateForm;
