import React from 'react';
import Icon from '@ant-design/icons';


const AvatarSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <circle cx="24.1" cy="21.1" r="5.3"/>
        <path d="M24.1,8c-9.4,0-17,7.6-17,17s7.6,17,17,17s17-7.6,17-17S33.5,8,24.1,8z M33.7,37.8v-3.3c0-3.6-3-6.6-6.6-6.6h-6
		c-3.6,0-6.6,3-6.6,6.6v3.3c-3.9-2.9-6.4-7.6-6.4-12.8c0-8.8,7.2-16,16-16s16,7.2,16,16C40.1,30.2,37.6,34.8,33.7,37.8z"/>
    </svg>
);

const AvatarIcon = props => {
    return <Icon component={ AvatarSvg } />;

};


export default AvatarIcon;