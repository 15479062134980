import React from 'react';

import ProfileTabs from '../components/tabs';


const ProfilePage = () => {

    return (
        <div className="page-holder bg-white">
            {/* <div className="title-box"></div> */ }
            <div className="form-container lg">
                <ProfileTabs />
            </div>
        </div>
    );
};

export default ProfilePage;
