import React from "react";
import PackageRequestsResolved from '../components/packages/package-requests-resolved';



const PackageRequestsPageResolved = () => {

  return (
    <div className="page-holder bg-white">
      <PackageRequestsResolved />
    </div>
  );
};

export default PackageRequestsPageResolved;
