import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";

const useMutationAccountSet = (mutation) => {

  let history = useHistory();

  const [ _setMutationVariableCreate,
    { loading: loadingMutationCreate,
      error: errorMutationCreate } ] = useMutation(mutation,
    {
      update(_, { data }) {
        const {
          userCreate: {
            label,
            message,
            user
          }
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message
        });

        history.push(`/users/id-${ user.id }-${ user.name }-${ user.surname }`.toLowerCase());
      },
    });


  return {
    _setMutationVariableCreate,
    loadingMutationCreate,
    errorMutationCreate,
  }
};

export default useMutationAccountSet;