import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";

const useMutationStandCreateUpdate = (mutation, dataQuery) => {

  const [ _setMutationVariableCreateUpdate,
    { loading: loadingMutationCreateUpdate,
      error: errorMutationCreateUpdate } ] = useMutation(mutation,
    {
      update(cache, { data }) {
        const {
          standCreateOrUpdate: {
            label,
            message,
            stand
          }
        } = data;

        cache.modify({
          id: cache.identify(dataQuery),
          fields: {
            stand() {
              return stand;
            },
          },
        });

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
    });


  return {
    _setMutationVariableCreateUpdate,
    loadingMutationCreateUpdate,
    errorMutationCreateUpdate,
  }
};

export default useMutationStandCreateUpdate;