import React from "react";
import { Avatar, Spin } from "antd";
import Icons from "../../../icons";

import "./avatar-image.scss";


const AvatarImage = ({
    photoUrl,
    badge,
    size,
    loading,
    shape = 'circle'
}) => {


    let photo = photoUrl,
        extraClass = "";

    if (photoUrl)
    {
        photo = photoUrl;
        extraClass = "with-image";
    } else
    {
        if (badge)
        {
            photo = <Icons.NoImage />;
            extraClass = "no-image";
        }
    }

    return (
        <div className={ `avatar-image ${ extraClass }` }>
            <Avatar
                src={ !loading && photo }
                size={ size }
                shape={ shape }
            >
                { loading ? <Spin /> : badge }
            </Avatar>
        </div>
    );
};

export default AvatarImage;