import { useMutation } from "@apollo/client";
import { errorNotification } from "../../../result";


const useMutationLogin = (mutation) => {


  const [ loginRequest, { loading } ] = useMutation(mutation, {
    update(_, { data: { userLogin } }) {
      const { access_token } = userLogin;
      localStorage.setItem("authToken", access_token);
      document.location.reload();
    },
    onError(error) {
      errorNotification(error);
    }
  });

  return {
    loading,
    loginRequest
  }
};

export default useMutationLogin;