import React from "react";
import { Form, Select } from "antd";

//Hooks
import useQueryStandUser from './hooks/use-query-select-users-stand';

//Query
import { GET_USERS } from '../../../graphql/query/user-gql';

//CSS
import './select-users-stand.scss';



const SelectUserStand = ({
  selectLabel = 'Add stand user'
}) => {

  const {
    loadingQuery,
    dataQuery,
    onSearch
  } = useQueryStandUser(GET_USERS);



  const { Option } = Select;

  const selectSearchField = dataQuery && (
    dataQuery.map(({ id, name, surname, email_original }) => {
      return (
        <Option key={ id } value={ id }>
          { name } { surname } - { email_original }
        </Option>
      )
    })
  );


  return (

    <Form.Item
      name="stand_user"
      label={ selectLabel }
      initialValue=""
    >
      <Select
        className="select-input-space-between"
        dropdownClassName="select-dropdown-space-between"
        loading={ loadingQuery }
        onSearch={
          (value) =>
            onSearch(value)
        }
        notFoundContent="There is no user with this name."
        showSearch
        placeholder="Select user stand"
        optionFilterProp="children"
      >
        { selectSearchField }
      </Select>
    </Form.Item>
  )
};

export default SelectUserStand;