import React, { useState } from "react";
import { Button, Modal } from "antd";
import { errorNotification } from "../result";
import ColorPicker from "../service/color-picker/color-picker";
import InputColorPicker from '../service/input-color-picker';
import hex from '../../utils/hex-color';


const ChoiceColor = ({
    defaultColor,
    loadingMutationStandMetaSet,
    variables = {},
    uploadMutation = () => { },
    btnText = ''
}) => {



    const [ showModal, setShowModal ] = useState(false);
    const [ currentColor, setCurrentColor ] = useState(defaultColor);


    const lightOrDark = hex.lightOrDark(currentColor);


    return (
        <div className="">
            <Button
                style={
                    {
                        background: currentColor,
                        borderColor: lightOrDark,
                        color: lightOrDark,
                    } }
                type="primary"
                onClick={ () => setShowModal(true) }
            >

                { btnText }
            </Button>
            <Modal
                className={ "" }
                title={ btnText }
                visible={ showModal }
                centered
                width={ "auto" }
                // closeIcon={ <Icons.Cross /> }

                onCancel={ () => {
                    setShowModal(false);
                    setCurrentColor(defaultColor);
                } }
                footer={ null }
            >
                <ColorPicker.ColorGrid
                    currentColor={ currentColor }
                    setColor={ setCurrentColor }
                />

                <InputColorPicker
                    currentColor={ currentColor }
                    setCurrentColor={ setCurrentColor }
                />

                <div className="modal-actions">
                    <Button
                        type="primary"
                        disabled={ loadingMutationStandMetaSet }
                        loading={ loadingMutationStandMetaSet }
                        onClick={ () => {

                            if (!currentColor)
                            {
                                errorNotification({
                                    label: "Set top color failed",
                                    reason: "Please choose color"
                                });

                                return false;
                            }

                            let uploadVar;
                            for (var key in variables)
                            {

                                if (Array.isArray(variables[ key ]))
                                {
                                    uploadVar = {
                                        [ key ]: [
                                            {
                                                ...variables[ key ][ 0 ],
                                                meta_value: currentColor
                                            }
                                        ]
                                    }
                                }
                            }

                            uploadVar = {
                                ...variables,
                                ...uploadVar
                            }

                            uploadMutation({
                                variables: {
                                    ...uploadVar,
                                }

                            }).catch((error) => {
                                errorNotification(error);
                            });

                            setShowModal(false);
                        } }

                    >
                        Save
                    </Button>
                    <Button
                        type="default"
                        onClick={ () => {
                            setShowModal(false);
                            setCurrentColor(defaultColor);
                        } }
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ChoiceColor;