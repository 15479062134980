import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';


const useQueryKeys = (query) => {

  const routePage = `/localization/translation-keys`

  const [ pageSize, setPageSize] = useState(10);
  const [ searchKey, setSearchKey ] = useState('key');
  const [ searchVal, setSearchVal ] = useState("");
  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });


  const [ filter, setFilter ] = useState({
    operator: 'EQ',
    column: 'TYPE',
    value: null
  });


  let history = useHistory();
  const { numPage } = useParams();


  const { loading, error, data } = useQuery(query, {
    variables: {
      first: pageSize,
      page: numPage ? +numPage : 1,
      ...order,
      where: (filter.value !== null) ? { ...filter } : { operator: 'EQ' },
      text: searchVal.length > 1 ? searchVal : undefined,
      searchField: searchVal.length > 1 ? searchKey : undefined,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });



  const { translationKeys = {} } = !loading ? data : {};
  const { paginatorInfo = {} } = !loading ? translationKeys : {};
  const { total, currentPage } = paginatorInfo;

  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = ({ pagination, filters, sorter }) => {

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;

    const column = Object.keys(filters)[ 0 ] && Object.keys(filters)[ 0 ].toUpperCase();
    const value = Object.values(filters)[ 0 ] && Object.values(filters)[ 0 ][ 0 ];

    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });

    setFilter({
      column,
      value
    })

    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }/page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: translationKeys.data,
    setSearchVal,
    setSearchKey,
    routePage,
    history,
    pagination,
    handleTableChange
  }
};

export default useQueryKeys;
