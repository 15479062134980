import React from "react";
import NormalUpdateAttachmentForm from './normal-update-attachment-form';
import useMutationStandUpdateFile from './hooks/use-mutation-stand-update-file';


import {
  ATTACHED_UPDATE
} from '../../../../graphql/mutation/attachments-gql'


import './update-attachment-form.scss';



const UpdateAttachmentForm = ({ dataQuery, loadingQuery, setModalVisible }) => {

  const {
    _setMutationStandUpdateFile,
    loadingMutationStandUpdateFile,

  } = useMutationStandUpdateFile(ATTACHED_UPDATE, dataQuery);

  return (
    <NormalUpdateAttachmentForm
      dataQuery={ dataQuery }
      loadingQuery={ loadingQuery }
      loadingMutationStandUpdateFile={ loadingMutationStandUpdateFile }
      setMutationStandUpdateFile={ _setMutationStandUpdateFile }
      setModalVisible={ setModalVisible }
    />
  );
};


export default UpdateAttachmentForm;
