import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';
import filterObj from '../../../../utils/filter-table';


const useQueryTaxonomies = (query) => {

  let history = useHistory();

  const routePage = `/taxonomy`
  const [ searchVal, setSearchVal ] = useState("");
  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });

  const [ filter, setFilter ] = useState(false);

  const { loading, error, data } = useQuery(query, {
    variables: {
      // first: 10,
      // page: numPage ? +numPage : 1,
      ...order,
      where: { ...filter },
      text: searchVal,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });



  const { taxonomies } = !!data && data;

  // const { paginatorInfo = {} } = !loading ? taxonomies : {};
  // const { total, perPage, currentPage } = paginatorInfo;

  // const pagination = {
  //   current: currentPage,
  //   pageSize: !loading ? perPage : loading,
  //   total,
  //   defaultCurrent: 1,
  //   defaultPageSize: 10,
  // };



  const pagination = false;

  const handleTableChange = ({ pagination, filters, sorter }) => {

    const {
      order,
      columnKey
    } = sorter;


    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });

    if (filterObj(filters) !== {})
    {
      setFilter(
        { ...filterObj(filters) }
      )
    } else
    {
      setFilter(false)
    }


    // if (pagination.current === 1)
    // {
    //   history.push(routePage);
    // } else
    // {
    //   history.push(`${ routePage }/page/${ pagination.current }`);
    // }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: data && taxonomies,
    setSearchVal,
    routePage,
    history,
    pagination,
    handleTableChange
  }
};

export default useQueryTaxonomies;
