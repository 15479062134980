import { useQuery } from "@apollo/client";



const useQueryExbnEvnts = (query) => {

  const { loading, error, data } = useQuery(query, {
    variables: {
      first: 20,
      page: 1
    },
  });


  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: data && data.eeAll.data,
  };
}

export default useQueryExbnEvnts;
