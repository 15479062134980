import React from "react";
import NormalEventForm from './normal-event-form';
import useMutationEventCreate from './hooks/use-mutation-event-create';
import useMutationEventUpdate from './hooks/use-mutation-event-update';


import {
  EXBN_EVNT_CREATE,
  EXBN_EVNT_UPDATE,
} from '../../../../graphql/mutation/exhibition-gql'

import './event-about-form.scss';


const EventAboutForm = ({ exbnEvnt, loadingQueryEvent }) => {

  const {
    _setMutationVariableCreate,
    loadingMutationCreate,

  } = useMutationEventCreate(EXBN_EVNT_CREATE, exbnEvnt);

  const {
    _setMutationVariableUpdate,
    loadingMutationUpdate,

  } = useMutationEventUpdate(EXBN_EVNT_UPDATE, exbnEvnt);


  return (
    <NormalEventForm
      exbnEvnt={ exbnEvnt }
      loadingQueryEvent={ loadingQueryEvent }
      loadingMutationCreate={ loadingMutationCreate }
      loadingMutationUpdate={ loadingMutationUpdate }
      setMutationVariableCreate={ _setMutationVariableCreate }
      setMutationVariableUpdate={ _setMutationVariableUpdate }
    />
  );
};


export default EventAboutForm;
