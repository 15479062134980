import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Input, Radio, Select, Typography } from "antd";
import { errorNotification } from "../../../result";
import RequestHandler from "../../../request-handler"
import metaFields from './../../../../utils/meta-fields';
import SelecStands from '../../select-stands';
import useMutationProductMetaUpload from '../hooks/use-mutation-product-meta-upload';
import useMutationProductMetaDelete from '../hooks/use-mutation-product-meta-delete';
import EntityRemoveButton from './../../../service/delete-entity';
import ProductInfoForm from '../product-info-form';
import ProductMediaForm from '../product-media-form';
import TaxanomyInput from '../../taxonomy-input';
import {

  PRODUCT_META_UPLOAD,
  PRODUCT_META_DELETE
} from '../../../../graphql/mutation/products-gql'



const { TextArea } = Input;

const NormalProductUpdateServiceForm = ({

  dataQuery,
  errorQuery,
  loadingQuery,
  setMutationProductCreateAndMetaSet,
  loadingMutationProductCreateAndMetaSet,
  setMutationProductDelete,
  loadingProductDelete

}) => {

  const { getValue, normalize } = metaFields;

  const [ form ] = Form.useForm();
  const { Option } = Select;

  const [ radioValue, setRadioValue ] = useState(dataQuery.status);
  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };

  const [ radioValueIsTop, setRadioValueIsTop ] = useState(dataQuery.is_top || false);
  const radioOnChangeIsTop = e => {
    setRadioValueIsTop(e.target.value);
  };


  const metaProductTaxonomiesId = normalize(
    dataQuery && dataQuery.exhibition_event ?
      dataQuery.exhibition_event.meta_fields : false
  )
  const arrProductTaxonomiesId = getValue(metaProductTaxonomiesId, "product_taxonomies")


  const {
    _setMutationProductUpload,
    loadingMutationProductUpload

  } = useMutationProductMetaUpload(PRODUCT_META_UPLOAD, dataQuery, form);


  const {
    _setMutationProductMetaDelete,
  } = useMutationProductMetaDelete(PRODUCT_META_DELETE);



  const formSubmit = ({ values }) => {

    let { metaValues, fieldValues } = metaFields.parseForm(values);
    metaValues = metaFields.filterEmpty(metaValues, dataQuery.meta_fields);


    let termIds = [];

    for (let [ key, value ] of Object.entries(fieldValues))
    {

      if (key.indexOf('product_terms') !== -1)
      {
        termIds = [ ...termIds, ...value ];
      }
    }


    setMutationProductCreateAndMetaSet({
      variables: {
        product_id: dataQuery.id,
        metaInput: [ ...metaValues ],
        productInput: {
          id: dataQuery.id,
          stand_id: values[ 'stand_id--unset' ].value,
          title: values[ 'title--unset' ],
          description: values[ 'description--unset' ],
          type: values[ 'type--unset' ],
          status: values[ 'status--unset' ],
          is_searchable: values[ 'is_searchable--unset' ],
          is_top: values[ 'is_top--unset' ]
        },
        term_id: termIds
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };



  return (

    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQuery }
    >

      <Form
        className=""
        form={ form }
        onFinish={ (values) => {
          formSubmit({ values });
        } }
        layout="vertical"
      >

        <div className="row-grid col-lg-2">

          <div className="left-col">

            <Form.Item className="form-group col-two">

              <div style={ { position: 'relative' } }>
                <div style={ { position: 'absolute', top: '-10px', right: '0', zIndex: '1' } }>
                  <Link style={ { marginBottom: '10px' } } to={ `/showrooms/${ dataQuery.stand.id }` }><Button type="primary" ghost>View Stand</Button></Link>
                </div>
                <SelecStands standTitle={ dataQuery.stand.title } standId={ dataQuery.stand.id } />
              </div>

              <Form.Item
                name="status--unset"
                label="Status"
                initialValue={ radioValue }>
                <Radio.Group
                  className="radio-row" onChange={ radioOnChange }
                >
                  <Radio.Button value="draft">Draft</Radio.Button>
                  <Radio.Button value="active">Active</Radio.Button>
                  <Radio.Button value="block">Block</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="title--unset"
                label="Product/service name"
                initialValue={ dataQuery.title }
                rules={ [
                  {
                    type: "string",
                  },
                  { required: true, message: 'Please input your Author name' }
                ] }
              >
                <Input placeholder="Enter Author name" />
              </Form.Item>

              <Form.Item
                name="type--unset"
                label="Product type"
                initialValue={ dataQuery.type }
                rules={ [ {
                  required: true,
                  message: "Select Product type!"
                } ] }
              >
                <Select
                  disabled={ true }
                  placeholder="Select Product type"
                >
                  <Option value="book">Book</Option>
                  <Option value="author">Author</Option>
                  <Option value="product">Product</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="is_top--unset"
                label="Top"
                initialValue={ radioValueIsTop }
              >

                <Radio.Group
                  className="radio-row" onChange={ radioOnChangeIsTop }
                >
                  <Radio.Button value={ true }>Yes</Radio.Button>
                  <Radio.Button value={ false }>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                hidden
                name="is_searchable--unset"
                label="Searchable"
                initialValue={ true }
              >
                <Input />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="description--unset"
              label="Description"
              initialValue={ dataQuery.description }
            // rules={
            //   [
            //     { max: 299, message: 'Field must be maximum 300 characters.' },
            //   ]
            // }
            >
              <TextArea
                maxLength={ 1200 }
                placeholder="Enter short product description"
                autoSize={ { minRows: 3, maxRows: 5 } }
                showCount={
                  { formatter: ({ count, maxLength }) => maxLength - count }
                }
              />
            </Form.Item>

            <ProductInfoForm
              dataQuery={ dataQuery }
              setMutationProductUpload={ _setMutationProductUpload }
              loadingMutationProductUpload={ loadingMutationProductUpload }
              setMutationProductMetaDelete={ _setMutationProductMetaDelete }
            />

            <Typography.Link className="text-decorator">
              <h6>Exhibitoon info</h6>
              <div className="separator"></div>
            </Typography.Link>

            { arrProductTaxonomiesId.length !== 0 ?
              arrProductTaxonomiesId.map((id) => {

                let arrTerms = dataQuery.terms
                  .filter((i) => i.taxonomy_id === id)
                  .map((i) => i.id)

                return (
                  <TaxanomyInput
                    key={ id }
                    id={ id }
                    name={ `product_terms_${ id }-field` }
                    initialValue={ arrTerms }
                    placeholder="Please select interested" />
                )
              }
              ) : false }

          </div>
          <div className="right-col">
            <ProductMediaForm
              dataQuery={ dataQuery }
              setMutationProductUpload={ _setMutationProductUpload }
              loadingMutationProductUpload={ loadingMutationProductUpload }
              setMutationProductMetaDelete={ _setMutationProductMetaDelete }
            />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <Button loading={ loadingMutationProductCreateAndMetaSet } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
            </div>
            <div>
              <EntityRemoveButton
                nameEntity={ 'Product' }
                dataNameEntity={ `${ dataQuery.title }` }
                loading={ loadingProductDelete }
                deleteMutation={ setMutationProductDelete }
                variables={
                  {
                    id: dataQuery.id,
                  }
                }
              />
            </div>
          </div>
        </div>
      </Form>

    </RequestHandler>
  );
};


export default NormalProductUpdateServiceForm;
