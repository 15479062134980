const normalMeta = (meta_fields = {}) => {

    let metaFields = {};

    for (let [, field] of Object.entries(meta_fields)) {
        metaFields[ field.meta_key] = field;
    }

    return metaFields;
};

export default normalMeta;