import React from "react";
import RequestHandler from "../../request-handler"
import { Form, Select } from "antd";

//Hooks
import useQueryUserModerator from './hooks/use-query-select-user-moderator';

//Query
import { GET_USERS_MODERATOR } from '../../../graphql/query/user-gql';


//CSS
import './select-user-moderator.scss';

const { Option } = Select;



const SelectUserModerator = ({
  selectLabel = 'Moderator',
  disabledSelect = false
}) => {



  const {
    loadingQuery,
    dataQuery,
    errorQuery,
  } = useQueryUserModerator(GET_USERS_MODERATOR);



  const selectSearchField = dataQuery && (
    dataQuery.map(({ id, name, surname, email_original }) => {
      return (
        <Option key={ id } value={ id }>
          { name } { surname } - { email_original }
        </Option>
      )
    })
  );

  return (
    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQuery }
    >
      <Form.Item

        name="package_moderator"
        label={ selectLabel }
        initialValue=''
      >
        <Select
          mode=""
          className="select-input-space-between"
          dropdownClassName="select-dropdown-space-between"
          disabled={ disabledSelect }
          showSearch
          placeholder="Choose package for showroom"
          optionFilterProp="children"
        >
          { selectSearchField }
        </Select>
      </Form.Item>
    </RequestHandler>
  )
};

export default SelectUserModerator;