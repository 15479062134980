import React from "react";
import { Form, Button, Radio } from "antd";
import { errorNotification } from "../../../result";
import SelectUserStand from '../../select-users-stand/select-users-stand';



const NormalAddStandUserForm = (
  {
    dataQueryStand,
    setMutationVariableCreateUpdate,
    loadingMutationCreateUpdate,
    setModalVisible
  }
) => {

  const [ form ] = Form.useForm();



  const formSubmit = ({ values }) => {

    setModalVisible(false)

    setMutationVariableCreateUpdate({
      variables: {
        standInput: {
          id: dataQueryStand.id,
          // ee_id: "1",
          exhibitor: [
            {
              user_id: values.stand_user,
              permission: values.stand_permission,
              visible: values.stand_user_visible
            }
          ]
        }
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };


  return (

    <Form
      form={ form }
      onFinish={ (values) => {
        formSubmit({ values });
      } }
      layout="vertical"
      className="add-stand-user"
    >
      <Form.Item
        initialValue="owner"
        name="stand_permission"
        label="Stand Permission"
        style={ { margin: "10px 0" } }>
        <Radio.Group
          // disabled={ true }
          className="radio-row">
          <Radio value="owner">Owner</Radio>
          <Radio value="editor">Editor</Radio>
          <Radio value="member">Member</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        initialValue={ true }
        name="stand_user_visible"
        label="Visible"
        style={ { margin: "10px 0" } }>
        <Radio.Group
          // disabled={ true }
          className="radio-row">
          <Radio value={ true }>Visible</Radio>
          <Radio value={ false }>Invisible</Radio>
        </Radio.Group>
      </Form.Item>

      <SelectUserStand />

      <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
        <Button loading={ loadingMutationCreateUpdate } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
      </Form.Item>
    </Form>
  )
}


export default NormalAddStandUserForm;
