import ProfileTabs from './profile-tabs';
import ExhibitionEventTabs from './exhibition-event-tabs';
import ShowroomTabs from './showroom-tabs';
import TaxonomyTabs from './taxonomy-tabs';
import PackageTabs from './package-tabs';


export default ProfileTabs;

export {
  ExhibitionEventTabs,
  ShowroomTabs,
  TaxonomyTabs,
  PackageTabs
}

