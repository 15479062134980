import React from "react";
import NormalTranslationKeyForm from './normal-translations-form';
import useMutationTranslationUpdate from './hooks/use-mutation-translation-update';
import useQueryTrnsByKey from './hooks/use-query-trns-by-key';
import { Typography } from 'antd';

import {
  GET_TRANSLATION_BY_KEY_AND_LANG
} from '../../../../graphql/query/languages-gql'

import {
  TRANSLATION_UPDATE
} from '../../../../graphql/mutation/languages-gql'


import './translations-form.scss';

const { Text } = Typography;

const TranslationKeyForm = ({ trnslnKeyId, setModalVisible, trnslnId }) => {

  const {
    loadingQuery,
    errorQuery,
    dataQueryKey,
    dataQueryLang,

  } = useQueryTrnsByKey(GET_TRANSLATION_BY_KEY_AND_LANG, trnslnKeyId);


  const {
    _setMutationVariableCreate,
    loadingMutationCreate,

  } = useMutationTranslationUpdate(TRANSLATION_UPDATE, dataQueryKey);


  return (
    <>
      <h3>Key Translations Update: <Text type="success">{ !!dataQueryKey && dataQueryKey.key } </Text></h3>
      <br />
      <NormalTranslationKeyForm
        dataQueryKey={ dataQueryKey }
        dataQueryLang={ dataQueryLang }
        errorQuery={ errorQuery }
        loadingQuery={ loadingQuery }
        loadingMutationCreate={ loadingMutationCreate }
        setMutationVariableCreate={ _setMutationVariableCreate }
        setModalVisible={ setModalVisible }
        trnslnId={ trnslnId }
      />
    </>
  );
};


export default TranslationKeyForm;
