function convertSortName(name) {
  switch (name)
  {
    case 'ascend':
      return 'ASC';
    case 'descend':
      return 'DESC';
    default:
      return 'ASC';
  }
}

export default convertSortName;