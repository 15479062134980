import { gql } from "@apollo/client";


export const GET_TAXONOMIES = gql`
query GetTaxonomies(
$text: String,
$where: QueryTaxonomiesWhereWhereConditions,
$orderBy: [QueryTaxonomiesOrderByOrderByClause!],
){
  taxonomies(
  text: $text,
  where: $where,
  orderBy: $orderBy,
  ){
    id
    title
    type
    original_id
    exhibition_event{
      id
      title
    }
    count_term
    count_obj
    created_at
    updated_at
    taxonomy_terms{
      id
      title
      taxonomy_id
      sort_number
      count_obj
      created_at
    }
  }
}
`;


export const GET_TAXONOMY = gql`
query GetTaxonomy($id: ID!){
  taxonomy(id: $id){
   id
   title
   type
   original_id
   ee_id
   count_term
   count_obj
   created_at
   updated_at
   taxonomy_terms {
      id
      title
      taxonomy_id
      sort_number
      count_obj
      created_at
      updated_at
    }
  }
}
`;
