import React from "react";
import Icons from "../../icons";
import "./color-picker.scss";



const colorArr = [
    ["#A60000", "#E2B3B3", "#CF6565", "#9C4242", "#703737"],
    ["#EF2F2F", "#FFBCBC", "#F57676", "#D43535", "#BB0000"],
    ["#F88800", "#FFD9AC", "#F4B364", "#E9850B", "#CF7200"],
    ["#FFE505", "#FFF5A2", "#FCDF85", "#F3C536", "#DCA700"],
    ["#C1E100", "#C6ECA8", "#A8D681", "#68AD58", "#346B28"],
    ["#4BEEDA", "#C8E6EA", "#91DFE5", "#56C2CA", "#12929C"],
    ["#3F6CE0", "#CEDBFC", "#95ACE8", "#607DC9", "#385190"],
    ["#6D27D6", "#E7D7FF", "#A685D8", "#8056C0", "#5A3297"],
    ["#EA02C3", "#EDCEE8", "#EB99DE", "#C76AB7", "#8F177B"],
    ["#000000", "#E0E0E0", "#9D9D9D", "#535353", "#333333"],
];

const ColorList = ({ colors, currentColor, setColor }) => {

    return colors.map(
        (color, index) => {
            return(
                <li key={ index }
                    className={ color === currentColor ? "current" : ""}
                >
                    <button
                        className="color-select-button"
                        style={{ backgroundColor: color }}
                        onClick={ () => setColor(color) }
                    >
                        { color === currentColor &&
                            <Icons.Check/>
                        }
                    </button>
                </li>
            )
        }
    );

}

const ColorGrid = (props) => {

    const { className } = props;

    return(
        <div className={ `color-grid ${ className }` }>
            { colorArr.map(
                ( colorList, index ) => {
                    return(
                        <ul className="color-group" key={ index }>
                            <ColorList
                                colors={ colorList }
                                { ...props }
                            />
                        </ul>
                    );
                })
            }
        </div>
    );

}

const ColorPicker = {
    ColorGrid
}

export default ColorPicker;