import React from "react";
import { Form, Input, Spin, Typography } from "antd";
import metaFields from '../../../../../utils/meta-fields';
// import link from '../../../../../utils/link';
import './contact-info-form.scss';




const ContactInfoForm = ({
  dataQueryStand,
  loadingQueryStand,
}) => {

  const { getValue, normalize } = metaFields;
  const meta = normalize(dataQueryStand.meta_fields);


  return (
    <div>
      { loadingQueryStand ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <>

          <Typography.Link className="text-decorator">
            <h6>Contact info</h6>
            <div className="separator"></div>
          </Typography.Link>

          <Form.Item className="form-group">
            <Form.Item
              name="company_address-string--contacts"
              label="Address"
              initialValue={ getValue(meta, "company_address") }
            >
              <Input placeholder="Enter your Company Address" />
            </Form.Item>

            <Form.Item
              name="company_city-string--contacts"
              label="City"
              initialValue={ getValue(meta, "company_city") }
            >
              <Input placeholder="Enter your Company City" />
            </Form.Item>

            <Form.Item
              name="company_country-string--contacts"
              label="Country"
              initialValue={ getValue(meta, "company_country") }
            >
              <Input placeholder="Enter your Company Country" />
            </Form.Item>

            <Form.Item
              name="company_postcode-string--contacts"
              label="Postcode"
              initialValue={ getValue(meta, "company_postcode") }
            >
              <Input placeholder="Enter your Company Postcode" />
            </Form.Item>

            <Form.Item
              name="company_phone-string--contacts"
              label="Phone"
              initialValue={ getValue(meta, "company_phone") }
            >
              <Input placeholder="Enter your Company Phone" />
            </Form.Item>

            <Form.Item
              name="company_fax-string--contacts"
              label="Fax"
              initialValue={ getValue(meta, "company_fax") }
            >
              <Input placeholder="Enter your Company Fax" />
            </Form.Item>

            <Form.Item
              name="company_site_url-string--contacts"
              label="Website"
              initialValue={ getValue(meta, "company_site_url") }
            // rules={ [
            //   {
            //     pattern: link.regExp,
            //     message: 'Type URL in right format (http://company-site.com)'
            //   }
            // ]}
            >
              <Input placeholder="Enter your Company Website" />
            </Form.Item>


            <Form.Item
              name="company_email-string--contacts"
              label="E-Mail"
              initialValue={ getValue(meta, "company_email") }
              rules={
                [
                  {
                    type: 'email',
                    message: "Incorrect E-mail format entered"
                  }
                ]
              }
            >
              <Input placeholder="Enter your Company E-Mail" />
            </Form.Item>

          </Form.Item>
        </>
      )
      }
    </div>
  );
}

export default ContactInfoForm;
