import { gql } from "@apollo/client";


export const ATTACHED_UPLOAD = gql`
  mutation AttachedUpload(
    $stand_id: ID!,
    $file: Upload!,
    $status: AttachedStatusEnum
    ) {
    attachedUpload(
      stand_id: $stand_id,
      file: $file,
      status: $status
      ) {
      label
      message
      attachment {
        id
        extension
        original_name
        size
        description
        status
        user {
          id
          name
          surname
        }
      }
    }
}`;


export const ATTACHED_UPDATE = gql`
  mutation AttachedUpdate(
    $attachment_id: ID!,
    $description: String,
    $status: AttachedStatusEnum
    ) {
    attachedUpdate(
      attachment_id: $attachment_id,
      description: $description,
      status: $status
      ) {
      label
      message
      attachment {
        id
        extension
        original_name
        size
        description
        status
        user {
          id
          name
          surname
        }
      }
    }
}`;


export const ATTACHED_DELETE = gql`
  mutation AttachedDelete($attachment_id: ID!) {
    attachedDelete(attachment_id: $attachment_id) {
      label
      message
    }
}`;



