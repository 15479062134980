import React from "react";
import { TaxonomyTabs } from '../components/tabs';



const TaxonomyPage = () => {

  return (
    <div className="page-holder bg-white">
      <TaxonomyTabs />
    </div>
  );
};

export default TaxonomyPage;
