import React from "react";
import NormalTermsUpdateForm from './normal-terms-update-form';
import useMutationTermsUpdate from '../hooks/use-mutation-terms-update';
import useMutationTermDelete from '../hooks/use-mutation-term-delete';

import {
  TAXONOMY_TERMS_UPDATE,
  TAXONOMY_TERM_DELETE
} from '../../../../graphql/mutation/taxonomies-gql'


import './terms-update-form.scss';




const TermsUpdateForm = ({ dataQuery, loadingQuery }) => {



  const {
    _setMutationTermsUpdate,
    loadingMutationTermsUpdate

  } = useMutationTermsUpdate(TAXONOMY_TERMS_UPDATE, dataQuery);


  const {
    _setMutationTermDelete,
    loadingTermDelete

  } = useMutationTermDelete(TAXONOMY_TERM_DELETE, dataQuery);


  return (
    <>
      <NormalTermsUpdateForm
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        setMutationTermsUpdate={ _setMutationTermsUpdate }
        loadingMutationTermsUpdate={ loadingMutationTermsUpdate }
        setMutationTermDelete={ _setMutationTermDelete }
        loadingTermDelete={ loadingTermDelete }
      />
    </>
  );
};


export default TermsUpdateForm;
