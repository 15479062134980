import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tabs, Typography } from "antd";
import PackageCreateForm from '../../forms/packages-form/package-create-form';
import PackageRules from './../../packages/package-rules/package-rules';
import { GET_PACKAGE } from '../../../graphql/query/packages-gql';

import './package-tabs.scss';






const PackageTabs = () => {

    const { pckgId = undefined, pckgTab } = useParams();
    let history = useHistory();


    const { data, loading } = useQuery(GET_PACKAGE, {
        variables: {
            id: pckgId
        },
        // notifyOnNetworkStatusChange: true,
        // fetchPolicy: "cache-and-network",
    });

    const singlePackage = (!loading && data?.package) || false;
    const { TabPane } = Tabs;

    return (
        <div className="tabs-form event-tabs">
            {
                !loading && (!pckgId ?
                    <h3>Add Package</h3> :
                    <h3>Edit Package: <Typography.Link>{ singlePackage.title }</Typography.Link></h3>)
            }

            <Tabs
                tabPosition="top"
                activeKey={ pckgTab ? pckgTab : 'package' }
                onTabClick={ (key) => {
                    changeTab(history, key, pckgId);
                } }
            >
                <TabPane tab="Package" key="package">
                    <PackageCreateForm
                        dataQuery={ singlePackage }
                        loadingQuery={ loading } />
                </TabPane>
                { !!pckgId && (
                    <>
                        <TabPane tab="Rules" key="rules">
                            <PackageRules pckgId={ pckgId } />
                        </TabPane>
                    </>
                ) }
            </Tabs>
        </div>
    );
};


export default PackageTabs;


//Pure functions

const changeTab = (history, tabKey, pckgId) => {

    if (!!pckgId)
    {
        if (tabKey === 'package')
        {
            history.push(`/packages/${ pckgId }`);
        } else
        {
            history.push(`/packages/${ pckgId }/${ tabKey }`);
        }
    } else
    {
        history.push(`/packages/add-package`);
    }
};