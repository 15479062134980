import React from "react";
import { TaxonomyTabs } from '../components/tabs';




const AddTaxonomyPage = () => {

  return (
    <div className="page-holder bg-white">
       <TaxonomyTabs />
    </div>
  );
};

export default AddTaxonomyPage;
