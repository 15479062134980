import React from 'react';
import ProfileTabs from '../components/tabs';
// import { UserAddForm } from '../components/forms/user-form/user-form';

const AddUserPage = () => {

    return (
        <div className="page-holder bg-white">
            <h3>Add User</h3>
            <div className="form-container lg">
                {/* <UserAddForm /> */ }

                <div className="form-container lg">
                    <ProfileTabs />
                </div>
            </div>
        </div>
    );
};

export default AddUserPage;