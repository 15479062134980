import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";



const useMutationUserDelete = (mutation) => {

  const history = useHistory();

  const [ _setMutationVariableUserDelete,
    { loading: loadingUserDelete,
      error: errorMutationUserDelete } ] = useMutation(mutation,
        {
          update(cache, { data }) {

            const {
              userDelete: {
                label,
                message
              }
            } = data;

            history.push(`/users/`);

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
        });

  return {
    _setMutationVariableUserDelete,
    loadingUserDelete,
    errorMutationUserDelete,
  }
};

export default useMutationUserDelete;