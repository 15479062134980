import React, { useState } from "react";
import { Form, Button, Radio, Spin, Input } from "antd";
import { errorNotification } from "../../../result";
import AttachIcon from './../../../../utils/attach-icon';


const NormalUpdateAttachmentForm = ({
  dataQuery,
  loadingQuery,
  loadingMutationStandUpdateFile,
  setMutationStandUpdateFile,
  setModalVisible
}) => {

  const [ form ] = Form.useForm();


  const [ radioValue, setRadioValue ] = useState(dataQuery.status);
  const [ descrValue, setDescrValue ] = useState(dataQuery.description);


  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };



  const formSubmit = ({ setMutationStandUpdateFile, values }) => {

    setDescrValue(values.description)

    setMutationStandUpdateFile({
      variables: {
        attachment_id: !loadingQuery && dataQuery.id,
        status: values.status,
        description: values.description
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });

    form.resetFields();
    setModalVisible(false);
  };


  return (

    loadingQuery ? (
      <div className="block-loader h-300px" >
        <Spin size="small" />
      </div>
    ) : (
      <Form
        form={ form }
        onFinish={ (values) => {
          formSubmit({ setMutationStandUpdateFile, values });
        } }
        layout="vertical"
        className="language-form"
      >
        <div className="d-flex align-items-center">
          <div style={ { marginRight: '10px', transform: 'scale(1.4)' } }>
            <AttachIcon extension={ dataQuery.extension } />
          </div>
          <div style={ { width: '100%', overflow: 'hidden' } }>
            <div className="download-file-info">
              <div className="download-file-name">
                <strong className="file-name">{ dataQuery.original_name }</strong>
                <strong>.{ dataQuery.extension }</strong>
              </div>
            </div>
            <span style={ { color: '#888888' } }>{ dataQuery.size }</span>
          </div>
        </div>
        <Form.Item
          initialValue={ radioValue }
          name="status"
          label="Status"
          style={ { margin: "10px 0" } }>
          <Radio.Group className="radio-row" onChange={ radioOnChange } value={ radioValue }>
            <Radio.Button value="active">Active</Radio.Button>
            <Radio.Button value="draft">Draft</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          initialValue={ descrValue }
        >
          <Input.TextArea
            maxLength={ 190 }
            showCount={
              { formatter: ({ count, maxLength }) => maxLength - count }
            }
            placeholder="Enter Attachment description"
            autoSize={ { minRows: 3, maxRows: 5 } }
          />
        </Form.Item>

        <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
          <Button loading={ loadingMutationStandUpdateFile } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
        </Form.Item>
      </Form>
    )
  )
}


export default NormalUpdateAttachmentForm;