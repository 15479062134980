import React from "react";
import { Upload, Button } from "antd";

import AvatarImage from "../avatar-image";
import { errorNotification } from "../../../result";

import "./avatar-upload.scss";



const AvatarUpload = ({
    variables = {},
    uploadMutation = () => { },
    loading,
    image,
    shape,
    size = 40,
    extraClass,
    uploadButtonText = "Upload Photo",
    disabled = false
}) => {



    const uploadProps = {
        className: `photo-upload ${ extraClass }`,
        disabled,
        accept: 'image/*',
        "beforeUpload": (file) => {

            let uploadVar;

            if (variables.input !== undefined)
            {
                uploadVar = { ...variables };
                uploadVar.input.file = file;
            } else
            {
                uploadVar = { ...variables, file }
            }

            uploadMutation({
                variables: {
                    ...uploadVar
                }

            }).catch((error) => {
                errorNotification(error);
            });

            return false;
        },
        showUploadList: false
    };

    return (
        <Upload { ...uploadProps }>
            <AvatarImage
                size={ size }
                photoUrl={ image }
                shape={ shape }
                badge={ <div className="badge-plus">+</div> }
                loading={ loading }
            />
            <Button type="default" disabled={ disabled }>{ uploadButtonText }</Button>
        </Upload>
    );
};

export default AvatarUpload;