import React, { useState } from "react";
import { Form, Button, Input, Radio, Select, Typography } from "antd";
import { errorNotification } from "../../../result";
import SelecStands from '../../select-stands';
import packageRules from '../../../../utils/package-rules';
import useQueryStands from '../../select-stands/hooks/use-query-select-stand';
import { GET_STAND_FOR_SELECT } from '../../../../graphql/query/showrooms-gql';

const { TextArea } = Input;
const { Option } = Select;




const NormalProductCreateForm = ({
  standId,
  setMutationProductCreateUpdate,
  loadingMutationProductCreateUpdate,

}) => {


  const [ form ] = Form.useForm();

  const [ standName, setStandName ] = useState(undefined);
  const [ ruleKey, setRuleKey ] = useState(false);
  const [ ruleIsempty, setRuleIsempty ] = useState(true);
  const [ maxAmountPackageRule, setMaxAmountPackageRule ] = useState(false);
  const [ activePackageRule, setActivePackageRule ] = useState(false);
  const [ standIdforSelect, setstandIdforSelect ] = useState(undefined);


  const {
    dataQuery,
    loadingQuery,
    getStands
  } = useQueryStands(GET_STAND_FOR_SELECT, standName);


  const {
    permittedNumbersStand,
    disabledEntityFromRules,
  } =
    packageRules.packageRuleForStandSelect(
      standIdforSelect,
      ruleKey,
      maxAmountPackageRule,
      activePackageRule
    );


  const formSubmit = ({ values }) => {

    setMutationProductCreateUpdate({
      variables: {
        input: {
          stand_id: values[ 'stand_id--unset' ].value,
          title: values.title,
          description: values.description,
          type: values.type
        }
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });

  };


  const onSelectProductType = ({ value }) => {

    setstandIdforSelect(form.getFieldValue([ 'stand_id--unset' ])?.value);

    if (value === 'author')
    {
      setRuleKey('product_amount_author');
    } else
    {
      setRuleKey('product_amount');
    }

    if (standId)
    {
      setStandName(form.getFieldValue([ 'stand_id--unset' ]).label.props.children[ 0 ])
      getStands();
    }
  }



  return (

    <>
      <Form
        className="form-container lg add-product-form"
        form={ form }
        onFinish={ (values) => {
          formSubmit({ values });
        } }
        layout="vertical"
      >
        <Form.Item className="form-group">

          <SelecStands
            standId={ standId }
            ruleKey={ ruleKey }
            dataQuery={ dataQuery }
            loadingQuery={ loadingQuery }
            setMaxAmountPackageRule={ setMaxAmountPackageRule }
            setActivePackageRule={ setActivePackageRule }
            setRuleIsempty={ setRuleIsempty }
          />

          <Form.Item
            name="status"
            label="Status"
            initialValue="draft">
            <Radio.Group
              // disabled={ true }
              className="radio-row"
            >
              <Radio.Button value="draft">Draft</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="type"
            label="Product type"
            rules={ [ {
              required: true,
              message: "Select Product type!"
            } ] }
          >
            <Select
              onSelect={ (value) => {
                onSelectProductType({ value })
              }
              }
              placeholder="Select Product type">
              <Option value="book">Book</Option>
              <Option value="author">Author</Option>
              <Option value="product">Product</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="title"
            label="Title"
            rules={ [
              {
                type: "string",
              },
              { required: true, message: 'Please input your Title' }
            ] }
          >
            <Input placeholder="Enter your Title" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <TextArea
            maxLength={ 1200 }
            placeholder="Enter short product description"
            autoSize={ { minRows: 3, maxRows: 5 } }
            showCount={
              { formatter: ({ count, maxLength }) => maxLength - count }
            }
          />
        </Form.Item>

        <div
          className="d-flex justify-content-between align-items-center"
          style={ { padding: '10px 0' } }
        >
          <Button
            // disabled={ standId ? !standId : disabledEntityFromRules }
            disabled={ disabledEntityFromRules }
            loading={ loadingMutationProductCreateUpdate }
            type="primary"
            htmlType="submit">Save</Button>

          <Typography.Text type="danger"
            style={ { maxWidth: '360px', padding: '0 10px', lineHeight: '1.1' } }>
            {
              ruleIsempty ?
                (
                  maxAmountPackageRule &&
                    (permittedNumbersStand || !activePackageRule) ?
                    <span>
                      { permittedNumbersStand && 'The maximum number of products has been reached by the rules of the showroom package' }
                      { (permittedNumbersStand && !activePackageRule) && ' and also ' }
                      { !activePackageRule && 'This action is prohibited by the rules of the showroom package' }
                    </span> :
                    ''
                )
                :
                'No tariff package is selected for this showroom. You cannot add product.'
            }
          </Typography.Text>
        </div>
      </Form>
    </>
  );
};


export default NormalProductCreateForm;

