import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
import { GET_ATTACHMENTS_STAND } from "../../../../graphql/query/attachments-gql";

const useMutationStandUploadFile = (mutation, dataQuery) => {



  const [ _setMutationStandUploadFile, { loading: loadingMutationStandUploadFile } ] = useMutation(mutation,
    {
      update(cache, { data }) {
        const {
          attachedUpload: {
            label,
            message,
            attachment
          }
        } = data;


        cache.writeQuery({
          query: GET_ATTACHMENTS_STAND,
          variables: {
            id: dataQuery.id
          },
          data: {
            attachmentsStand: {
              data: [
                attachment
              ],
              __typename: "AttachmentPaginator"
            }
          }
        })


        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
    });


  return {
    _setMutationStandUploadFile,
    loadingMutationStandUploadFile,
  }
};

export default useMutationStandUploadFile;