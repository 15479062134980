import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { successNotification } from "../../../result";
import {/* GET_STAND,*/ GET_STAND_PRODUCTS, GET_STAND_AMOUNTS } from "../../../../graphql/query/showrooms-gql";



const useMutationProductDelete = (mutation) => {

  const { standId } = useParams();


  const [ _setMutationProductDelete,
    { loading, error } ] = useMutation(mutation,
      {
        update(cache, { data }) {

          const {
            productDelete: {
              label,
              message
            }
          } = data;



          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },

        refetchQueries: [

          {
            query: GET_STAND_PRODUCTS,
            variables: { stand_id: standId },
          },

          {
            query: GET_STAND_AMOUNTS,
            variables: { stand_id: standId },
            // notifyOnNetworkStatusChange: true,
            // fetchPolicy: "cache-and-network",
          },
        ]
      });

  return {
    _setMutationProductDelete,
    loadingProductDelete: loading,
    errorMutationProductDelete: error,
  }
};

export default useMutationProductDelete;