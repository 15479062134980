import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";


const useMutationPackagAddStand = (mutation, dataQuery) => {


  const [ _setMutationPackageAddStand,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            standPackage: {
              label,
              message,
            }
          } = data;

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationPackageAddStand,
    loadingMutationAddStand: loading,
  }
};

export default useMutationPackagAddStand;