import { useMutation } from "@apollo/client";
import { successNotification } from '../../../result';

const useMutationChangePassword = (mutation) => {

  const [ _setMutationVarChangePass, { loading: loadingMutationChangePass } ] = useMutation(mutation,
    {
      update(cache, { data }) {
        const {
          userChangePassword: {
            label,
            message
          }
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      }
    });


  return {
    _setMutationVarChangePass,
    loadingMutationChangePass,
  }
};

export default useMutationChangePassword;