import React from "react";
import LanguagesTable from "./languages-table";
// import SearchForm from "../../forms/search-form"
import { Layout } from 'antd';
import { GET_LANGUAGES } from "../../../graphql/query/languages-gql";
import useQueryLanguages from './hooks/use-query-languages';
import EditModal from './../../service/edit-modal';
import LanguageForm from './../../forms/localization-forms/language-form';

import './languages.scss';



const { Sider, Content } = Layout;


const Languages = () => {


  const {
    loadingQuery,
    dataQuery,
    handleTableChange,
    // history,
    // setSearchVal,
    // routePage,
  } = useQueryLanguages(GET_LANGUAGES);


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <div className="title-indent">
            <h1>Languages</h1>
          </div>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <EditModal valueBtn={ 'Create Language' } titleModal={ 'Create Language' }>
            <LanguageForm dataQuery={ dataQuery } loadingQuery={ loadingQuery } />
          </EditModal>
          {/* <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } /> */ }
        </Sider>
      </Layout>
      <LanguagesTable
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        handleTableChange={ handleTableChange }
      />
    </>
  );
};

export default Languages;
