import { useParams } from "react-router-dom";
import ProductUpdateAuthorForm from '../../forms/product-forms/product-update-author-form';
import ProductUpdateServiceForm from '../../forms/product-forms/product-update-service-form/product-update-service-form';
import ProductUpdateBookForm from './../../forms/product-forms/product-update-book-form/product-update-book-form';
import useQueryProduct from './hooks/use-query-product';



import {
  GET_PRODUCT
} from '../../../graphql/query/products-gql'


const Product = () => {

  const { productId = false } = useParams();

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
  } = useQueryProduct(GET_PRODUCT, productId);



  let typeProduct = !loadingQuery && dataQuery.type

  function showFormByType(typeProduct) {
    switch (typeProduct)
    {
      case 'author':
        return (
          <ProductUpdateAuthorForm
            dataQuery={ dataQuery }
            loadingQuery={ loadingQuery }
            errorQuery={ errorQuery } />
        );
      case 'product':
        return (
          <ProductUpdateServiceForm
            dataQuery={ dataQuery }
            loadingQuery={ loadingQuery }
            errorQuery={ errorQuery } />
        );

        case 'book':
          return (
            <ProductUpdateBookForm
              dataQuery={ dataQuery }
              loadingQuery={ loadingQuery }
              errorQuery={ errorQuery } />
          );
      default:
        return '';
    }
  }

  return (

    <>
      { showFormByType(typeProduct) }
    </>

  );
};

export default Product;
