const filterObj = (filters = {}) => {

  let fields = {};

  if (filters)
  {
    for (let [ key, value ] of Object.entries(filters))

      if (value !== null)
      {
        fields[ 'column' ] = key;
        fields[ 'value' ] = value[ 0 ];
      }

    return fields;
  }
};


export default filterObj;


// if (filterObj(filters) !== {})
// {
//   setFilter(
//     { ...filterObj(filters) }
//   )
// } else
// {
//   setFilter(undefined)
// }