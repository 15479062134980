import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { successNotification, errorNotification } from "../../../result";
import { arrayMoveImmutable } from 'array-move';


const useMutationPckgRulesSet = (mutation, dataQuery, loadingQuery, pckgId) => {


  const [ _setMutationPckgRulesSet,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            packageRulesOrderSet: {
              label,
              message
            }
          } = data;

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });

  const initDataSource = !loadingQuery && dataQuery;
  const [ dataSource, setDataSource ] = useState(initDataSource);


  useEffect(() => {
    setDataSource(initDataSource)
  }, [ initDataSource ]);



  const onSortEnd = ({ oldIndex, newIndex }) => {

    if (oldIndex !== newIndex)
    {
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);

      setDataSource(newData)

      const arrNewDataId = newData.map(({ id }) => id);


      _setMutationPckgRulesSet({
        variables: {
          package_id: pckgId,
          ids: arrNewDataId
        }
      }).catch((errorMutation) => {
        errorNotification(errorMutation);
      });

    }
  };



  return {
    loadingMutationAddStand: loading,
    onSortEnd,
    dataSource
  }
};


export default useMutationPckgRulesSet;