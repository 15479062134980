import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tabs, Collapse, Button, Typography } from "antd";
import CompanyProfileForm from '../../forms/showroom-forms/company-profile-form';
import CompanyPageForm from '../../forms/showroom-forms/company-page-form';
import CompanyProductsForm from '../../forms/showroom-forms/company-products-form';
import CompanyDownloadForm from '../../forms/showroom-forms/company-download-form';
import StandUser from '../../showrooms/stand-users';
import StandProducts from '../../showrooms/stand-products/stand-products';
import CompanyCommunicationPageForm from '../../forms/showroom-forms/company-communication-form';
import PackageRequestsStand from '../../packages/package-requests-stand/package-requests-stand';
import packageRules from './../../../utils/package-rules';
import StandUploadFile from '../../showrooms/stand-upload-file';
import StandDownloads from '../../showrooms/stand-downloads';


import { GET_STAND } from './../../../graphql/query/showrooms-gql';


import './showroom-tabs.scss';





const { Panel } = Collapse;
const { TabPane } = Tabs;




const SettingsPanel = ({ stand, standId, loading }) => {


    const {
        permittedNumbersStand,
        activePackageRule,
        disabledEntityFromRules
    } = Rules(stand);


    return (
        <Collapse>
            <Panel
                header="Page settings"
                key="1"
                extra={
                    <div className='interface-block'>
                        { stand.package?.package_rules ?
                            (
                                (permittedNumbersStand || !activePackageRule) ?
                                    <span>
                                        { permittedNumbersStand && 'Maximum number of "Product" reached of this package' }
                                        { (permittedNumbersStand && !activePackageRule) && ' and also ' }
                                        { !activePackageRule && 'This action is prohibited in the rules of this package:' }

                                        <Link
                                            className="form-link"
                                            to={ {
                                                pathname: `/packages/${ stand.package?.id }`,
                                                state: {
                                                    breadcrumbSlug: stand.package?.title,
                                                }
                                            } }
                                        >
                                            <Typography.Link>
                                                <strong> "{ stand.package?.title }" </strong>
                                            </Typography.Link>
                                        </Link>
                                    </span> : ''
                            )
                            : 'No tariff package is selected for this showroom. You cannot add product.'

                        }
                        <Link
                            style={ { position: 'absolute', bottom: '7px', right: '10px' } }
                            to={ {
                                pathname: `/products/add-product/${ standId }`,
                                state: {
                                    stand_id: standId,
                                    stand_title: stand.title
                                }
                            } }>
                            <Button disabled={ disabledEntityFromRules } type="primary">Add product</Button>
                        </Link>
                    </div>
                }
            >
                <CompanyProductsForm
                    dataQueryStand={ stand }
                    loadingQueryStand={ loading } />
            </Panel>
        </Collapse>
    )
}



const ShowroomTabs = () => {

    const { standId = false, standTab } = useParams();
    let history = useHistory();

    const { data, loading } = useQuery(GET_STAND, {
        variables: {
            standId
        },
        // notifyOnNetworkStatusChange: true,
        // fetchPolicy: "no-cache",
    });


    const { stand = false } = data !== undefined ? data : {};


    return (
        <div className="tabs-form event-tabs">
            {
                !loading && (!standId ?
                    <h3>Add Showroom</h3> :
                    <h3>Edit Showroom: <Typography.Link>{ stand.title }</Typography.Link></h3>
                )
            }

            <Tabs
                tabPosition="top"
                activeKey={ standTab ? standTab : 'company-profile' }
                onTabClick={ (key) => {
                    changeTab(history, key, standId);
                } }
            >
                <TabPane tab="Company Profile" key="company-profile">
                    <CompanyProfileForm
                        dataQueryStand={ stand }
                        loadingQueryStand={ loading } />
                </TabPane>
                { !!standId && (
                    <>
                        <TabPane tab="Company page" key="company-page">
                            <CompanyPageForm
                                dataQueryStand={ stand }
                                loadingQueryStand={ loading } />
                        </TabPane>

                        <TabPane tab="Products" key="products">
                            <>
                                <SettingsPanel
                                    stand={ stand }
                                    standId={ standId }
                                    loading={ loading } />
                                <br />
                                <StandProducts />
                            </>
                        </TabPane>
                        <TabPane tab="Communication page" key="сommunication-page">
                            <CompanyCommunicationPageForm
                                dataQueryStand={ stand }
                                loadingQueryStand={ loading } />
                        </TabPane>
                        <TabPane tab="Download page" key="download-page">
                            <div className="upload-file-list-hidden" style={ { position: 'relative' } }>
                                <div
                                    style={ {
                                        position: 'absolute',
                                        bottom: 'auto',
                                        right: '10px',
                                        top: '8px',
                                        zIndex: '1'
                                    } }
                                >
                                    <StandUploadFile dataQuery={ stand } />
                                </div>

                                <Collapse>
                                    <Panel
                                        header="Page settings"
                                        key="1"

                                    >
                                        <CompanyDownloadForm
                                            dataQueryStand={ stand }
                                            loadingQueryStand={ loading } />
                                    </Panel>
                                </Collapse>
                                <br />
                                <StandDownloads />
                            </div>
                        </TabPane>
                        <TabPane tab="Company Team" key="company-team">
                            <StandUser
                                dataQueryStand={ stand }
                                loadingQuery={ loading } />
                        </TabPane>

                        <TabPane tab="Package" key="package">
                            <PackageRequestsStand
                                dataQuery={ stand }
                                loadingQuery={ loading } />
                        </TabPane>
                    </>
                ) }
            </Tabs>
        </div>
    );
};


export default ShowroomTabs;


//Pure functions

const changeTab = (history, tabKey, standId) => {

    if (!!standId)
    {
        if (tabKey === 'company-profile')
        {
            history.push(`/showrooms/${ standId }`);
        } else
        {
            history.push(`/showrooms/${ standId }/${ tabKey }`);
        }
    } else
    {
        history.push(`/showrooms/add-showroom`);
    }
};


const Rules = (stand) => {


    //Package rules for stand product
    const { packageRuleForStand } = packageRules;


    const x = (ruleKey) => {

        const {
            permittedNumbersStand,
            activePackageRule,
            disabledEntityFromRules,
            disabledEntityFromRules: conditionRule
        } = packageRuleForStand(stand, ruleKey);


        return {
            permittedNumbersStand,
            activePackageRule,
            disabledEntityFromRules,
            conditionRule
        }
    };


    const { conditionRule: productAmount } = x('product_amount');
    const { conditionRule: productAmountAuthor } = x('product_amount_author');


    if (productAmount)
    {
        return x('product_amount_author')
    }


    if (productAmountAuthor)
    {
        return x('product_amount')
    }


    if (!productAmount && !productAmountAuthor)
    {
        return x('product_amount')
    }

}
