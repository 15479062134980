import React from "react";
import NormalTaxonomyCreateForm from './normal-taxonomy-create-form';
import useMutationTaxonomyCreateUpdate from '../hooks/use-mutation-taxonomy-create-update';
import useMutationTaxonomyDelete from '../hooks/use-mutation-taxonomy-delete';


import {
  TAXONOMY_CREATE_UPDATE,
  TAXONOMY_DELETE
} from '../../../../graphql/mutation/taxonomies-gql'


import './taxonomy-create-form.scss';


const TaxonomyCreateForm = ({ dataQuery, loadingQuery }) => {

  const {
    _setMutationTaxonomyCreateUpdate,
    loadingMutationTaxonomyCreateUpdate

  } = useMutationTaxonomyCreateUpdate(TAXONOMY_CREATE_UPDATE, dataQuery);

  const {
    _setMutationTaxonomyDelete,
    loadingTaxonomyDelete,

  } = useMutationTaxonomyDelete(TAXONOMY_DELETE);



  return (
    <>
      <NormalTaxonomyCreateForm
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        setMutationTaxonomyCreateUpdate={ _setMutationTaxonomyCreateUpdate }
        loadingMutationTaxonomyCreateUpdate={ loadingMutationTaxonomyCreateUpdate }
        setMutationTaxonomyDelete={ _setMutationTaxonomyDelete }
        loadingTaxonomyDelete={loadingTaxonomyDelete}
      />
    </>
  );
};


export default TaxonomyCreateForm;
