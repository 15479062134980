import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";


const useMutationStandCreateAndMetaSet = (mutation, dataQuery) => {

  const [ _setMutationVariableStandCreateAndMetaSet,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            standMetaSet: {
              meta_fields
            },
            standCreateOrUpdate: {
              label,
              message,
              stand
            }

          } = data;


          cache.modify({
            id: cache.identify(dataQuery),
            fields: {
              meta_fields() {
                return meta_fields;
              },
              stand() {
                return stand;
              }
            }
          });


          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationVariableStandCreateAndMetaSet,
    loadingMutationStandCreateAndMetaSet: loading,
  }
};

export default useMutationStandCreateAndMetaSet;