import React from "react";
import NormalPackageRequest from './normal-package-request-form';
import useMutationPackageRequest from './hooks/use-mutation-package-request';


import {
  PACKAGE_REQUEST_UPDATE,
} from '../../../../graphql/mutation/packages-gql'


import './package-request-form.scss';


const PackageRequestForm = ({ dataQuery, loadingQuery, reqstId, setModalVisible, requestStatus }) => {

  const {
    _setMutationPackageRequest,
    loadingMutationPackageRequest

  } = useMutationPackageRequest(PACKAGE_REQUEST_UPDATE, dataQuery);



  return (
    <>
      <NormalPackageRequest
        requestStatus={ requestStatus }
        setModalVisible={ setModalVisible }
        reqstId={ reqstId }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        setMutationPackageRequest={ _setMutationPackageRequest }
        loadingMutationPackageRequest={ loadingMutationPackageRequest }
      />
    </>
  );
};

export default PackageRequestForm;
