import React, { useState } from "react";
import { Upload, Button, Spin } from "antd";
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { errorNotification } from "../../../result";

import "./upload-file.scss";



const UploadFile = ({
    variables = {},
    uploadMutation = () => { },
    loading,
    url = false,
    listType = 'picture',
    maxCountFileList = 1,
    uploadButtonText = "Upload file",
    btnType = "default",
    deleteVariables = {},
    deleteMutation = () => { },
    accept = 'file/*',
    productImages = [],
    loadingDelete = false,
}) => {


    const [ uploadError, setUploadError ] = useState(true);

    const uploadButtonCard = (
        <div>
            { loading ? <LoadingOutlined /> : <PlusOutlined /> }
            <div style={ { marginTop: 8 } }>{ uploadButtonText }</div>
        </div>
    );



    const initFileList = url ? (
        [
            {
                name: url.split("/").pop(-1),
                url: url,
                thumbUrl: url,
                link: url
            }
        ]
    ) : false;


    const confirmRemoveEntity = (props) => {
        const {
            deleteVariables,
            deleteMutation,
        } = props;


        let deleteVar;

        if (deleteVariables !== undefined)
        {
            deleteVar = { ...deleteVariables };
        } else
        {
            return false;
        }


        let metaInput = [];

        if (productImages.length !== 0)
        {
            const newImageList = productImages.filter((item) => item.meta_key !== deleteVar.meta_key);

            if (newImageList.length)
            {
                for (let i = 0; i < newImageList.length; i++)
                {

                    const { id } = newImageList[ i ];

                    metaInput.push({
                        id,
                        meta_key: `product_image_${ i + 1 }`,
                        meta_type: "image"
                    });

                }
            }
        } else
        {
            metaInput = undefined;
        }



        deleteMutation({
            variables: {
                ...deleteVar,
                metaInput
            }

        }).catch((error) => {
            errorNotification(error);
        });
    };


    const uploadProps = {
        className: `file-upload`,
        onRemove: () => confirmRemoveEntity({
            deleteVariables,
            deleteMutation,
        }),
        defaultFileList: initFileList,
        maxCount: maxCountFileList,
        disabled: loading,
        listType,
        accept,
        "beforeUpload": (file) => {

            let uploadVar;

            if (variables.input !== undefined)
            {
                uploadVar = { ...variables };
                uploadVar.input.file = file;
            } else
            {
                uploadVar = { ...variables, file }
                setUploadError(!!file)
            }

            uploadMutation({
                variables: {
                    ...uploadVar
                }

            }).catch((error) => {
                errorNotification(error);
                setUploadError(!error)
            });

            return false;
        },
        showUploadList: uploadError
    };

    return (
        <>
            { listType === 'picture' &&
                <>

                    <Upload Upload { ...uploadProps } >
                        <Button type={ btnType } icon={ <UploadOutlined /> }>{ uploadButtonText }</Button>
                    </Upload>
                </>
            }

            { listType === 'picture-card' &&
                <>
                    { loading ? (
                        <div className="block-loader h-300px" >
                            <Spin size="small" />
                        </div>
                    ) : (
                        <Upload { ...uploadProps } >
                            { uploadButtonCard }
                        </Upload>
                    )
                    }
                </>
            }
        </>
    )
};

export default UploadFile;