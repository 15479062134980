import { gql } from "@apollo/client";

export const USER_LOGIN = gql`
mutation UserLogin($userLoginAdmInput: UserLoginInputAdmin) {
  userLogin(input: $userLoginAdmInput) {
    access_token
  }
}
`;

export const USER_LOGOUT = gql`
mutation UserLogout {
  userLogout {
    message
  }
}
`;


export const USER_META_SET = gql`
mutation UserMetaSet($userMetaSetInput: [UserMetaInput], $userMetaSetUserId: ID!, ) {
  userMetaSet(
  input: $userMetaSetInput,
  user_id: $userMetaSetUserId
  ){
    label
    message
    user {
      id
    }
    meta_fields{
      id
      user_id
      meta_key
      meta_value
    }
  }
}
`;

export const USER_META_UPLOAD = gql`
mutation UserMetaUpload(
$userId: ID!,
$userKey: String!,
$file: Upload!
) {
  userMetaUpload(
  user_id: $userId,
  meta_key: $userKey,
  file: $file
  ) {
    label
    message
    user {
      id
      avatar
    }
    meta_fields {
      id
      user_id
      meta_key
      meta_value
    }
  }
}
`;


export const USER_UPLOAD_AVATAR = gql`
mutation UserUploadAvatar($userId: ID!, $file: Upload!) {
  userUploadAvatar(user_id: $userId, file: $file) {
    label
    message
    avatar
  }
}
`;

export const USER_CREATE = gql`
mutation UserCreate($userCreateInput: UserCreateInput!) {
  userCreate(input: $userCreateInput) {
    label
    message
    user {
      id
      name
      surname
      status
    }
  }
}
`;


export const USER_UPDATE = gql`
mutation UserUpdate($userId: ID!, $userInput: UserUpdateInput!) {
  userUpdate(user_id: $userId, input: $userInput) {
    label
    message
    user {
      id
      name,
      email_original,
      role,
      surname,
      locale,
      status
    }
  }
}
`;



export const USER_CHANGE_PASSWORD = gql`
mutation UserChangePassword($userId: ID!, $userInput: UserChangePassword!) {
  userChangePassword(user_id: $userId, input: $userInput) {
    label
    message
  }
}
`;


export const USER_DELETE = gql`
mutation UserDelete($user_id: ID!){
  userDelete(user_id: $user_id){
    label
    message
  }
}
`;





