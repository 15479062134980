import { useQuery } from "@apollo/client";


const useQueryTrnsByKey = (query, trnslnId) => {

  const { loading: loadingQuery, error: errorQuery, data } = useQuery(query, {
    variables: {
      id: trnslnId,
    },
    // notifyOnNetworkStatusChange: true,
    // fetchPolicy: "cache-and-network",
  });


  const { translationKey, languages } =  !!data && data;


  return {
    loadingQuery,
    errorQuery,
    dataQueryKey: translationKey,
    dataQueryLang: languages,
  }
};

export default useQueryTrnsByKey;
