import React from "react";
import { Col, Row } from "antd";
import ChangePasswordForm from "../../../forms/change-password-form";

const ProfileTabChangePassword = ({ getUser }) => {

    return (
        <Row type="flex" className="profile-row">
            <Col lg={ 22 } xl={ 20 } span={ 24 }>
                <ChangePasswordForm getUser={ getUser } />
            </Col>
        </Row>
    );
};

export default ProfileTabChangePassword;