import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";





const useMutationTermsUpdate = (mutation, dataQuery) => {

  const [ _setMutationTermsUpdate,
    { loading } ] = useMutation(mutation,
   {
        update(cache, { data }) {
          const {
            taxonomyTermsUpdate: {
              label,
              message,
              taxonomy_terms
            }
          } = data;


            cache.modify({
              id: cache.identify(dataQuery),
              fields: {
                taxonomy_terms() {
                  return taxonomy_terms;
                },
              },
            });


          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationTermsUpdate,
    loadingMutationTermsUpdate: loading,
  }
};

export default useMutationTermsUpdate;