import { gql } from "@apollo/client";


export const GET_PRODUCTS = gql`
query GetProducts(
$text: String,
$where: QueryProductsWhereWhereConditions,
$orderBy: [QueryProductsOrderByOrderByClause!],
$first: Int,
$page: Int,
) {
  products(
  text: $text,
  where: $where,
  orderBy: $orderBy,
  first: $first,
  page: $page,
  ) {
    paginatorInfo {
      total
      perPage
      lastPage
      currentPage
    }
    data {
      id
      title
      cover
      type
      is_top
      is_searchable
      status
      stand {
        id
        title
      }
      exhibition_event {
        id
        title
        uri
      }
      stand {
        id
        title
      }
      terms {
        id
        title
      }
      created_at
    }
  }
}
`;

export const GET_PRODUCT = gql`
query Product($id: ID!){
  product(id: $id){
    id
    title
    description
    cover
    type
    is_searchable
    is_top
    status
    meta_fields {
      id
      product_id
      meta_key
      meta_type
      meta_value
      meta_group
      product {
        id
      }
    }
    stand {
      id
      title
    }
    exhibition_event {
      id
      title
      meta_fields {
        id
        ee_id
        meta_key
        meta_type
        meta_value
        meta_group
      }
    }
    terms {
      taxonomy_id
      id
      title
    }
    ee_id
    created_at
    updated_at
  }
}
`;


