import React from "react";
import { useLocation } from "react-router-dom";
import { Form, Select, Typography } from "antd";

//Hooks
import useQueryStands from './hooks/use-query-select-stand';

//Query
import { GET_STAND_FOR_SELECT } from '../../../graphql/query/showrooms-gql';

//CSS
import './select-stands.scss';




const { Text } = Typography;
const { Option } = Select;

const SelecStands = ({
  standTitle,
  standId,
  ruleKey = false,
  disabled = false,
  setMaxAmountPackageRule = () => { },
  setActivePackageRule = () => { },
  setRuleIsempty = () => { },
  dataQuery: dataQueryFromProductForm,
}) => {



  const location = useLocation();
  const routeStateStandTitle = location.state?.stand_title;


  const {
    loadingQuery,
    dataQuery,
    onSearch
  } = useQueryStands(GET_STAND_FOR_SELECT);



  let dataQueryCondition;

  if (standId)
  {
    dataQueryCondition = dataQueryFromProductForm;
  } else
  {
    dataQueryCondition = dataQuery;
  }



  const selectSearchField = dataQueryCondition && (
    dataQueryCondition.map((item) => {

      setRuleIsempty(item.package)

      return (
        <Option key={ item.id }
          value={ item.id }
          label={ item.title }
        >
          { item.title !== null ? item.title : 'No Showroom Title' }
          <span>{ `; Showroom ID: ${ item.id }` }</span>


          <span style={ { display: 'none' } }>
            {
              setMaxAmountPackageRule(item?.package?.package_rules?.filter(
                ({ rule_key }) => rule_key === ruleKey
              )[ 0 ]?.max_value)

            }
          </span>

          <span style={ { display: 'none' } }>

            {
              setActivePackageRule(item?.package?.package_rules?.filter(
                ({ rule_key }) => rule_key === ruleKey
              )[ 0 ]?.active)
            }
          </span>
        </Option>
      )
    })
  );


  return (

    <Form.Item
      name="stand_id--unset"
      label="Showroom"

      initialValue={ {
        value: `${ standId }`,
        label:
          <>
            {
              standTitle !== null || !!routeStateStandTitle ?
                standTitle || routeStateStandTitle : 'No Showroom Title'
            }
            {
              !!standId ?
                <span>; Showroom ID: { standId } </span> :
                <Text disabled>Select showroom</Text>
            }
          </>
      } }

    >
      <Select
        disabled={ disabled }
        className="select-input-space-between"
        dropdownClassName="select-dropdown-space-between"
        labelInValue
        loading={ loadingQuery }
        onSearch={
          (value) =>
            onSearch(value)
        }
        notFoundContent="There is no showroom with that name"
        showSearch
        placeholder="Select showroom"
        optionFilterProp="children"
      >
        { selectSearchField }
      </Select>
    </Form.Item>
  )
};

export default SelecStands;

