import { InMemoryCache, makeVar } from "@apollo/client";


const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                platformParams: {
                    read() {
                        return platformParamsVar();
                    }
                }
            },
        },
    },
});

export const platformParamsInitial = {
    mainContentBgColor: '#ffffff',
};

export const platformParamsVar = makeVar(platformParamsInitial);

export default cache;



//GET

//Function call example WITHOUT rerendering:
//import useVarParam from './../hooks/use-var-param';

//const headerColor = useVarParam().get("mainContentBgColor");


// FROM HOOK Apollo

// Function call example WITH rerendering:
// import { useReactiveVar  } from '@apollo/client';
// import { platformParamsVar } from "../../graphql/cache";

// const headerColor = useReactiveVar(platformParamsVar).mainContentBgColor;


// FROM local QUERY Apollo

// Function call example WITH rerendering:
// import { useQuery  } from '@apollo/client';
// import { GET_PLATFORM_PARAMS } from "../../graphql/query/local-store-gql";

// const { data } = useQuery(GET_PLATFORM_PARAMS);
// const headerColor = data.platformParams.mainContentBgColor;


//SET

// Function call example Set
// import useVarParam from './../hooks/use-var-param';

// const headerColor = useVarParam();
// headerColor.set({ mainContentBgColor: currentColor })