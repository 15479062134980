import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";
import { GET_USER } from './../../../../../graphql/query/user-gql';

const useMutationMetaUpload = (mutation, getUser) => {

  const [ _setMutationVariableUpload,
    { loading: loadingMutation,
      error: errorMutation, data } ] = useMutation(mutation,
        {
          update(_, { data }) {
            const {
              userMetaUpload: {
                label,
                message
              }
            } = data;


            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
          refetchQueries: [
            {
              query: GET_USER,
              variables: { userAdmId: getUser.id },
            },
          ],
        });

  return {
    _setMutationVariableUpload,
    loadingMutation,
    errorMutation,
    dataMutation: data && data.nameMutatiom
  }
};

export default useMutationMetaUpload;