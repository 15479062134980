import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";

const useMutationTaxonomyCreateUpdate = (mutation, dataQuery) => {



  const [ _setMutationTaxonomyCreateUpdate,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            taxonomyCreateOrUpdate: {
              label,
              message,
              taxonomy
            }
          } = data;


          if (!!dataQuery)
          {
            cache.modify({
              id: cache.identify(dataQuery),
              fields: {
                product() {
                  return taxonomy;
                },
              },
            });
          }

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationTaxonomyCreateUpdate,
    loadingMutationTaxonomyCreateUpdate: loading,
  }
};

export default useMutationTaxonomyCreateUpdate;