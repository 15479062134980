import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
// import metaFields from '../../../../utils/meta-fields';

const useMutationProductMetaUpload = (mutation, dataQuery, form) => {

  // const { normalize } = metaFields;

  const [
    _setMutationProductUpload,
    { loading }
  ] = useMutation(mutation,
    {
      update(cache, { data }) {
        const {
          productMetaUpload: {
            label,
            message,
            product: {
              cover,
              meta_fields
            }

          }
        } = data;


        cache.modify({
          id: cache.identify(dataQuery),
          fields: {
            cover() {
              return cover;
            },
            meta_fields() {
              return meta_fields;
            }
          },
        });


        form.setFieldsValue({
          "cover-image-unset": cover,
        });

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
    });


  return {
    _setMutationProductUpload,
    loadingMutationProductUpload: loading,
  }
};

export default useMutationProductMetaUpload;
