import { gql } from "@apollo/client";


export const EXBN_EVNT_CREATE = gql`
mutation ExbnEvntCreate($eeCreateInput: EE_CreateInput!) {
  eeCreate(input: $eeCreateInput) {
    label
    message
    exhibition_event {
      id
      status
    }
  }
}
`;

export const EXBN_EVNT_UPDATE = gql`
mutation ExbnEvntUpdate($eeUpdateEeId: ID!, $eeUpdateInput: EE_CreateInput!) {
  eeUpdate(ee_id: $eeUpdateEeId, input: $eeUpdateInput) {
    label
    message
    exhibition_event {
      id
      status
      title
      uri
      archive_at
      end_at
      start_at
    }
  }
}
`;

export const EXBN_EVNT_META_SET = gql`
mutation EeMetaSetMutation($eeMetaSetEeId: ID!, $eeMetaSetInput: [EE_MetaInput]) {
  eeMetaSet(ee_id: $eeMetaSetEeId, input: $eeMetaSetInput) {
    label
    message
    exhibition_event {
      id
    }
    meta_fields {
      id
      ee_id
      meta_key
      meta_value
    }
  }
}
`;

export const EXBN_EVNT_META_UPLOAD = gql`
mutation EeMetaUploadMutation(
$ee_id: ID!,
$meta_key: String!,
$meta_group: String,
$meta_type: String,
$file: Upload!
) {
  eeMetaUpload(
  ee_id: $ee_id,
  meta_key: $meta_key,
  meta_group: $meta_group,
  meta_type: $meta_type,
  file: $file
  ) {
    label
    message
    exhibition_event {
      id
      meta_fields {
        id
        ee_id
        meta_key
        meta_value
      }
    }
  }
}
`;


export const EXBN_EVNT_META_DELETE = gql`
  mutation EeMetaDelete(
    $ee_id: ID!,
    $meta_key: String
    ) {
    eeMetaDelete(
      ee_id: $ee_id,
      meta_key: $meta_key
      ) {
      label
      message
      exhibition_event {
        id
        meta_fields {
          id
          meta_key
          meta_value
          meta_group
        }
      }
    }
  }
`;






