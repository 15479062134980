import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
import { GET_ATTACHMENTS_STAND } from "../../../../graphql/query/attachments-gql";



const useMutationAttachedDelete = (mutation, dataQuery) => {


  const [ _setMutationAttachedDelete,
    { loading, error } ] = useMutation(mutation,
      {
        update(cache, { data }) {

          const {
            attachedDelete: {
              label,
              message
            }
          } = data;



          const filteredArr = dataQuery.data.filter(
            (item) => item.id !== dataQuery.data.id
          )


          // cache.writeQuery({
          //   query: GET_TAXONOMY,
          //   variables: {
          //     id: dataQuery.id
          //   },
          //   data: {
          //     taxonomy: {
          //       taxonomy_terms: [
          //         ...filteredArr
          //       ],
          //       __typename: dataQuery.__typename
          //     }
          //   }
          // })

          cache.writeQuery({
            query: GET_ATTACHMENTS_STAND,
            variables: {
              id: dataQuery.id
            },
            data: {
              attachmentsStand: {
                data: [
                  filteredArr
                ],
                __typename: "AttachmentPaginator"
              }
            }
          })



          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },

        // refetchQueries: [

        //   {
        //     query: GET_STAND_PRODUCTS,
        //     variables: { stand_id: standId },
        //   },

        //   {
        //     query: GET_STAND_AMOUNTS,
        //     variables: { stand_id: standId },
        //     // notifyOnNetworkStatusChange: true,
        //     // fetchPolicy: "cache-and-network",
        //   },
        // ]
      });

  return {
    _setMutationAttachedDelete,
    loadingAttachedDelete: loading,
    errorMutationAttachedDelete: error,
  }
};

export default useMutationAttachedDelete;