import React from "react";
import { Layout, /*Button*/ } from 'antd';
import PackageRulesTable from "./package-rules-table";
import useQueryPackageRules from './hooks/use-query-package-rules';
import useMutationPackageRuleDelete from './hooks/use-mutation-package-rule-delete';
import EditModal from './../../service/edit-modal';
import PackageRuleCreateForm from '../../forms/packages-form/package-rule-create-form';
import useMutationPckgRulesSet from './hooks/use-mutation-pckg-rules-order-set';


import { GET_PACKAGE_RULES } from "../../../graphql/query/packages-gql";
import {
  PACKAGE_RULE_DELETE,
  PACKAGE_RULES_ORDER_SET
} from "../../../graphql/mutation/packages-gql";


import './package-rules.scss';





const { Sider, Content } = Layout;



const PackageRules = ({ pckgId }) => {


  const {
    loadingQuery,
    errorQuery,
    dataQuery,
    pagination,
    handleTableChange

  } = useQueryPackageRules(GET_PACKAGE_RULES, pckgId);


  const {
    _setMutationPackageRuleDelete,
    loadingPackageRuleDelete

  } = useMutationPackageRuleDelete(PACKAGE_RULE_DELETE, dataQuery);


  const {
    onSortEnd,
    dataSource
  } = useMutationPckgRulesSet(PACKAGE_RULES_ORDER_SET, dataQuery, loadingQuery, pckgId);


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Rules</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <EditModal valueBtn={ 'Add Rule' } titleModal={ 'Add Rule' }>
            <PackageRuleCreateForm />
          </EditModal>
        </Sider>
      </Layout>
      <PackageRulesTable
        pagination={ pagination }
        onSortEnd={ onSortEnd }
        dataSource={ dataSource }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        handleTableChange={ handleTableChange }
        setMutationPackageRuleDelete={ _setMutationPackageRuleDelete }
        loadingPackageRuleDelete={ loadingPackageRuleDelete }
      />
    </>
  );
};

export default PackageRules;
