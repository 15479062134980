import React from 'react';
import Icon from '@ant-design/icons';


const FileSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M35.4,18.8l-8.2-8.1c-0.2-0.2-0.6-0.4-0.9-0.4H15.1c-0.7,0-1.3,0.6-1.3,1.3v26.2c0,0.7,0.6,1.3,1.3,1.3h19.4
	c0.7,0,1.3-0.6,1.3-1.3V19.7C35.8,19.4,35.7,19,35.4,18.8z M32.8,20.7h-7c-0.2,0-0.4-0.2-0.4-0.4v-7c0-0.4,0.4-0.5,0.7-0.3l7,7
	C33.3,20.3,33.1,20.7,32.8,20.7z"/>
    </svg>
);

const FileIcon = props => {
    return <Icon component={ FileSvg } />;

};


export default FileIcon;