import React from "react";
import NormalEventThemeForm from './normal-event-theme-form';
import useMutationEeMetaSet from '../hooks/use-mutation-exbn-evnt-meta-set';
import { EXBN_EVNT_META_SET } from '../../../../graphql/mutation/exhibition-gql';

import './event-theme-form.scss';




const EventThemeForm = ({ exbnEvnt, loadingQueryEvent }) => {


  const {
    _setMutationVariableEeMetaSet,
    loadingMutationEeMetaSet
  } = useMutationEeMetaSet(EXBN_EVNT_META_SET, exbnEvnt);


  return (
    <NormalEventThemeForm
      exbnEvnt={ exbnEvnt }
      loadingQueryEvent={ loadingQueryEvent }
      setMutationVariableEeMetaSet={ _setMutationVariableEeMetaSet }
      loadingMutationEeMetaSet={ loadingMutationEeMetaSet }
    />
  );
};

export default EventThemeForm;
