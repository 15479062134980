import { gql } from "@apollo/client";


export const TAXONOMY_CREATE_UPDATE = gql`
mutation TaxonomyCreateOrUpdate(
$id: ID,
$title: String,
$type: TaxonomyTypeEnum,
$ee_id: ID
){
  taxonomyCreateOrUpdate(
  id: $id,
  title: $title,
  type: $type,
  ee_id: $ee_id
  ){
    label
    message
    taxonomy {
      id
      title
      type
      original_id
      ee_id
      count_term
      count_obj
      created_at
      updated_at
    }
  }
}
`;


export const TAXONOMY_DELETE = gql`
mutation TaxonomyDelete($id: ID!){
  taxonomyDelete(id: $id){
    label
    message
  }
}
`;

export const TAXONOMY_TERM_CREATE_UPDATE = gql`
mutation TaxonomyTermCreateOrUpdate(
$taxonomy_id: ID,
$id: ID,
$title: String,
$sort_number: Int
) {
  taxonomyTermCreateOrUpdate(
  taxonomy_id: $taxonomy_id,
  id: $id,
  title: $title,
  sort_number: $sort_number
  ) {
    label
    message
    taxonomy_term {
      id
      taxonomy_id
      title
      sort_number
      count_obj
    }
  }
}
`;

export const TAXONOMY_TERMS_UPDATE = gql`
mutation TaxonomyTermsUpdate(
$input: [TaxonomyTermInput]
){
  taxonomyTermsUpdate(input: $input){
    label
    message
    taxonomy_terms {
      id
      taxonomy_id
      title
      sort_number
      count_obj
    }
  }
}
`;

export const TAXONOMY_TERM_DELETE = gql`
mutation TaxonomyTermDelete($id: ID!){
  taxonomyTermDelete(id: $id){
    label
    message
  }
}
`;

