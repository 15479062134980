import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";



const useMutationTaxonomyDelete = (mutation) => {

  const history = useHistory();

  const [ _setMutationTaxonomyDelete,
    { loading } ] = useMutation(mutation,
        {
          update(_, { data }) {

            const {
              taxonomyDelete: {
                label,
                message
              }
            } = data;

            history.push(`/taxonomy/`);

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
        });

  return {
    _setMutationTaxonomyDelete,
    loadingTaxonomyDelete: loading,
  }
};

export default useMutationTaxonomyDelete;