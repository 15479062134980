// import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";



const useMutationProductMetaDelete = (mutation, dataQuery) => {

  // const history = useHistory();

  const [ _setMutationProductMetaDelete,
    { loading, error } ] = useMutation(mutation,
        {
          update(cache, { data }) {

            const {
              productMetaDelete: {
                label,
                message,
                product: {
                  meta_fields
                }
              }
            } = data;


            // history.push(`/products/`);

              cache.modify({
                id: cache.identify(dataQuery),
                fields: {
                  meta_fields() {
                    return meta_fields;
                  },
                },
              });


            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
        });

  return {
    _setMutationProductMetaDelete,
    loadingProductMetaDelete: loading,
    errorMutationProductMetaDelete: error,
  }
};

export default useMutationProductMetaDelete;