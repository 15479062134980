import React from "react";
import placeholder from '../assets/placeholder-download.png'

const Dashboard = () => {
  return (
    <div className="page-holder">
      <h1>Dashboard Example</h1>
      <img src={ placeholder } alt="placeholder dashboard" />
    </div>
  );
};

export default Dashboard;