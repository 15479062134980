import React from "react";
import Users from "../components/users"


const UsersPage = () => {

  return (
    <div className="page-holder bg-white">
      <Users />
    </div>
  );
};

export default UsersPage;
