import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';



const useQueryStandDownloads = (query) => {

  let history = useHistory();
  const { numPage, standId, standTab } = useParams();
  const routePage = `/showrooms/${ standId }/${ standTab }/`;


  const [ filter, setFilter ] = useState();

  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });



  const { loading, error, data } = useQuery(query, {
    variables: {
      // text: searchVal,
      stand_id: standId,
      status: filter,
      ...order,
      first: 10,
      page: +numPage ? +numPage : 1
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });



  const { attachmentsStand } = !!data && data;
  const { paginatorInfo } = !!attachmentsStand && attachmentsStand;
  const { total, perPage, currentPage } = !!paginatorInfo && paginatorInfo;


  const pagination = {
    current: currentPage,
    pageSize: !loading ? perPage : loading,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter, page) => {


    const value = Object.values(filters)[ 0 ] && Object.values(filters)[ 0 ][ 0 ];

    const {
      order,
      columnKey
    } = sorter;


    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });


    if (!!value)
    {
      setFilter(value)
    } else
    {
      setFilter()
    }

    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: attachmentsStand,
    // setSearchVal,
    // routePage,
    // history,
    pagination,
    handleTableChange
  }
};

export default useQueryStandDownloads;
