import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";

const useMutationTranslationSync = (mutation) => {

  const [ _setMutationTranslationSync,
    { loading: loadingMutationTranslationSync,
      error: errorMutationTranslationSync } ] = useMutation(mutation,
        {
          update(_, { data }) {
            const {
              translationsSync: {
                label,
                message,

              }
            } = data;

            // cache.modify({
            //   id: cache.identify(dataQuery),
            //   fields: {
            //     stand() {
            //       return stand;
            //     },
            //   },
            // });

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
        });


  return {
    _setMutationTranslationSync,
    loadingMutationTranslationSync,
    errorMutationTranslationSync,
  }
};

export default useMutationTranslationSync;