import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";

const useMutationKeyUpdate = (mutation) => {

  const [ _setMutationVariableUpdate,
    { loading: loadingMutationUpdate,
      error: errorMutationUpdate } ] = useMutation(mutation,
    {
      update(_, { data }) {
        const {
          translationKeyUpdate: {
            label,
            message
          }
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
    });


  return {
    _setMutationVariableUpdate,
    loadingMutationUpdate,
    errorMutationUpdate,
  }
};

export default useMutationKeyUpdate;

