import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Input, Select } from "antd";
import { errorNotification } from "../../../result";
import { LockOutlined } from '@ant-design/icons';
import RequestHandler from "../../../request-handler"
import { useApolloClient, gql } from '@apollo/client';
import SelectExbnEvnt from '../../select-exbn-evnt';
import SelectUserRoles from '../../select-user-roles';
import SelectUserLanguages from '../../select-user-languages';
import isEmpty from 'lodash/isEmpty';
import AvatarUpload from '../../../upload/avatar/avatar-upload';
import useMutationMetaUpload from './hooks/use-mutation-meta-upload';
import EntityRemoveButton from './../../../service/delete-entity';
import SelecStands from './../../select-stands';


import {

  USER_META_UPLOAD
} from '../../../../graphql/mutation/user-gql'


const NormalAccountForm = ({
  userId,
  getUser,
  errorUser,
  setMutationVariableCreate,
  setMutationVariableUpdate,
  setMutationVariableUserDelete,
  loadingUser,
  loadingMutationCreate,
  loadingMutationUpdate,
  loadingUserDelete,
}) => {


  const [ form ] = Form.useForm();
  const { Option } = Select;

  const {
    _setMutationVariableUpload,
    loadingMutationUpload,

  } = useMutationMetaUpload(USER_META_UPLOAD, getUser, form);


  const client = useApolloClient();
  const cache = client.cache;

  const getMeCashe = cache.readQuery({
    query: gql`
      query GetMe {
        me {
          id
        }
      }
    `,
  });

  const disabledSelectRoles = userId === getMeCashe.me.id ? true : false;

  const loadingMutation =
    !getUser.id ?
      loadingMutationCreate :
      loadingMutationUpdate;

  const imageUrl = ((getUser.avatar !== "") && getUser.avatar);
  const disabledSelect = getUser.role === 'admin' || getUser.role === 'editor' || getUser.role === 'api' ? true : false
  const eventStatus = (!isEmpty(getUser.exhibition_event)) && (getUser.exhibition_event[ 0 ].id);

  const formSubmit = ({ values, setMutationVariableCreate }) => {

    const UserCreateUpdateFields = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      password: values.password,
      locale: values.locale,
      status: values.status,
      role: values.role,
      ee_id: +values.exhibitionEvent
    }


    if (!userId)
    {
      setMutationVariableCreate({
        variables: {
          userCreateInput: {
            ...UserCreateUpdateFields
          }
        }
      }).catch((error) => {
        errorNotification(error);
      });

    } else
    {
      setMutationVariableUpdate({
        variables: {
          userId: getUser.id,
          userInput: {
            ...UserCreateUpdateFields
          }
        }
      }).catch((error) => {
        errorNotification(error);
      });
    }
  };

  const userForm = (
    <Form
      initialValues={ {
        name: getUser.name,
        email: getUser.email_original,
        // exhibitionEvent: eventStatus || 0,
        role: getUser.role,
        surname: getUser.surname,
        locale: getUser.locale,
        status: getUser.status,
      } }
      form={ form }
      onFinish={ (values) => {
        formSubmit({ values, setMutationVariableCreate });
      } }
      layout="vertical"
      className="account-form"
    >
      <Form.Item className="form-group">
        { !!getUser.id && (
          <Form.Item label="Avatar" name="avatar" valuePropName="fileList"
          // rules={ [ ...avatarRules ] }
          >

            <AvatarUpload
              size={ 140 }
              image={ imageUrl }
              loading={ loadingMutationUpload }
              uploadMutation={ _setMutationVariableUpload }
              variables={
                {
                  userId: getUser.id,
                  userKey: "avatar",
                }
              }
              extraClass={ "contain" }
              uploadButtonText={ "Upload Logo" }
              shape={ "square" }
            />
          </Form.Item>
        ) }

        <div>
          <SelectExbnEvnt disabledSelect={ disabledSelect } eventStatus={ eventStatus } />

          { !!getUser.id &&
            (
              getUser?.stand?.length ?
                <>
                  <br />
                  <div style={ { position: 'relative' } }>
                    <div style={ { position: 'absolute', top: '-10px', right: '0', zIndex: '1' } }>
                      <Link style={ { marginBottom: '10px' } } to={ `/showrooms/${ getUser.stand[ 0 ].id }` }><Button type="primary" ghost>View Showroom</Button></Link>
                    </div>
                    <SelecStands
                      disabled={ true }
                      standId={ getUser.stand[ 0 ].id }
                      standTitle={ getUser.stand[ 0 ].title } />
                  </div>
                </>
                :
                'The user is not attached to the showroom'
            )
          }
        </div>

      </Form.Item>

      <Form.Item className="form-group">
        <Form.Item
          name="name"
          label="Name"
          rules={ [
            {
              type: 'string',
              message: 'The input is not valid Name',
            },
            {
              required: true,
              message: 'Please input your Name'
            }
          ] }
        >
          <Input placeholder="Enter your Name" />
        </Form.Item>

        <Form.Item
          name="surname"
          label="Surname"
          rules={ [
            {
              required: true,
              message: 'Please input your Surname',
            },
          ] }
        >
          <Input placeholder="Enter your Surname" />
        </Form.Item>
      </Form.Item>

      <Form.Item className="form-group">
        <Form.Item
          name="email"
          label="Email"
          rules={ [
            {
              type: 'email',
              message: 'The input is not valid E-mail',
            },
            {
              required: true,
              message: 'Please input your Email'
            }
          ] }
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <SelectUserLanguages />
      </Form.Item>

      <Form.Item className="form-group">
        <SelectUserRoles disabledSelectRoles={ disabledSelectRoles } />
        <Form.Item
          name="status"
          label="Status"
          rules={ [ { required: true, message: 'Please select your status' } ] }
        >
          <Select placeholder="Select your status">
            <Option value="new">New</Option>
            <Option value="active">Active</Option>
            <Option value="close">Close</Option>
            <Option value="block">Block</Option>
          </Select>
        </Form.Item>
      </Form.Item>

      {
        !userId ?
          <Form.Item className="form-group">
            <Form.Item
              name="password"
              label="Password"
              rules={ [
                { min: 8, message: 'Password must be at least 8 characters' },
                { required: true, message: 'Please input new Password' },
              ] }
              hasFeedback
            >
              <Input.Password
                prefix={ <LockOutlined className="form-icon" /> }
                type="password"
                placeholder="Enter new password"
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={ [ 'password' ] }
              hasFeedback
              rules={ [
                {
                  required: true,
                  message: 'Please confirm password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value)
                    {
                      return Promise.resolve();
                    }

                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ] }
            >
              <Input.Password
                prefix={ <LockOutlined className="form-icon" /> }
                type="password"
                placeholder="Repeat new password"
              />
            </Form.Item>
          </Form.Item>
          : ""
      }
      {
        !userId ?
          <Button loading={ loadingMutation } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Create User</Button> :
          <div className="d-flex justify-content-between">
            <div>
              <Button loading={ loadingMutation } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Update User</Button>
            </div>
            <div>
              { (getUser.role === 'visitor' || getUser.role === 'exhibitor') &&
                <EntityRemoveButton
                  nameEntity={ 'User' }
                  dataNameEntity={ `${ getUser.name } ${ getUser.surname }` }
                  loading={ loadingUserDelete }
                  deleteMutation={ setMutationVariableUserDelete }
                  variables={
                    {
                      user_id: getUser.id,
                    }
                  }
                />
              }
            </div>
          </div>
      }
    </Form>
  );


  return (

    !userId ? (!loadingUser && userForm) :

      <RequestHandler
        loading={ loadingUser }
        error={ errorUser }
        data={ getUser }
      >
        { userForm }
      </RequestHandler>
  );
};


export default NormalAccountForm;
