import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tabs, Typography } from "antd";
import TaxonomyCreateForm from '../../forms/taxonomies-form/taxonomy-create-form';
import TermCreateForm from '../../forms/taxonomies-form/term-create-form';
import TermUpdateForm from '../../forms/taxonomies-form/terms-update-form';
import { GET_TAXONOMY } from '../../../graphql/query/taxonomies-gql';

import './taxonomy-tabs.scss';





const TaxonomyTabs = () => {

    const { txmyId = undefined, txmyTab } = useParams();
    let history = useHistory();

    const { data, loading } = useQuery(GET_TAXONOMY, {
        variables: {
            id: txmyId
        },
        // notifyOnNetworkStatusChange: true,
        // fetchPolicy: "cache-and-network",
    });


    const { taxonomy = false } = data !== undefined ? data : {};
    const { TabPane } = Tabs;

    const isEmptyTerms = taxonomy && taxonomy.taxonomy_terms.length !== 0;

    return (
        <div className="tabs-form event-tabs">
            {
                !loading && (!txmyId ?
                    <h3>Add Taxonomy</h3> :
                    <h3>Edit Taxonomy: <Typography.Link> { taxonomy.title }</Typography.Link></h3>)
            }

            <Tabs
                tabPosition="top"
                activeKey={ txmyTab ? txmyTab : 'taxonomy' }
                onTabClick={ (key) => {
                    changeTab(history, key, txmyId);
                } }
            >
                <TabPane tab="Taxonomy" key="taxonomy">
                    <TaxonomyCreateForm
                        dataQuery={ taxonomy }
                        loadingQuery={ loading } />
                </TabPane>
                { !!txmyId && (
                    <>
                        <TabPane tab="Terms" key="terms">
                            { isEmptyTerms && (
                                <TermUpdateForm
                                    dataQuery={ taxonomy }
                                    loadingQuery={ loading } />
                            ) }

                            <br />
                            <TermCreateForm
                                dataQuery={ taxonomy }
                                loadingQuery={ loading } />
                        </TabPane>
                    </>
                ) }
            </Tabs>
        </div>
    );
};


export default TaxonomyTabs;


//Pure functions

const changeTab = (history, tabKey, txmyId) => {

    if (!!txmyId)
    {
        if (tabKey === 'taxonomy')
        {
            history.push(`/taxonomy/${ txmyId }`);
        } else
        {
            history.push(`/taxonomy/${ txmyId }/${ tabKey }`);
        }
    } else
    {
        history.push(`/taxonomy/add-taxonomy`);
    }
};