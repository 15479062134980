import React, { useEffect, useState } from "react";
import { Form, Button, Input, Radio, Spin } from "antd";
import useMutationStandMetaUpload from '../hooks/use-mutation-stand-meta-upload';
import { STAND_META_UPLOAD } from '../../../../graphql/mutation/showroom-gql';
import AvatarUpload from '../../../upload/avatar/avatar-upload';
import metaFields from './../../../../utils/meta-fields';
import errorNotification from '../../../result/error-notification';
import youtubeUrl from '../../../../utils/youtube-url';




const NormalCompanyPageForm = ({
  loadingQueryStand,
  dataQueryStand,
  loadingMutationStandMetaSet,
  setMutationVariableStandMetaSet,

}) => {

  const [ form ] = Form.useForm();
  const { TextArea } = Input;

  const {
    _setMutationStandUpload,
    loadingMutationStandUpload,

  } = useMutationStandMetaUpload(STAND_META_UPLOAD, dataQueryStand, form);

  const { getValue, normalize } = metaFields;
  const meta = normalize(dataQueryStand.meta_fields);

  const defaultRadioVal = getValue(meta, "page_media_type_1", 'image');

  const [ radioValue, setRadioValue ] = useState(defaultRadioVal);
  useEffect(() => {
    setRadioValue(defaultRadioVal)
  }, [ defaultRadioVal ]);


  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };

  const formSubmit = ({ values }) => {

    let { metaValues } = metaFields.parseForm(values);
    metaValues = metaFields.filterEmpty(metaValues, dataQueryStand.meta_fields);

    setMutationVariableStandMetaSet({
      variables: {
        stand_id: dataQueryStand.id,
        input: [ ...metaValues ]

      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };

  return (

    loadingQueryStand ? (
      <div className="block-loader h-300px" >
        <Spin size="small" />
      </div>
    ) : (
        <Form
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
        >

          <Form.Item
            className="showroom-image"
            label="Page header image"
            name="company_image_1-image-unset-page_headers"
            initialValue={ !!getValue(meta, "company_image_1") ? getValue(meta, "company_image_1") : null }
          >
            <AvatarUpload
              image={ getValue(meta, "company_image_1") }
              loading={ loadingMutationStandUpload }
              uploadMutation={ _setMutationStandUpload }
              variables={
                {
                  input: {
                    stand_id: dataQueryStand.id,
                    meta_key: "company_image_1",
                    meta_group: "page_headers"
                  }
                }
              }
              // extraClass={ "contain" }
              uploadButtonText={ "Upload Company logo" }
              shape={ "square" }
            />
          </Form.Item>

          <div className="form-container lg">

            <Form.Item
              name="about_title-string--about_company"
              label="Title"
              initialValue={ getValue(meta, "about_title") }
            >
              <Input placeholder="Enter your Title" />
            </Form.Item>

            <Form.Item
              initialValue={ defaultRadioVal }
              name="page_media_type_1-radio--about_company"
              className="form-group"
              style={ { margin: "10px 0" } }>
              <Radio.Group onChange={ radioOnChange }>
                <Radio value="image">Image</Radio>
                <Radio value="video">Video</Radio>
              </Radio.Group>
            </Form.Item>

            { radioValue === 'image' &&

              <Form.Item label="Company image"
                className="showroom-image"
                name="page_image_1-image-unset-about_company"
                initialValue={ !!getValue(meta, "page_image_1") ? getValue(meta, "page_image_1") : null }
              >
                <AvatarUpload
                  size={ !!getValue(meta, "page_image_1") ? 300 : 140 }
                  image={ getValue(meta, "page_image_1") }
                  loading={ loadingMutationStandUpload }
                  uploadMutation={ _setMutationStandUpload }
                  variables={
                    {
                      input: {
                        stand_id: dataQueryStand.id,
                        meta_key: "page_image_1",
                        meta_group: "about_company"
                      }
                    }
                  }
                  // extraClass={ "contain" }
                  uploadButtonText={ "Upload Company image" }
                  shape={ "square" }
                />
              </Form.Item>
            }
            { radioValue === 'video' &&
              <Form.Item
                name="page_video_link_1-link--about_company"
                label="Company video"
                initialValue={ getValue(meta, "page_video_link_1") }
                rules={
                  [
                    () => ({
                      validator(_, value) {
                        if (youtubeUrl.parse(value))
                        {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Please insert valid youtube link'))
                      }
                    })
                  ]
                }
              >
                <Input placeholder="Add youtube link" />
              </Form.Item>
            }

            <Form.Item
              name="about_description-string--about_company"
              label="Text about the company"
              initialValue={ getValue(meta, "about_description") }
            >
              <TextArea
                maxLength={ 2000 }
                showCount={
                  { formatter: ({ count, maxLength }) => maxLength - count }
                }
                placeholder="Enter short company description"
                autoSize={ { minRows: 16, maxRows: 30 } }
              />
            </Form.Item>
          </div>


          {/* <Form.Item className="form-group"></Form.Item> */ }
          <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
            <Button loading={ loadingMutationStandMetaSet } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
          </Form.Item>
        </Form>
      )
  )
}


export default NormalCompanyPageForm;
