import React from "react";
import Products from '../components/products/all-products';



const ProductsPage = () => {

  return (
    <div className="page-holder bg-white">
      <Products />
    </div>
  );
};

export default ProductsPage;
