import React from "react";
// import { Link } from "react-router-dom";
import { Table, Radio } from "antd";
import LanguageForm from '../../../forms/localization-forms/language-form';
import EditModal from './../../../service/edit-modal';
import mobileSize from "../../../../utils/mobile-size";
import { TRANSLATION_SYNC } from '../../../../graphql/mutation/languages-gql';
import useMutationTranslationSync from './../hooks/use-mutation-translation-sync';
import errorNotification from './../../../result/error-notification';

import "./languages-table.scss"

const { Column } = Table;



const LanguagesTable = ({ dataQuery, loadingQuery, handleTableChange }) => {


  const {
    _setMutationTranslationSync,

  } = useMutationTranslationSync(TRANSLATION_SYNC);


  const handleRadioChange = ({ locale, type }) => {

    _setMutationTranslationSync({
      variables: {
        locale,
        type
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };


  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }

        className="main-table languages-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ loadingQuery }
        dataSource={ dataQuery }
        rowKey={ language => language.id }
        pagination={ false }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" sorter="true" dataIndex="id" key="id" />
        <Column title="Label" dataIndex="label" key="label" />
        <Column title="Slug" dataIndex="slug" key="slug" />

        <Column title="Status" key="status"
          render={
            language => {
              // const langStatus;
              return (
                !!language.status ?
                  'active' : 'inactive'
              )
            }
          }
          filterMultiple={ false }
          filters={ [
            { text: "All", value: null },
            { text: "Active", value: true },
            { text: "Inactive", value: false },
          ] }
        />

        <Column title="Translations Sync" render={
          language =>
            <Radio.Group
              onChange={
                ({ target: { value } }) => {
                  const locale = language.slug;
                  const type = value;
                  handleRadioChange({ locale, type })
                }
              }>
              <Radio.Button value={ 'admin' }>Admin</Radio.Button>
              <Radio.Button value={ 'site' }>Site</Radio.Button>
              <Radio.Button value={ 'core' }>Core</Radio.Button>
            </Radio.Group> }
        />

        <Column title="Action" render={
          (language) =>
            <EditModal valueBtn={ 'Edit' } titleModal={ 'Update Language' }>
              <LanguageForm dataQuery={ language } loadingQuery={ loadingQuery } />
            </EditModal>
        }
        />
      </Table>
    </div>
  );
};

export default LanguagesTable;