import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";



const useMutationProductDelete = (mutation) => {

  const history = useHistory();

  const [ _setMutationProductDelete,
    { loading, error } ] = useMutation(mutation,
        {
          update(cache, { data }) {

            const {
              productDelete: {
                label,
                message
              }
            } = data;

            history.push(`/products/`);

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
        });

  return {
    _setMutationProductDelete,
    loadingProductDelete: loading,
    errorMutationProductDelete: error,
  }
};

export default useMutationProductDelete;