import React from "react";
import NormalPackageAddStandForm from './normal-package-add-stand-form';
import useMutationPackagAddStand from './hooks/use-mutation-package-add-stand';


import {
  PACKAGE_STAND
} from '../../../../graphql/mutation/packages-gql'

import './package-add-stand-form.scss';



const PackageAddStandFormForm = ({ dataQuery, setModalVisible }) => {

  const {
    _setMutationPackageAddStand,
    loadingMutationAddStand

  } = useMutationPackagAddStand(PACKAGE_STAND, dataQuery);

  return (
    <NormalPackageAddStandForm
      dataQuery={ dataQuery }
      loadingMutationAddStand={ loadingMutationAddStand }
      setMutationPackageAddStand={ _setMutationPackageAddStand }
      setModalVisible={ setModalVisible }
    />
  );
};


export default PackageAddStandFormForm;
