import { ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import cache from './cache';


const httpLink = createUploadLink({
  // uri: "https://api.vep.mp-dev.eu/admin-graphql"
  uri: "https://api.fairgalaxy.de/admin-graphql"
});

const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem("authToken");

  if (!authToken)
  {
    return { headers: { ...headers } };
  }

  return {
    headers: {
      ...headers,
      Accept: "application/json",
      Authorization: `Bearer ${authToken}`
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});


export default client;