import React from "react";
import Taxonomies from '../components/taxonomies/all-taxonomies';



const PackagesPage = () => {

  return (
    <div className="page-holder bg-white">
      <Taxonomies />
    </div>
  );
};

export default PackagesPage;
