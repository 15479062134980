import React from "react";
import { Link } from "react-router-dom";
import { Layout, Button } from 'antd';
import TaxonomiesTable from "./taxonomies-table";
import SearchForm from "../../forms/search-form"
// import TranslationKeysForm from '../../forms/localization-forms/translation-keys-form';
// import EditModal from '../../service/edit-modal';
import useQueryTaxonomies from './hooks/use-query-taxonomies';
import { GET_TAXONOMIES } from "../../../graphql/query/taxonomies-gql";

import './taxonomies.scss';


const { Sider, Content } = Layout;



const Taxonomies = () => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
    history,
    setSearchVal,
    routePage,
    pagination,
    handleTableChange
  } = useQueryTaxonomies(GET_TAXONOMIES);



  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Taxonomies</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <Link to={ `/taxonomy/add-taxonomy` }><Button className='' type="primary">Add taxonomy</Button></Link>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>
      <TaxonomiesTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        handleTableChange={ handleTableChange }
      />
    </>
  );
};

export default Taxonomies;
