import UploadFile from '../../upload/upload-files/upload-file';
import useMutationStandUploadFile from './hooks/use-mutation-stand-upload-file';
import { ATTACHED_UPLOAD } from './../../../graphql/mutation/attachments-gql';


const StandUploadFile = ({ dataQuery }) => {


  const {
    _setMutationStandUploadFile,
    loadingMutationStandUploadFile
  } = useMutationStandUploadFile(ATTACHED_UPLOAD, dataQuery);


  return (

    <UploadFile
      className="stand-upload-file"
      // size={ 140 }
      loading={ loadingMutationStandUploadFile }
      uploadMutation={ _setMutationStandUploadFile }
      uploadButtonText={ "Upload file" }
      btnType="primary"
      accept={ '*' }
      variables={
        {
          stand_id: dataQuery.id,
          status: "draft",
        }
      }
    />

  );
};

export default StandUploadFile;
