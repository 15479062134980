import React from "react";
import RequestHandler from "../../request-handler"
import { Form, Select } from "antd";

// Hooks
import useQueryUserLanguages from './hooks/use-query-user-languages';

//Query
import { GET_LANGUAGES } from './../../../graphql/query/languages-gql';

//CSS
import './select-user-languages.scss';



const SelectUserLanguages = () => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
  } = useQueryUserLanguages(GET_LANGUAGES);

  const { Option } = Select;


  const selectUserLanguage = dataQuery && (
    dataQuery.map((item) => {
      return (
        <Option key={ item.id } value={ item.slug }>
          <span>{ item.label }</span>
        </Option>
      )
    })
  );


  return (
    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQuery }
    >
      <Form.Item
        name="locale"
        label="Language"
        rules={ [ { required: true, message: 'Please select language' } ] }
      >
        <Select placeholder="Select your Language">
          { selectUserLanguage }
        </Select>
      </Form.Item>
    </RequestHandler>
  )
};

export default SelectUserLanguages;