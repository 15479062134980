const arrSortDescend = (arr) => {

  const arrSorted = Object.assign([], [ ...arr ]);

  arrSorted.sort(function (a, b) {

    if (parseInt(a.id) < parseInt(b.id))
    {
      return 1;
    }
    if (parseInt(a.id) > parseInt(b.id))
    {
      return -1;
    }

    return 0;
  });

  return arrSorted;
}


export default arrSortDescend;

// const arrDescend = dataQuery && arrSortDescend(dataQuery);

