import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";

const useMutationEventCreate = (mutation) => {

  let history = useHistory();

  const [ _setMutationVariableCreate,
    { loading: loadingMutationCreate,
      error: errorMutationCreate } ] = useMutation(mutation,
    {
      update(_, { data }) {
        const {
          eeCreate: {
            label,
            message,
            exhibition_event
          }
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message
        });

        history.push(exhibition_event.id);
      },
    });


  return {
    _setMutationVariableCreate,
    loadingMutationCreate,
    errorMutationCreate,
  }
};

export default useMutationEventCreate;