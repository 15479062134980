import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";


const useMutationStandMetaSet = (mutation, dataQuery) => {

  const [ _setMutationVariableStandMetaSet,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            standMetaSet: {
              label,
              message,
              meta_fields
            }
          } = data;


          cache.modify({
            id: cache.identify(dataQuery),
            fields: {

              meta_fields() {
                return meta_fields;
              },
            },
          });


          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationVariableStandMetaSet,
    loadingMutationStandMetaSet: loading,
  }
};

export default useMutationStandMetaSet;