import React, { useState } from "react";
import { Button, Modal } from "antd";
import { errorNotification } from "../result";



const confirmRemoveEntity = (props) => {
  const {
    variables,
    deleteMutation,
  } = props;

  let deleteVar;

  if (variables !== undefined)
  {
    deleteVar = { ...variables };
  } else
  {
    return false;
  }

  deleteMutation({
    variables: {
      ...deleteVar
    }

  }).catch((error) => {
    errorNotification(error);
  });
};

const EntityRemoveButton = (props) => {

  const [ showModal, setShowModal ] = useState(false);
  const {
    loading,
    variables = {},
    deleteMutation = () => { },
    nameEntity,
    dataNameEntity,
  } = props;


  return (
    <>
      <Button onClick={ () => setShowModal(true) } type="danger" style={ { marginTop: "10px" } }>{ `Remove ${ nameEntity }` }</Button>
      <Modal
        title={ `Remove ${ nameEntity } ${ dataNameEntity }` }
        className=""
        visible={ showModal }
        onOk={
          () => confirmRemoveEntity(
            {
              variables,
              deleteMutation,
            }
          ) }
        onCancel={ () => setShowModal(false) }
        okText={ 'Yes' }
        okButtonProps={
          {
            loading,
            className: "rounded-button"
          }
        }
        cancelText={ 'No' }
        cancelButtonProps={
          { className: "rounded-button" }
        }
      >
        <p>{ `Are you sure you want to delete ${ nameEntity } ${ dataNameEntity } ?` }</p>
      </Modal>
    </>
  );
};

export default EntityRemoveButton;