// import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";



const useMutationPackageFileDelete = (mutation, dataQuery) => {

  const [ _setMutationPackageFileDelete,
    { loading, error } ] = useMutation(mutation,
      {
        update(cache, { data }) {

          const {
            packageUpdloadPDFDelete: {
              label,
              message,
            }
          } = data;

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });

  return {
    _setMutationPackageFileDelete,
    loadingPackageFileDelete: loading,
    errorMutationPackageFileDelete: error,
  }
};

export default useMutationPackageFileDelete;