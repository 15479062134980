import React from "react";
import { Form, Input, Spin, Typography } from "antd";
import metaFields from '../../../../../utils/meta-fields';
import link from '../../../../../utils/link';

import './social-links-form.scss';



const SocialLinksForm = ({
  dataQueryStand,
  loadingQueryStand
}) => {


  const { getValue, normalize } = metaFields;
  const meta = normalize(dataQueryStand.meta_fields);

  return (
    <div>
      { loadingQueryStand ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <>

          <Typography.Link className="text-decorator">
            <h6>Social links</h6>
            <div className="separator"></div>
          </Typography.Link>

          <Form.Item className="form-group">

            <Form.Item
              name="link_facebook-link--social_links"
              label="Facebook"
              initialValue={ getValue(meta, "link_facebook") }
              rules={ [
                {
                  pattern: link.regExpPattern("facebook.com"),
                  message: 'Please insert valid http://facebook.com link'
                }
              ] }
            >
              <Input placeholder="Enter your social links" />
            </Form.Item>

            <Form.Item
              name="link_instagram-link--social_links"
              label="Instagram"
              initialValue={ getValue(meta, "link_instagram") }
              rules={ [
                {
                  pattern: link.regExpPattern("instagram.com"),
                  message: 'Please insert valid http://instagram.com link'
                }
              ] }
            >
              <Input placeholder="Enter your Instagram" />
            </Form.Item>

            <Form.Item
              name="link_linkedin-link--social_links"
              label="Linkedin"
              initialValue={ getValue(meta, "link_linkedin") }
              rules={ [
                {
                  pattern: link.regExpPattern("linkedin.com"),
                  message: 'Please insert valid http://linkedin.com link'
                }
              ] }
            >
              <Input placeholder="Enter your Linkedin" />
            </Form.Item>

            <Form.Item
              name="link_youtube-link--social_links"
              label="Youtube"
              initialValue={ getValue(meta, "link_youtube") }
              rules={ [
                {
                  pattern: link.regExpPattern("youtube.com|youtu.be"),
                  message: 'Please insert valid http://youtube.com link'
                }
              ] }
            >
              <Input placeholder="Enter your Linkedin" />
            </Form.Item>
          </Form.Item>
        </>
      )
      }
    </div>
  );
}

export default SocialLinksForm;
