import React from 'react';
import TranslationKeys from '../components/localization/translation-keys';


const LocalizationKeysPage = () => {

    return (
        <div className="page-holder bg-white">
            <TranslationKeys />
        </div>
    );
};

export default LocalizationKeysPage;