import React from "react";
import StandDownloadsTable from "./stand-downloads-table";
import { Layout } from 'antd';
import useQueryStandDownloads from './hooks/use-query-stand-downloads';


import { GET_ATTACHMENTS_STAND } from "../../../graphql/query/attachments-gql";


import './stand-downloads.scss';




const { Sider, Content } = Layout;

const StandDownloads = () => {

  const {
    loadingQuery,
    dataQuery,
    pagination,
    handleTableChange
  } = useQueryStandDownloads(GET_ATTACHMENTS_STAND);


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Download page</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
        </Sider>
      </Layout>
      <StandDownloadsTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loading={ loadingQuery }
        handleTableChange={ handleTableChange }
      />
    </>
  );
};

export default StandDownloads;
