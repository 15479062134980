import ExhibitionIcon from "./exhibition-icon";
import NotificationIcon from "./notification-icon";
import CrossIcon from "./cross-icon";
import NoImageIcon from "./no-image-icon";
import ProductIcon from "./product-icon";
import EditIcon from "./edit-icon";
import CompanyIcon from "./company-icon";
import CommunicationIcon from "./communication-icon";
import TeamIcon from "./team-icon";
import PackageIcon from "./package-icon";
import AnalyticsIcon from "./analytics-icon";
import EyeIcon from "./eye-icon";
import SidebarIcon from "./sidebar-icon";
import PlusIcon from "./plus-icon";
import WebIcon from "./web-icon";
import PhoneIcon from "./phone-icon";
import EmailIcon from "./email-icon";
import LocationIcon from "./location-icon";
import SocialIcon from "./social-icon";
import NoVideoIcon from "./no-video-icon";
import CreateProductIcon from "./create-product-icon";
import ArrowIcon from "./arrow-icon";
import AvatarIcon from "./avatar-icon";
import TrashIcon from "./trash-icon";
import PlayerIcon from "./player-icon";
import FileIcon from "./file-icon";
import FilesIcon from "./files-icon";
import CheckIcon from "./check-icon";


const Icons = {
    Exhibition : ExhibitionIcon,
    Notification : NotificationIcon,
    Cross : CrossIcon,
    NoImage : NoImageIcon,
    Product : ProductIcon,
    Edit : EditIcon,
    Company : CompanyIcon,
    Communication : CommunicationIcon,
    Team : TeamIcon,
    Package : PackageIcon,
    Analytics : AnalyticsIcon,
    Eye : EyeIcon,
    Sidebar : SidebarIcon,
    Plus : PlusIcon,
    Web : WebIcon,
    Phone : PhoneIcon,
    Email : EmailIcon,
    Location : LocationIcon,
    Social : SocialIcon,
    NoVideo : NoVideoIcon,
    CreateProduct : CreateProductIcon,
    Arrow : ArrowIcon,
    Avatar: AvatarIcon,
    Trash : TrashIcon,
    Player : PlayerIcon,
    File: FileIcon,
    Files : FilesIcon,
    Check: CheckIcon
};

export default Icons;