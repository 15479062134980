import React from 'react';
import { ShowroomTabs } from '../components/tabs';

const ShowroomPage = () => {

    return (
        <div className="page-holder bg-white">
            <div className="form-container lg">
                <ShowroomTabs />
            </div>
        </div>
    );
};

export default ShowroomPage;
