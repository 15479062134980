import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Avatar, Typography } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import mobileSize from "../../../../utils/mobile-size";
import EntityRemoveButton from '../../../service/delete-entity';
import "./showrooms-table.scss"


const { Column } = Table;
const { Text } = Typography;

const ShowroomsTable = ({
  pagination,
  dataQuery,
  loading,
  handleTableChange,
  routePage,
  eventId,
  setMutationStandDelete,
  loadingStandDelete
}) => {

  return (
    <div className={ !eventId && 'holder-table' }>
      <Table size={ mobileSize('small') }
        className="main-table showrooms-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        // scroll={{ x: 800, y: 300 }}
        loading={ loading }
        dataSource={ dataQuery && dataQuery.data }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" dataIndex="id" key="id" sorter="true" />

        <Column
          className="table-avatar-link"
          title="Logo"
          render={ item => {
            const imageUrl = item.logo;
            return (
              <Link
                to={ {
                  pathname: `/showrooms/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                { !imageUrl ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ imageUrl } /> }
              </Link>
            )
          } } />
        <Column title="Company name" key="title" sorter="true" render={ item => !item.title ?
          <Link
            to={ {
              pathname: `${ routePage }${ item.id }`,
              state: {
                breadcrumbSlug: 'Company name is not filled'
              }
            } }
          >
            <Text type="secondary">Company name is not filled</Text></Link> :
          <Link
            to={ {
              pathname: `/showrooms/${ item.id }`,
              state: {
                breadcrumbSlug: item.title
              }
            } }
          >
            { `${ item.title }` }</Link> }
        />
        { !eventId && (
          <>
            <Column
              title="Description"
              key="description"
              className="column-description"
              render={ item => <span>{ item.description }</span> }

            />
            <Column title="Keywords" key="keywords" className="column-description" render={ item => <span>{ item.keywords }</span> } />
          </>
        ) }

        <Column title="Packege" className="column-description-core" render={ item => !item.package?.title ? 'This showroom does not have a package' : item.package.title } />
        <Column title="Status" dataIndex="status" key="status"
          filterMultiple={ false }
          filters={ [
            { text: "Draft", value: "draft" },
            { text: "Offline", value: "offline" },
            { text: "Online", value: "online" },
          ] }
        />

        { !eventId &&
          <Column title="Exhibition event" render={ item =>
            <Link
              to={ {
                pathname: `/exhibition-events/${ item.exhibition_event.id }`,
                state: {
                  breadcrumbSlug: item.exhibition_event.title
                }
              } }
            >
              { `${ item.exhibition_event.title }` }</Link>
          } />
        }
        <Column title="Created" className="column-date" dataIndex="created_at" key="created_at" />
        <Column title="Action" className="text-align-center" render={
          item =>
            <div className="wrap-link-btn">
              <Link
                to={ {
                  pathname: `/showrooms/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                <Button type="primary">Edit</Button>
              </Link>
              <Link to={ `//${ item.exhibition_event.uri }/showroom-${ item.id }/` } target="_blank"><Button type="default">View on site</Button></Link>

              { !!eventId &&
                <EntityRemoveButton
                  nameEntity={ 'Showroom' }
                  dataNameEntity={ `${ item.title }` }
                  loading={ loadingStandDelete }
                  deleteMutation={ setMutationStandDelete }
                  variables={
                    {
                      stand_id: item.id,
                    }
                  }
                />
              }
            </div>
        } />
      </Table>
    </div>
  );
};

export default ShowroomsTable;
