import React from 'react';
import Languages from '../components/localization/languages';


const LocalizationLangsPage = () => {

    return (
        <div className="page-holder bg-white">
            <Languages />
        </div>
    );
};

export default LocalizationLangsPage;