import Icons from '../components/icons';
import Icon from '@ant-design/icons';


const AttachIcon = ({ extension }) => {


    switch (extension)
    {
        case 'pdf':
            return <Icons.Files type="pdf" />;

        case 'ppt':
        case 'pptx':
            return <Icons.Files type="ppt" />;

        case 'xls':
        case 'xlsx':
            return <Icons.Files type="excel" />;

        case 'zip':
        case '7zip':
        case '7zp':
        case 'rar':
            return <Icons.Files type="file-zip" />;

        case 'doc':
        case 'docx':
            return <Icons.Files type="word" />;


        case 'txt':
        case 'list':
            return <Icon type="file-text" />;

        case 'JPEG':
        case 'jpeg':
        case 'jpg':
        case 'gif':
        case 'tif':
            return <Icons.Files type="image" />;

        case 'png':
            return <Icons.Files type="png-image" />;


        case 'mov':
        case 'h.264':
        case 'MJPEG':
        case 'wmv':
        case 'flv':
        case 'avi':
        case '3gp':
        case 'mkv':
            return <Icons.Files type="video" />;


        case 'mp4':
        case 'mpeg4':
        case 'MPEG-4':
            return <Icons.Files type="mp4-video" />;

        case 'mp3':
        case 'wma':
        case 'ogg':
        case 'aac':
        case 'flac':
        case 'ape':
        case 'wav':
        case 'aiff':

            return <Icons.Files type="audio" />;


        default: return <Icons.Files />;
    }
};


export default AttachIcon;

