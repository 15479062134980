// import { useState } from "react";
import { useQuery } from "@apollo/client";



const useQueryTerms = (query, taxonomiesId) => {

  const { loading: loadingQuery, error: errorQuery, data } = useQuery(query, {
    variables: {
      where: {
        column: 'TAXONOMY_ID',
        value: taxonomiesId
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });


  return {
    loadingQuery,
    errorQuery,
    dataQuery: data,
  }
};

export default useQueryTerms;


