import React from "react";
import Showrooms from "../components/showrooms/stands"


const ShowRoomsPage = () => {


  return (
    <div className="page-holder bg-white">
      <Showrooms />
    </div>
  );
};

export default ShowRoomsPage;
