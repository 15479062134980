import React from "react";
import RequestHandler from "../../request-handler"
import { Form, Select } from "antd";


//CSS
import './select-package-rule-constants.scss';



const SelectPackageRuleConstants = ({
  packageRulesId,
  selectOnChange,
  disabledSelect = false,
  loadingQueryRuleConstants,
  errorQueryRuleConstants,
  dataQueryRuleConstants,
  dataQuery
}) => {


  const { Option } = Select;

  const selectSearchField = dataQueryRuleConstants && (
    dataQueryRuleConstants.map((item) => {
      return (
        <Option key={ item.id } value={ item.id }>
          { item.rule_key }
        </Option>
      )
    })
  );


  return (
    <RequestHandler
      loading={ loadingQueryRuleConstants }
      error={ errorQueryRuleConstants }
      data={ dataQueryRuleConstants }
    >

      <Form.Item
        name="rule_key"
        label="Rule"
        initialValue={ {
          label: !!packageRulesId ? dataQuery.rule_key : null,
          value: !!packageRulesId ? dataQuery.rule_key : null
      } }
        rules={ [ { required: true, message: 'Please select package rule constant' } ] }
      >
        <Select
          onChange={ selectOnChange }
          className="select-input-space-between"
          dropdownClassName="select-dropdown-space-between"
          disabled={ disabledSelect }
          showSearch
          labelInValue
          placeholder="Please select package rule constant"
          optionFilterProp="children"
        >
          { selectSearchField }
        </Select>
      </Form.Item>
    </RequestHandler>
  )
};

export default SelectPackageRuleConstants;