import React from "react";
import Product from '../components/products/product';



const ProductsPage = () => {

  return (
    <div className="page-holder bg-white">
      <Product />
    </div>
  );
};

export default ProductsPage;
