import React from "react";
import { Form, Spin, Select, Typography } from "antd";
import metaFields from '../../../../utils/meta-fields';
import { GET_TAXONOMIES } from '../../../../graphql/query/taxonomies-gql';
import useQueryTaxonomies from './hooks/use-query-taxonomies';
import './exbn-taxonomy-form.scss';


const { Option } = Select;



const ExbnTaxonomyForm = ({ metaExbnEvent }) => {

  let {
    dataQuery,
    loadingQuery,

  } = useQueryTaxonomies(GET_TAXONOMIES);


  const { getValue, normalize } = metaFields;
  const meta = normalize(metaExbnEvent.meta_fields);

  const arrTypeAll = dataQuery && (
    dataQuery
      .map(i =>
        <Option key={ i.id } label={ i.title } >{ i.title }</Option>
      )
  );

  const arrTypeStand = dataQuery && (
    dataQuery
      .filter((i) => i.type === 'stand')
      .map(i => <Option key={ i.id } label={ i.title }  >{ i.title }</Option>)
  );


  const arrTypeProduct = dataQuery && (
    dataQuery
      .filter((i) => i.type === 'product')
      .map(i => <Option key={ i.id } label={ i.title } >{ i.title }</Option>)
  );


  return (
    <div>
      { loadingQuery ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <>
          <Typography.Link className="text-decorator">
            <h6>Exhibition taxonomy</h6>
            <div className="separator"></div>
          </Typography.Link>
          <Form.Item
            name="showroom_taxonomies-string--ee_taxonomy"
            label="Showroom taxonomies"
            initialValue={ getValue(meta, "showroom_taxonomies") === '' ? [] : getValue(meta, "showroom_taxonomies") }
          >
            <Select
              mode="multiple"
              optionFilterProp={ "label" }
              allowClear
              placeholder="Please select Showroom taxonomies"
            >
              { arrTypeStand }
            </Select>
          </Form.Item>

          <Form.Item
            name="product_taxonomies-string--ee_taxonomy"
            label="Product taxonomies"
            initialValue={ getValue(meta, "product_taxonomies") === '' ? [] : getValue(meta, "product_taxonomies") }
          >
            <Select
              mode="multiple"
              optionFilterProp={ "label" }
              allowClear
              placeholder="Please select Product taxonomies"
            >
              { arrTypeProduct }
            </Select>
          </Form.Item>

          <Form.Item
            name="user_interest_taxonomies-string--ee_taxonomy"
            label="User interest taxonomies"
            initialValue={ getValue(meta, "user_interest_taxonomies") }
          >
            <Select
              mode="multiple"
              optionFilterProp={ "label" }
              allowClear
              placeholder="Please select User interest taxonomies"
            >
              { arrTypeAll }
            </Select>
          </Form.Item>

          <Form.Item
            name="map_taxonomy-string--ee_taxonomy"
            label="Map taxonomy"
            initialValue={ getValue(meta, "map_taxonomy") === '' ? [] : getValue(meta, "map_taxonomy") }
            rules={ [
              { max: 1, type: "array", message: "You can choose only one taxonomies" },
            ] }
          >
            <Select
              mode="multiple"
              optionFilterProp={ "label" }
              allowClear
              placeholder="Please select Map taxonomies"
            >
              { arrTypeStand }
            </Select>
          </Form.Item>
        </>
      )
      }
    </div>
  );
}

export default ExbnTaxonomyForm;
