// Query Example:

import { useQuery } from "@apollo/client";


const useQueryUserRoles = ( query ) => {

  const { loading, error, data  } = useQuery(query);

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: !!data && data.roles,
  }
};

export default useQueryUserRoles;