import React from "react";
import { Table } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import mobileSize from "../../../../utils/mobile-size";
import PackageRuleCreateForm from '../../../forms/packages-form/package-rule-create-form';
import EditModal from '../../../service/edit-modal';
import EntityRemoveButton from '../../../service/delete-entity';

import "./package-rules-table.scss"




const { Column } = Table;


const DragHandle = sortableHandle(() => <MenuOutlined style={ { cursor: 'grab', color: '#999' } } />);

const SortableItem = sortableElement(props => <tr { ...props } />);
const SortableContainer = sortableContainer(props => <tbody { ...props } />);





const PackageRulesTable = ({
  onSortEnd,
  dataSource,
  loadingQuery,
  pagination,
  handleTableChange,
  setMutationPackageRuleDelete,
  loadingPackageRuleDelete }) => {


  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={ onSortEnd }
      { ...props }
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource && dataSource.findIndex(x => x.id === restProps[ 'data-row-key' ]);
    return <SortableItem index={ +index } { ...restProps } />;
  };


  return (

    <div className="_holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        loading={ loadingQuery }
        dataSource={ dataSource }
        rowKey={ item => item.id }
        components={ {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        } }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="Sort" className="drag-visible" render={ () => <DragHandle /> } />
        <Column title="ID" className="drag-visible" dataIndex="id" key="id" />
        <Column title="Title" className="drag-visible" dataIndex="title" key="title" />
        <Column title="Rule" className="drag-visible" dataIndex="rule_key" key="rule_key" />
        <Column title="Value" className="drag-visible text-align-center" render={
            item => (!!item.min_value || !!item.max_value) ? `min: ${ item.min_value } - max: ${ item.max_value }` : "-" } />
        <Column title="Status" className="drag-visible" render={ item => item.active === true ? 'Allowed' : 'Forbidden' } />

        <Column title="Action" className="drag-visible text-align-center" render={
          item =>
            <div className="wrap-link-btn">

              <EditModal valueBtn={ 'Edit' } titleModal={ 'Update Rule' }>
                <PackageRuleCreateForm dataQuery={ item } loadingQuery={ loadingQuery } />
              </EditModal>

              <EntityRemoveButton
                nameEntity={ 'Rule' }
                dataNameEntity={ `${ item.title }` }
                loading={ loadingPackageRuleDelete }
                deleteMutation={ setMutationPackageRuleDelete }
                variables={
                  {
                    id: item.id,
                  }
                }
              />
            </div>
        } />
      </Table>
    </div>
  )
};

export default PackageRulesTable;
