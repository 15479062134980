import { useQuery } from "@apollo/client";


const useQueryProduct = ( query, id ) => {


  const { loading: loadingQuery, error: errorQuery, data } = useQuery(query, {
    variables: {
     id
    },
    // notifyOnNetworkStatusChange: true,
    // fetchPolicy: "cache-and-network",
  });

  return {
    loadingQuery,
    errorQuery,
    dataQuery: data && data.product
  }
};

export default useQueryProduct;