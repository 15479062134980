import React from "react";
import { Link } from "react-router-dom";
import { Layout, Button } from 'antd';
import PackagesTable from "./packages-table";
// import SearchForm from "../forms/search-form"
import useQueryPackages from './hooks/use-query-packages';
import { GET_PACKAGES } from "../../../graphql/query/packages-gql";

import './packages.scss';


const { Sider, Content } = Layout;



const Packages = () => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
    pagination,
    setExhibitionEventId,
    handleTableChange

  } = useQueryPackages(GET_PACKAGES);



  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Packages</h1>
        </Content>
        <Sider className="" style={ { background: "white" } }>
          <Link to={ `/packages/add-package` } style={ { marginLeft: "auto", display: "table" } }>
            <Button className='' type="primary">Add packages</Button>
          </Link>
        </Sider>
      </Layout>
      <PackagesTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        setExhibitionEventId={ setExhibitionEventId }
        handleTableChange={ handleTableChange }
      />
    </>
  );
};

export default Packages;
