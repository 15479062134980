import React from "react";
import NormalCommunicationForm from './normal-communication-form';



const CompanyCommunicationPageForm = ({ dataQueryStand, loadingQueryStand }) => {


  return (
    <>
      <NormalCommunicationForm
        dataQueryStand={ dataQueryStand }
        loadingQueryStand={ loadingQueryStand }
      />
    </>
  );
};


export default CompanyCommunicationPageForm;
