import React from "react";
import NormalTermCreateForm from './normal-term-create-form';
import useMutationTermCreate from '../hooks/use-mutation-term-create';


import {
  TAXONOMY_TERM_CREATE_UPDATE,
} from '../../../../graphql/mutation/taxonomies-gql'


import './term-create-form.scss';




const TermCreateForm = ({ dataQuery, loadingQuery }) => {

  const {
    _setMutationTermCreate,
    loadingMutationTermCreate

  } = useMutationTermCreate(TAXONOMY_TERM_CREATE_UPDATE, dataQuery);



  return (
    <>
      <NormalTermCreateForm
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        setMutationTermCreate={ _setMutationTermCreate }
        loadingMutationTermCreate={ loadingMutationTermCreate }

      />
    </>
  );
};


export default TermCreateForm;
