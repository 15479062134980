import React from "react";
import { Link } from "react-router-dom";
import { Table, Button } from "antd";
import mobileSize from "../../../../utils/mobile-size";
import EntityRemoveButton from '../../../service/delete-entity';
import useMutationAttachedDelete from './../hooks/use-mutation-attached-delete';
import AttachIcon from './../../../../utils/attach-icon';
import EditModal from '../../../service/edit-modal';
import UpdateAttachmentForm from '../../../forms/showroom-forms/update-attachment-form';
import { ATTACHED_DELETE } from "../../../../graphql/mutation/attachments-gql";


import "./stand-downloads-table.scss"



const { Column } = Table;


const StandDownloadsTable = ({
  pagination,
  dataQuery,
  loading,
  handleTableChange,
}) => {


  const {
    _setMutationAttachedDelete,
    loadingAttachedDelete

  } = useMutationAttachedDelete(ATTACHED_DELETE, dataQuery);


  return (
    <div className="">
      <Table size={ mobileSize('small') }
        className="main-table stand-downloads-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        // scroll={{ x: 800, y: 300 }}
        loading={ loading }
        dataSource={ !!dataQuery && dataQuery.data }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" dataIndex="id" key="id" sorter="true" />
        <Column title="Icon" key="extension"
          render={ item => {
            let extension = item.extension;
            return <a href={ item.path } target="_blank" rel="noreferrer"> <AttachIcon extension={ extension } /></a>
          } }
        />
        <Column title="file name" key="original_name" className="column-description-core"
          render={ item =>
            <a href={ item.path } target="_blank" rel="noreferrer">
              <span className="download-file-info">
                <span className="download-file-name">
                  <span className="file-name">{ item.original_name }</span>
                  <span>.{ item.extension }</span>
                </span>
              </span>
            </a>
          }
        />
        <Column title="size" dataIndex="size" key="size" />
        <Column title="Description" dataIndex="description" key="description" />
        <Column title="Status" dataIndex="status" key="status"
          filterMultiple={ false }
          filters={ [
            { text: "Draft", value: "draft" },
            { text: "Active", value: "active" },
          ] }
        />
        <Column title="Full name" key="name" render={ item =>
          <Link
            to={ {
              pathname: `/users/id-${ item.user.id }-${ item.user.name }-${ item.user.surname }`.toLowerCase(),
            } }>
            { `${ item.user.name } ${ item.user.surname }` }
          </Link>
        } />
        <Column title="Action" className="text-align-center" render={
          item =>
            <div className="wrap-link-btn">

              <a href={ item.path } target="_blank" rel="noreferrer"><Button type="primary">View</Button></a>

              <EditModal valueBtn={ 'Edit' } titleModal={ 'EDIT FILE: Status, Description' }>
                <UpdateAttachmentForm dataQuery={ item } loadingQuery={ loading } />
              </EditModal>

              <EntityRemoveButton
                nameEntity={ '' }
                dataNameEntity={ item.original_name }
                loading={ loadingAttachedDelete }
                deleteMutation={ _setMutationAttachedDelete }
                variables={
                  {
                    attachment_id: item.id,
                  }
                }
              />

            </div>
        } />

      </Table>
    </div>
  );
};

export default StandDownloadsTable;

