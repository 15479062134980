import { gql } from "@apollo/client";


export const GET_ATTACHMENTS_STAND = gql`
query GetAttachmentsStand(
  $stand_id: ID!,
  $status: [AttachedStatusEnum],
  $orderBy: [OrderByClause!],
  $first: Int,
  $page: Int,
) {
  attachmentsStand(
    stand_id: $stand_id,
    status: $status,
    orderBy: $orderBy,
    first: $first,
    page: $page
    ) {
    data {
      id
      extension
      original_name
      size
      description
      status
      path
      user {
        id
        name
        surname
      }
    }
     paginatorInfo {
      total
      perPage
      lastPage
      currentPage
    }
  }
}
`;

