import React from "react";
import StandProductsTable from "./stand-products-table";
// import SearchForm from "../forms/search-form"
import { Layout } from 'antd';
import { GET_STAND_PRODUCTS } from "../../../graphql/query/showrooms-gql";
import { PRODUCT_DELETE } from '../../../graphql/mutation/products-gql';
import useQueryStandProducts from './hooks/use-query-stand-products';
import useMutationProductDelete from './hooks/use-mutation-product-delete';

import './stand-products.scss';




const { Sider, Content } = Layout;

const StandProducts = () => {

  const {
    loadingQuery,
    dataQuery,
    // history,
    // setSearchVal,
    // routePage,
    pagination,
    handleTableChange
  } = useQueryStandProducts(GET_STAND_PRODUCTS);

  const {
    _setMutationProductDelete,
    loadingProductDelete

  } = useMutationProductDelete(PRODUCT_DELETE);


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Stand Products</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          {/* <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } /> */ }
        </Sider>
      </Layout>
      <StandProductsTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loading={ loadingQuery }
        handleTableChange={ handleTableChange }
        setMutationProductDelete={ _setMutationProductDelete }
        loadingProductDelete={ loadingProductDelete }
      />
    </>
  );
};

export default StandProducts;
