import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Typography, Avatar } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import mobileSize from "../../../../utils/mobile-size";
import link from "../../../../utils/link";

import "./products-table.scss"


const { Column } = Table;
const { Text } = Typography;


const ProductsTable = ({ pagination, dataQuery, loadingQuery, handleTableChange }) => {


  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        // scroll={{ x: 800, y: 300 }}
        loading={ loadingQuery }
        dataSource={ dataQuery }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" sorter="true" dataIndex="id" key="id" />

        <Column
          className="table-avatar-link"
          title="Cover"
          render={ item => {

            return (
              <Link
                to={ {
                  pathname: `/products/${ item.id }`,
                  state: {
                    // breadcrumbSlug: `${ item.name } ${ item.surname }`
                  }
                } }>
                { !item.cover ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ item.cover } /> }
              </Link>
            )
          } } />

        <Column title="Title" key="title" sorter="true" render={
          item => !item.title ?
            <Link
              to={ {
                pathname: `/products/${ item.id }`,
                state: {
                  breadcrumbSlug: 'Product name is not filled'
                }
              } }
            >
              <Text type="secondary">Product name is not filled</Text>
            </Link> :
            <Link
              // to={ `/products/${ item.id }` }
              to={ {
                pathname: `/products/${ item.id }`,
                state: {
                  breadcrumbSlug: item.title
                }
              } }
            >
              { `${ item.title }` }
            </Link> }
        />
        <Column title="Type" dataIndex="type" key="type" />
        <Column title="Top" render={ item => `${ item.is_top ? 'Yes' : 'No' }` } />
        <Column title="Searchable" key="title" render={ item => `${ item.is_searchable ? 'Yes' : 'No' }` } />
        <Column title="Status" dataIndex="status" key="STATUS"
          filterMultiple={ false }
          filters={ [
            { text: "Draft", value: "draft" },
            { text: "Active", value: "active" },
            { text: "Block", value: "block" },
          ] }
        />
        <Column title="Showroom" key="stand" className="column-description-core"
          render={
            item =>
              <Link
                key={ item.stand.id }
                to={ {
                  pathname: `/showrooms/${ item.stand.id }`,
                  state: {
                    breadcrumbSlug: item.stand.title
                  }
                } }
              >
                { `${ item.stand.title }` }
              </Link>
          }
        />
        <Column title="Exhibition event" key="EE_ID"
          render={
            item =>
              <Link key={ item.exhibition_event.id }
                to={ {
                  pathname: `/exhibition-events/${ item.exhibition_event.id }`,
                  state: {
                    breadcrumbSlug: item.exhibition_event.title
                  }
                } }
              >
                { `${ item.exhibition_event.title }` }
              </Link>
          }
        // filterMultiple={ false }
        // filters={ [
        //   { text: "ID", value: "id" },
        //   { text: "Title", value: "title" },
        // ] }
        />
        <Column title="Created" dataIndex="created_at" key="created_at" />
        <Column title="Action" className="text-align-center" render={
          item =>
            <div className="wrap-link-btn">
              <Link
                to={ {
                  pathname: `/products/${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                <Button type="primary">Edit</Button>
              </Link>
              <Link
                to={ getProductUrl(item) }
                target="_blank">
                <Button type="default">View on site</Button>
              </Link>
            </div>
        } />
      </Table>
    </div>
  );
};

export default ProductsTable;



const getProductUrl = (item) => {

  switch (item.type)
  {
    case "author":
      return `//${ item.exhibition_event.uri }/showroom-${ item.stand.id }/product#author-${ item.id }`;
    default:
      return `//${ item.exhibition_event.uri }/product/${ item.id }${ item.title ? "-" + link.toSlug(item.title) : "" }`;
  }
}