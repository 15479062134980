import React from "react";
import { Link } from "react-router-dom";
import { Spin, Result, Button } from "antd";
import isEmpty from 'lodash/isEmpty';



const RequestHandler =
  ({
    // blockHeight = 'h-100'/'h-100vh'/'h-100px'/'h-200px'/'h-300px'
    blockHeight = '',
    loading,
    error,
    data,
    children
  }) => {


    const errorDescrMsng = error && isEmpty(error.graphQLErrors[ 0 ].extensions.reason);

    const ErrorMessage = error && (
      !errorDescrMsng ?
        (error.graphQLErrors.map(({ message: label, extensions: { reason, action } }) => {
          return (

            <Result
              status="warning"
              title={ label }
              subTitle={ reason }
              extra={
                <Button type="primary"><Link to="/">Back Home</Link></Button>
              }
            />
          );
        })) :
        (
          <Result
            status="warning"
            title={ error.message }
            extra={
              <Button type="primary"><Link to="/">Back Home</Link></Button>
            }
          />
        )
    )


    const loaderJSX = loading && (
      <div className={ `block-loader ${ blockHeight }` } >
        <Spin size="middle" /*tip="Loading..."*/ />
      </div>
    );

    const errorJSX = error && (
      <>
        { ErrorMessage }
      </>
    );

    const dataJSX = data && (
      <>
        { children }
      </>
    );


    return (
      <>
        { dataJSX }
        { loaderJSX }
        { errorJSX }
      </>
    )
  };

export default RequestHandler;