import React from "react";
import { Form, Spin, Button, Input, Typography } from "antd";
import metaFields from '../../../../utils/meta-fields';
import { errorNotification } from "../../../result";
import UploadFile from './../../../upload/upload-files/upload-file/upload-file';
import useMutationMetaEeUpload from '../hooks/use-mutation-exbn-evnt-meta-upload';
import useMutationExbnMetaDelete from '../hooks/use-mutation-exbn-evnt-meta-delete';
import {
  EXBN_EVNT_META_UPLOAD,
  EXBN_EVNT_META_DELETE
} from "../../../../graphql/mutation/exhibition-gql";






const NormalEventContactForm = ({
  exbnEvnt,
  loadingQueryEvent,
  setMutationVariableEeMetaSet,
  loadingMutationEeMetaSet
}) => {


  const [ form ] = Form.useForm();

  const {
    _setMutationVariableEeUpload,
    loadingMutationEeUpload,

  } = useMutationMetaEeUpload(EXBN_EVNT_META_UPLOAD, exbnEvnt, form);

  const {
    _setMutationExbnMetaDelete,
  } = useMutationExbnMetaDelete(EXBN_EVNT_META_DELETE);

  const { getValue, normalize } = metaFields;
  const meta = normalize(exbnEvnt.meta_fields);


  const formSubmit = ({ values }) => {

    let { metaValues } = metaFields.parseForm(values);
    metaValues = metaFields.filterEmpty(metaValues, exbnEvnt.meta_fields);

    setMutationVariableEeMetaSet({
      variables: {
        eeMetaSetEeId: exbnEvnt.id,
        eeMetaSetInput: [ ...metaValues ]
      }
    }).catch((error) => {
      errorNotification(error);
    });
  };


  return (
    <div>
      { loadingQueryEvent ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
          className="form-container lg"
        >

          <Typography.Link className="text-decorator">
            <h6>Exhibition Legal</h6>
            <div className="separator"></div>
          </Typography.Link>

          <Form.Item className="form-group">

            <Form.Item
              className="file-upload-box"
              name="ee_terms_of_service-file-unset-ee_legal"
              label="Terms of Service PDF"
              initialValue={ getValue(meta, "ee_terms_of_service") }
            >
              <UploadFile
                // size={ 140 }
                url={ getValue(meta, "ee_terms_of_service") }
                loading={ loadingMutationEeUpload }
                uploadMutation={ _setMutationVariableEeUpload }
                uploadButtonText={ "Upload Terms of Service PDF" }
                accept={ 'application/pdf' }
                variables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_terms_of_service",
                    meta_group: "ee_legal",
                    meta_type: "file"
                  }
                }
                deleteMutation={ _setMutationExbnMetaDelete }
                deleteVariables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_terms_of_service"
                  }
                }
              />
            </Form.Item>

            <Form.Item
              className="file-upload-box"
              name="ee_privacy_policy-file-unset-ee_legal"
              label="Privacy Policy PDF"
              initialValue={ getValue(meta, "ee_privacy_policy") }
            >
              <UploadFile
                // size={ 140 }
                url={ getValue(meta, "ee_privacy_policy") }
                loading={ loadingMutationEeUpload }
                uploadMutation={ _setMutationVariableEeUpload }
                uploadButtonText={ "Upload Privacy Policy PDF" }
                accept={ 'application/pdf' }
                variables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_privacy_policy",
                    meta_group: "ee_legal",
                    meta_type: "file"
                  }
                }
                deleteMutation={ _setMutationExbnMetaDelete }
                deleteVariables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_privacy_policy"
                  }
                }
              />
            </Form.Item>

            <Form.Item
              className="file-upload-box"
              name="ee_imprint-file-unset-ee_legal"
              label="Imprint PDF"
              initialValue={ getValue(meta, "ee_imprint") }
            >
              <UploadFile
                // size={ 140 }
                url={ getValue(meta, "ee_imprint") }
                loading={ loadingMutationEeUpload }
                uploadMutation={ _setMutationVariableEeUpload }
                uploadButtonText={ "Upload Imprint PDF" }
                accept={ 'application/pdf' }
                variables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_imprint",
                    meta_group: "ee_legal",
                    meta_type: "file"
                  }
                }
                deleteMutation={ _setMutationExbnMetaDelete }
                deleteVariables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_imprint"
                  }
                }
              />
            </Form.Item>

            <Form.Item
              className="file-upload-box"
              name="ee_data_protection-file-unset-ee_legal"
              label="Data protection PDF"
              initialValue={ getValue(meta, "ee_data_protection") }
            >
              <UploadFile
                // size={ 140 }
                url={ getValue(meta, "ee_data_protection") }
                loading={ loadingMutationEeUpload }
                uploadMutation={ _setMutationVariableEeUpload }
                uploadButtonText={ "Upload Data protection PDF" }
                accept={ 'application/pdf' }
                variables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_data_protection",
                    meta_group: "ee_legal",
                    meta_type: "file"
                  }
                }
                deleteMutation={ _setMutationExbnMetaDelete }
                deleteVariables={
                  {
                    ee_id: exbnEvnt.id,
                    meta_key: "ee_data_protection"
                  }
                }
              />
            </Form.Item>
          </Form.Item>

          <Typography.Link className="text-decorator">
            <h6>Exhibition contacts</h6>
            <div className="separator"></div>
          </Typography.Link>

          <Form.Item className="form-group">
            <Form.Item
              name="ee_contact_phone-string--ee_contacts"
              label="Contact Phone"
              initialValue={ getValue(meta, "ee_contact_phone") }
            >
              <Input placeholder="Enter your Contact Phone" />
            </Form.Item>

            <Form.Item
              name="ee_contact_email-string--ee_contacts"
              label="Contact email"
              initialValue={ getValue(meta, "ee_contact_email") }
            >
              <Input placeholder="Enter your Contact Email" />
            </Form.Item>

            <Form.Item
              name="ee_exhibitor_site_url-string--ee_contacts"
              label="Exhibittor site"
              initialValue={ getValue(meta, "ee_exhibitor_site_url") }
            >
              <Input placeholder="Enter your Exhibittor Site" />
            </Form.Item>
          </Form.Item>

          <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
            <Button loading={ loadingMutationEeMetaSet } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
          </Form.Item>

        </Form>
      )
      }
    </div>
  );
};

export default NormalEventContactForm;
