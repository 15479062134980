import React from "react";
import { Table, Tooltip } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import TranslationKeysForm from './../../../forms/localization-forms/translation-keys-form/';
import EditModal from './../../../service/edit-modal';
import mobileSize from "../../../../utils/mobile-size";

import "./translation-keys-table.scss"


const { Column } = Table;


const TranslationKeysTable = ({ dataQuery, loadingQuery, pagination, handleTableChange }) => {

  return (

    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table translation-keys-table"
        // style={ { whiteSpace: "nowrap" } }
        loading={ loadingQuery }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange({ pagination, filters, sorter, page })
        }
        dataSource={ dataQuery }
        rowKey={ key => key.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" key="id" dataIndex="id" sorter="true" />
        <Column title="Key" key="key" dataIndex="key" sorter="true" width={ 300 } />
        <Column
          title="Type"
          key="type"
          dataIndex="type"
          filterMultiple={ false }
          filters={ [
            { text: "Admin", value: "admin" },
            { text: "Site", value: "site" },
            { text: "Core", value: "core" },
          ] }
        />
        <Column title="Description" key="description"
          className="column-description"
          render={
            key =>
              <>
                <div style={ { position: 'absolute' } }>
                  <Tooltip overlayClassName='tooltip-width' title={ <pre>{ key.type === 'core' ? key.help : key.description }</pre> }>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <div style={ { marginLeft: '30px' } }>
                  <span>{ key.description }</span>
                </div>
              </>
          } />
        <Column
          title="Action"
          key="action"
          className="column-child"
          render={
            key => (
              <>
                <EditModal key="0" valueBtn={ 'Edit key' } titleModal={ 'Update Translation Key' }>
                  <TranslationKeysForm dataQuery={ key } loadingQuery={ loadingQuery } />
                </EditModal>
              </>
            )
          }
        />
      </Table>
    </div>
  );
};

export default TranslationKeysTable;