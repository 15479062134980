import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Avatar, Typography } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import normalMeta from '../../../utils/normal-meta';
import mobileSize from "../../../utils/mobile-size";



import "./exhibition-events-table.scss"

const { Column } = Table;
const { Text } = Typography;


const ExhibitionEventsTable = ({ pagination, dataQuery, loading, handleTableChange, routePage }) => {

  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table exhibition-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        // scroll={{ x: 800, y: 300 }}
        loading={ loading }
        dataSource={ dataQuery.data }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" dataIndex="id" key="id" />

        <Column
          className="table-avatar-link"
          title="Logo"
          render={ item => {
            const eventMetaFields = normalMeta(item.meta_fields);
            const imageUrl = !!eventMetaFields.ee_logo && eventMetaFields.ee_logo.meta_value;
            return (
              <Link
                key={ item.id }
                to={ {
                  pathname: `${ routePage }${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }>
                { !imageUrl ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ imageUrl } /> }
              </Link>
            )
          } } />
        <Column
          title="Title"
          key="title"
          render={
            item => !item.title ?
              <Link
                to={ {
                  pathname: `${ routePage }${ item.id }`,
                  state: {
                    breadcrumbSlug: 'Event name is not filled'
                  }
                } }
              >
                <Text type="secondary">Event name is not filled</Text>
              </Link> :
              <Link
                to={ {
                  pathname: `${ routePage }${ item.id }`,
                  state: {
                    breadcrumbSlug: item.title
                  }
                } }
              >
                { `${ item.title }` }
              </Link>
          }
        />
        <Column title="URL"
          render={
            item =>
              <Link
                to={ {
                  pathname: `//${ item?.uri }`,
                } }
                target="_blank"
              >
                { `${ item?.uri }` }
              </Link>
          }

        />
        <Column title="Start date" className="column-date" dataIndex="start_at" key="start_at" />
        <Column title="End date" className="column-date" dataIndex="end_at" key="end_at" />
        <Column title="Archive date" className="column-date" dataIndex="archive_at" key="archive_at" />
        <Column title="Status" dataIndex="status" key="status" />
        <Column title="Created" className="column-date" dataIndex="created_at" key="created_at" />
        <Column title="Action" render={ item => <Link to={ `${ routePage }${ item.id }` }><Button type="primary">Edit</Button></Link> } />
      </Table>
    </div>
  );
};

export default ExhibitionEventsTable;

