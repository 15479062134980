import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";
// import metaFields from '../../../../utils/meta-fields';

const useMutationPackageFileUpload = (mutation, dataQuery, form) => {

  // const { normalize } = metaFields;

  const [ _setMutationPackageFileUpload,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            packageUpdloadPDF: {
              label,
              message,
              package: {
                id,
                pdf
              }

            }
          } = data;


          cache.modify({
            id: cache.identify(dataQuery),
            fields: {
              id() {
                return id;
              },
              pdf() {
                return pdf;
              }
            },
          });


          // form.setFieldsValue({
          //   "cover-image-unset": cover,
          // });

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationPackageFileUpload,
    loadingPackageFileUpload: loading,
  }
};

export default useMutationPackageFileUpload;
