import React from "react";
import ShowroomsTable from "./showrooms-table";
import SearchForm from "../../forms/search-form"
import { Layout } from 'antd';
import { GET_STANDS } from "../../../graphql/query/showrooms-gql";
import useQueryShowrooms from './hooks/use-query-showrooms';
import { STAND_DELETE } from '../../../graphql/mutation/showroom-gql';
import useMutationStandDelete from './hooks/use-mutation-stand-delete';
import './showrooms.scss';




const { Sider, Content } = Layout;

const Showrooms = () => {

  const {
    eventId,
    loadingQuery,
    dataQuery,
    history,
    setSearchVal,
    routePage,
    pagination,
    handleTableChange
  } = useQueryShowrooms(GET_STANDS);


  const {
    _setMutationStandDelete,
    loadingStandDelete

  } = useMutationStandDelete(STAND_DELETE, dataQuery);



  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Showrooms</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>
      <ShowroomsTable
        eventId={ eventId }
        pagination={ pagination }
        dataQuery={ dataQuery }
        loading={ loadingQuery }
        handleTableChange={ handleTableChange }
        routePage={ routePage }
        setMutationStandDelete={ _setMutationStandDelete }
        loadingStandDelete={ loadingStandDelete }
      />
    </>
  );
};

export default Showrooms;
