import React from "react";
import NormalPackageCreateForm from './normal-package-create-form';
import useMutationPackageCreateUpdate from './hooks/use-mutation-package-create-update';
import useMutationPackageDelete from './hooks/use-mutation-package-delete';


import {
  PACKAGE_CREATE_UPDATE,
  PACKAGE_DELETE
} from '../../../../graphql/mutation/packages-gql'


import './package-create-form.scss';


const PackageCreateForm = ({ dataQuery, loadingQuery }) => {

  const {
    _setMutationPackageCreateUpdate,
    loadingMutationPackageCreateUpdat

  } = useMutationPackageCreateUpdate(PACKAGE_CREATE_UPDATE, dataQuery);

  const {
    _setMutationPackageDelete,
    loadingPackageDelete,

  } = useMutationPackageDelete(PACKAGE_DELETE);



  return (
    <>
      <NormalPackageCreateForm
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        setMutationPackageCreateUpdate={ _setMutationPackageCreateUpdate }
        loadingMutationPackageCreateUpdat={ loadingMutationPackageCreateUpdat }
        setMutationPackageDelete={ _setMutationPackageDelete }
        loadingPackageDelete={ loadingPackageDelete }
      />
    </>
  );
};

export default PackageCreateForm;
