import React /*, { useState }*/ from "react";
import { Form, Input } from "antd";


import './input-color-picker.scss';



const InputColorPicker = ({
  labelInput = 'Choose your color',
  nameInput = '',
  currentColor = '',
  setCurrentColor = () => { },
}) => {
  // const [ color, setColor ] = useState(null);


  return (
    <Form.Item
      className="input-color-picker"
      name={ nameInput }
      label={ labelInput }
      initialValue={ currentColor }
    >
      <Input type="color" onChange={ e => setCurrentColor(e.target.value) } />
    </Form.Item>
  );
}

export default InputColorPicker;