import React from "react";
import ExhibitionEventsTable from "./exhibition-events-table";
// import SearchForm from "../forms/search-form"
import { Layout } from 'antd';
import { GET_EXBN_EVNT_ALL } from "../../graphql/query/exhibition-gql";
import useQueryExbnEvnts from './hooks/use-query-exbn-evnts';
import RequestHandler from './../request-handler/index';

import './exhibition-events.scss';


const { Sider, Content } = Layout;

const ExhibitionEvents = () => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
    // history,
    // setSearchVal,
    routePage,
    pagination,
    handleTableChange
  } = useQueryExbnEvnts(GET_EXBN_EVNT_ALL);


  return (

    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQuery }
    >
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Exhibition Events</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          {/* <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } /> */ }
        </Sider>
      </Layout>
      <ExhibitionEventsTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loading={ loadingQuery }
        handleTableChange={ handleTableChange }
        routePage={ routePage }
      />
    </RequestHandler>

  );
};

export default ExhibitionEvents;
