import React from "react";
import { Form, Spin, Radio } from "antd";
// import RadioValue from './radio-value';

import './radio-button.scss';



const RadioButton = ({
  radioValue = '',
  loadingQuery,
  radioLabel = '',
  radioName = '',
  radioVal = [],
  radioText = [],
  radioOnChange = () => { }

}) => {


  return (
    <>
      { loadingQuery ? (
        <div className="block-loader h-100px">
          <Spin size="small" />
        </div>
      ) : (
        <>
          <Form.Item
            label={ radioLabel }
            initialValue={ radioValue }
            name={ radioName }
            className="form-group"
            style={ { margin: "10px 0" } }>
            <Radio.Group onChange={ radioOnChange }>
              <Radio.Button value={ radioVal[ 0 ] }>{ radioText[ 0 ] }</Radio.Button>
              <Radio.Button value={ radioVal[ 1 ] }>{ radioText[ 1 ] }</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </>
      )
      }
    </>
  );
}



export default RadioButton;



