import React from "react";
import ProductCreateForm from '../components/forms/product-forms/product-create-form';



const ProductsPage = () => {

  return (
    <div className="page-holder bg-white">
       <ProductCreateForm />
    </div>
  );
};

export default ProductsPage;
