import { gql } from "@apollo/client";



export const GET_PACKAGES = gql`
  query GetPackages(
  $ee_id: ID,
  $title: String,
  $price: NumericRange,
  $first: Int,
  $page: Int,
  $orderBy: [QueryPackagesOrderByOrderByClause!],
  ) {
    packages(
      ee_id: $ee_id,
      title: $title,
      price: $price,
      first: $first,
      page: $page,
      orderBy: $orderBy,
    ) {
      data {
        id
        title
        description
        price
        pdf
        created_at
        exhibition_event {
          id
          title
        }
      }
      paginatorInfo {
        total
        perPage
        lastPage
        currentPage
      }
    }
  }
`;


export const GET_PACKAGES_FOR_SELECT = gql`
query GetPackagesForSelect($first: Int, $page: Int) {
  packages(first: $first, page: $page) {
    paginatorInfo {
      total
      perPage
      lastPage
      currentPage
    }
    data {
      id
      title
    }
  }
}
`;

export const GET_PACKAGE = gql`
  query GetPackage($id: ID!) {
    package(id: $id) {
      id
      title
      price
      ee_id
      pdf
      description
      created_at
      updated_at
    }
  }
`;


export const GET_PACKAGE_RULES = gql`
  query GetPackageRules(
    $package_id: ID,
    $title: String,
    $rule_key: String,
    $type: PackageRuleConstantStatusEnum,
    $active: Boolean,
    $created_at: DateTimeRange,
    $first: Int,
    $page: Int,
    $orderBy: [QueryPackageRulesOrderByOrderByClause!]
  ) {
    packageRules(
      package_id: $package_id,
      title: $title,
      rule_key: $rule_key,
      type: $type,
      active: $active,
      created_at: $created_at,
      first: $first,
      page: $page,
      orderBy: $orderBy
    ) {
      data {
        id
        title
        package_id
        rule_key
        order
        min_value
        max_value
        type
        active
        created_at
        updated_at
      }
      paginatorInfo {
        total
        perPage
        lastPage
        currentPage
      }
    }
  }
`;


export const GET_PACKAGE_RULE_CONSTANTS = gql`
  query GetPackageRuleConstants(
  $id: ID,
  $title: String,
  $rule_key: String,
  $type: PackageRuleConstantStatusEnum,
  $first: Int,
  $page: Int,

  ) {
    packageRuleConstants(
      id: $id,
      title: $title,
      rule_key: $rule_key,
      type: $type,
      first: $first,
      page: $page
    ) {
      data {
        id
        title
        rule_key
        type
        description
        created_at
        updated_at
      }
      paginatorInfo {
        total
        perPage
        lastPage
        currentPage
      }
    }
  }
`;


export const GET_PACKAGE_REQUESTS = gql`
  query GetPackageRequests(
    $package_id: ID,
    $stand_id: ID,
    $user_id: ID,
    $moderator_id: ID,
    $status: [PackageRequestStatusEnum],
    $created_at: DateTimeRange
    $first: Int,
    $page: Int,
    $orderBy: [QueryPackageRequestsOrderByOrderByClause!]
  ) {
    packageRequests(
      moderator_id: $moderator_id,
      package_id: $package_id,
      stand_id: $stand_id,
      user_id: $user_id,
      status: $status,
      created_at: $created_at
      first: $first,
      page: $page,
      orderBy: $orderBy
    ) {
      data {
        id
        package_id
        stand_id
        user_id
        moderator_id
        status
        message_request
        message_answer
        package {
          title
        }
        stand {
          title
          package {
            title
          }
        }
        user {
          id
          name
          surname
        }
        moderator {
          id
          name
          surname
          email_original
        }
        created_at
        updated_at
      }
      paginatorInfo {
        total
        perPage
        lastPage
        currentPage
      }
    }
  }
`;




