import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import convertSortName from '../../../../utils/convert-sort-name';


const useQueryPackageRequestsResolved = (query) => {

  const routePage = `/packages/package-requests-resolved/`;
  let history = useHistory();
  const { numPage } = useParams();
  const [ pageSize, setPageSize ] = useState(10);
  const [ moderatorId, setModeratorId ] = useState(undefined);
  const [ standId, setStandId ] = useState(undefined);
  const [ packageId, setPackageId] = useState(undefined);
  const [ userId, setUserId ] = useState(undefined);

  const [ order, setOrder ] = useState({
    orderBy: [
      {
        column: "ID",
        order: 'ASC'
      }
    ]
  });

  const statusDefault = ['approved', 'declined']
  const [ status, setStatus ] = useState(statusDefault);


  const { loading, error, data } = useQuery(query, {
    variables: {
      moderator_id: +moderatorId || undefined,
      stand_id: +standId || undefined,
      package_id: +packageId || undefined,
      user_id: +userId || undefined,
      status: status || statusDefault,
      ...order,
      first: pageSize,
      page: +numPage ? +numPage : 1,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });



  const packageRequests = !loading && data?.packageRequests;
  const { paginatorInfo } = !loading && packageRequests;
  const { total, currentPage } = !loading && paginatorInfo;


  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter, page) => {

    setPageSize(pagination.pageSize)

    const {
      order,
      columnKey
    } = sorter;


    setOrder({
      orderBy: [
        {
          column: !!columnKey ? columnKey.toUpperCase() : 'ID',
          order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
        }
      ]
    });

    setStatus(filters.status)


    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: packageRequests.data,
    pagination,
    handleTableChange,
    setStandId,
    setPackageId,
    setUserId,
    setModeratorId
  }
};

export default useQueryPackageRequestsResolved;

