import React, { useState, useEffect } from "react";
import { Form, Button, Input, Radio, Spin } from "antd";
import { errorNotification } from "../../../result";
// import RequestHandler from "../../../request-handler"
// import parseFormMeta from './../../../../utils/meta-fields';



const NormalLanguageForm = ({
  dataQuery,
  loadingQuery,
  loadingMutationCreate,
  setMutationVariableCreate,
  setModalVisible

}) => {

  const [ form ] = Form.useForm();

  const langId = dataQuery.id;

  const initRadioVal = !!langId ? dataQuery.status : true;

  const [ radioValue, setRadioValue ] = useState(initRadioVal);

  useEffect(() => {
    setRadioValue(initRadioVal)
  }, [ initRadioVal ]);


  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };



  const formSubmit = ({ setMutationVariableCreate, values }) => {

    form.resetFields();

    setModalVisible(false);

    setMutationVariableCreate({
      variables: {
        langCrteUpdte: {
          id: langId || '',
          label: values.label,
          slug: values.slug,
          status: values.status
        }
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };


  return (

    loadingQuery ? (
      <div className="block-loader h-300px" >
        <Spin size="small" />
      </div>
    ) : (
        <Form
          form={ form }
          onFinish={ (values) => {
            formSubmit({ setMutationVariableCreate, values });
          } }
          layout="vertical"
          className="language-form"
        >
          <Form.Item
            initialValue={ radioValue }
            name="status"
            label="Status"
            style={ { margin: "10px 0" } }>
            <Radio.Group className="radio-row" onChange={ radioOnChange } value={ radioValue }>
              <Radio value={ true }>Active</Radio>
              <Radio value={ false }>Inactive</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            initialValue={ dataQuery && dataQuery.label }
            name="label"
            label="Label"
            rules={ [
              {
                type: 'string',
                // message: '',
              },
              {
                required: true,
                message: 'Please input label language'
              },
              { max: 190, message: 'Label must be maximum 191 characters.' },
            ] }
          >
            <Input maxLength={ 191 } placeholder="Enter label language" />
          </Form.Item>

          <Form.Item
            initialValue={ dataQuery && dataQuery.slug }
            name="slug"
            label="Slug"

            rules={ [
              {
                type: 'string',
                // message: '',
              },
              {
                required: true,
                message: 'Please input Slug language'
              },
            ] }
          >
            <Input maxLength={ 3 } placeholder="Enter Slug language (max 3 symbol)" />
          </Form.Item>

          <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
            <Button loading={ loadingMutationCreate } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
          </Form.Item>
        </Form>
      )
  )
}


export default NormalLanguageForm;
