import React from "react";
import { Form, Button, Input, Spin } from "antd";
import { errorNotification } from "../../../result";


const NormalTermCreateForm = ({
  dataQuery,
  loadingQuery,
  setMutationTermCreate,
  loadingMutationTermCreate
}) => {

  const [ form ] = Form.useForm();
  dataQuery = dataQuery && dataQuery


  const formSubmit = ({ values }) => {

    form.resetFields();

    setMutationTermCreate({
      variables: {
        taxonomy_id: dataQuery.id,
        title: values.title,
        sort_number: values.sort_number
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };



  return (

    <div className="form-container lg">
      { loadingQuery ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
          <Form
            className="form-container lg"
            form={ form }
            onFinish={ (values) => {
              formSubmit({ values });
            } }
            layout="vertical"
          >
            <Form.Item className="form-group">

              <Form.Item
                name="title"
                label="Term label"
              >
                <Input placeholder="Enter Term Title" />
              </Form.Item>

              <Form.Item
                hidden
                initialValue={ 0 }
                name="sort_number"
                label="Sort number"
              >
                <Input />
              </Form.Item>

              <Button loading={ loadingMutationTermCreate } type="primary" htmlType="submit" style={ { marginTop: "30px" } }>ADD TERM</Button>
            </Form.Item>
          </Form>
        )
      }
    </div>
  );
};


export default NormalTermCreateForm;
