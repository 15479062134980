import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Form, Button, Input, Radio, Spin } from "antd";
import { errorNotification } from "../../../result";
import SelectPackageRuleConstants from '../../select-package-rule-constants';


const NormalPackageRuleCreateForm = ({
  dataQuery,
  loadingQuery,
  loadingMutationCreate,
  // loadingMutationUpdate,
  setMutationVariableCreate,
  setModalVisible,

  loadingQueryRuleConstants,
  errorQueryRuleConstants,
  dataQueryRuleConstants

}) => {

  const [ form ] = Form.useForm();

  const { pckgId } = useParams();

  const packageRulesId = dataQuery && +dataQuery.id;

  const initRadioVal = !!packageRulesId ? dataQuery.active : false;

  const [ radioValue, setRadioValue ] = useState(initRadioVal);
  const [ selectValRuleOrText, setSelectValRuleOrText ] = useState(false);


  useEffect(() => {
    setRadioValue(initRadioVal)
  }, [ initRadioVal ]);


  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };


  const selectOnChange = (value) => {

    const titleRuleConstant = !loadingQueryRuleConstants &&
      (dataQueryRuleConstants
        .filter((i) => i.id === value.key)
      )

    if (titleRuleConstant[ 0 ]?.type === "rule")
    {
      setSelectValRuleOrText(true);
    } else
    {
      setSelectValRuleOrText(false)
    }

    form.setFieldsValue({
      "title": titleRuleConstant[ 0 ]?.title,
      "type": titleRuleConstant[ 0 ]?.type,
    });

  };

  const formSubmit = ({ values }) => {

    form.resetFields();
    setModalVisible(false)

    setMutationVariableCreate({
      variables: {
        // order: Int,
        id: packageRulesId,
        package_id: +pckgId,
        rule_key: values.rule_key.label,
        type: values.type,
        title: values.title,
        active: values.active,
        min_value: +values.min_value || 0,
        max_value: +values.max_value || 0,
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };


  return (

    loadingQuery ? (
      <div className="block-loader h-300px" >
        <Spin size="small" />
      </div>
    ) : (
      <Form
        form={ form }
        onFinish={ (values) => {
          formSubmit({ setMutationVariableCreate, values });
        } }
        layout="vertical"
        className="translation-keys-form"
      >
        <Form.Item className="form-group">
          <SelectPackageRuleConstants
            packageRulesId={ packageRulesId }
            selectOnChange={ selectOnChange }
            loadingQueryRuleConstants={ loadingQueryRuleConstants }
            errorQueryRuleConstants={ errorQueryRuleConstants }
            dataQueryRuleConstants={ dataQueryRuleConstants }
            dataQuery={ dataQuery }
          />

          <Form.Item
            name="type"
            label="Type"
            initialValue={ !!packageRulesId ? dataQuery.type : '' }
          // rules={ [
          //   {
          //     type: "string",
          //   },
          //   { required: true, message: 'Please input Package type' }
          // ] }
          >
            <Input disabled={ true } />
          </Form.Item>
        </Form.Item>


        <Form.Item className="form-group">
          <Form.Item
            name="title"
            label="Title"
            initialValue={ !!packageRulesId ? dataQuery.title : '' }
            rules={ [
              {
                type: "string",
              },
              { required: true, message: 'Please input Package Title' }
            ] }
          >
            <Input placeholder="Enter Package Title" />
          </Form.Item>

          <Form.Item
            initialValue={ radioValue }
            name="active"
            label="Status"
          >
            <Radio.Group className="" onChange={ radioOnChange } value={ radioValue }>
              <Radio.Button value={ true }>Allowed</Radio.Button>
              <Radio.Button value={ false }>Forbidden</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form.Item>

        {
          (selectValRuleOrText || (dataQuery && dataQuery.type === 'rule')) && (

            <Form.Item className="form-group">
              <Form.Item
                name="min_value"
                label="Min value"
                initialValue={ dataQuery ? dataQuery.min_value : '' }
              >
                <Input placeholder="Enter Package Min value" />
              </Form.Item>

              <Form.Item
                name="max_value"
                label="Max value"
                initialValue={ dataQuery ? dataQuery.max_value : '' }
              >
                <Input placeholder="Enter Package Max value" />
              </Form.Item>
            </Form.Item>
          )
        }

        <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
          <Button loading={ loadingMutationCreate } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
        </Form.Item>
      </Form>
    )
  );
};


export default NormalPackageRuleCreateForm;
