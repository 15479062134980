import { useMutation } from "@apollo/client";
import { GET_TAXONOMY } from "../../../../graphql/query/taxonomies-gql";
import { successNotification } from "../../../result";



const useMutationTermCreate = (mutation, dataQuery) => {

  const [ _setMutationTermCreate,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            taxonomyTermCreateOrUpdate: {
              label,
              message,
              taxonomy_term
            }
          } = data;


          cache.writeQuery({
            query: GET_TAXONOMY,
            variables: {
              id: dataQuery.id
            },
            data: {
              taxonomy: {
                taxonomy_terms: [
                  taxonomy_term,
                  ...dataQuery.taxonomy_terms
                ],
                __typename: dataQuery.__typename
              }
            }
          })

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        }
      });


  return {
    _setMutationTermCreate,
    loadingMutationTermCreate: loading,
  }
};

export default useMutationTermCreate;