import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";



const useMutationMetaUpload = (mutation, dataQuery, form) => {


  const [ _setMutationVariableUpload,
    { loading: loadingMutation,
      error: errorMutation, data } ] = useMutation(mutation,
        {
          update(cache, { data }) {

            const {
              userMetaUpload: {
                label,
                message,
                user: {
                  avatar
                }
              }
            } = data;

            cache.modify({
              id: cache.identify(dataQuery),
              fields: {

                avatar() {
                  return avatar;
                },
              },
            });

            form.setFieldsValue({ "avatar": avatar });

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
        });

  return {
    _setMutationVariableUpload,
    loadingMutation,
    errorMutation,
    dataMutation: data && data.nameMutatiom
  }
};

export default useMutationMetaUpload;