import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Input, Radio, Select } from "antd";
import { errorNotification } from "../../../result";
import RequestHandler from "../../../request-handler"
// import metaFields from './../../../../utils/meta-fields';
import SelecStands from '../../select-stands';
import useMutationProductMetaUpload from '../hooks/use-mutation-product-meta-upload';
import AvatarUpload from './../../../upload/avatar/avatar-upload/avatar-upload';
import EntityRemoveButton from './../../../service/delete-entity';
import {
  PRODUCT_META_UPLOAD,
} from '../../../../graphql/mutation/products-gql'



const { TextArea } = Input;

const NormalProductUpdateAuthorForm = ({

  dataQuery,
  errorQuery,
  loadingQuery,
  setMutationProductCreateUpdate,
  loadingMutationProductCreateUpdate,
  setMutationProductDelete,
  loadingProductDelete

}) => {

  const [ form ] = Form.useForm();
  const { Option } = Select;

  const [ radioValue, setRadioValue ] = useState(dataQuery.status);
  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };

  const [ radioValueIsTop, setRadioValueIsTop ] = useState(false);
  const radioOnChangeIsTop = e => {
    setRadioValueIsTop(e.target.value);
  };



  const {
    _setMutationProductUpload,
    loadingMutationProductUpload

  } = useMutationProductMetaUpload(PRODUCT_META_UPLOAD, dataQuery, form);



  const formSubmit = ({ values }) => {

    setMutationProductCreateUpdate({
      variables: {
        input: {
          id: dataQuery.id,
          stand_id: values[ 'stand_id--unset' ].value,
          title: values.title,
          description: values.description,
          type: values.type,
          status: values.status,
          is_searchable: values.is_searchable,
          is_top: values.is_top

        }
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };


  return (

    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQuery }
    >
      <>

        <Form
          className="form-container lg add-product-form"
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
        >
          <Form.Item className="form-group">
            <div style={ { position: 'relative' } }>
              <div style={ { position: 'absolute', top: '-10px', right: '0', zIndex: '1' } }>
                <Link style={ { marginBottom: '10px' } } to={ `/showrooms/${ dataQuery.stand.id }` }><Button type="primary" ghost>View Stand</Button></Link>
              </div>
              <SelecStands standTitle={ dataQuery.stand.title } standId={ dataQuery.stand.id } />
            </div>

            <Form.Item
              name="status"
              label="Status"
              initialValue={ radioValue }>
              <Radio.Group
                className="radio-row" onChange={ radioOnChange }
              >
                <Radio.Button value="draft">Draft</Radio.Button>
                <Radio.Button value="active">Active</Radio.Button>
                <Radio.Button value="block">Block</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Author avatar"
              name="cover-image-unset"
              initialValue={ dataQuery.cover }
              rules={
                [
                  {
                    required: true,
                    message: 'Please upload Author avatar!',
                  }
                ]
              }
            >
              <AvatarUpload
                size={ 140 }
                image={ dataQuery.cover }
                loading={ loadingMutationProductUpload }
                uploadMutation={ _setMutationProductUpload }
                variables={
                  {
                    input: {
                      product_id: dataQuery.id,
                      meta_key: "cover",
                    }
                  }
                }
                extraClass={ "contain" }
                uploadButtonText={ "Upload Author avatar" }
                shape={ "square" }
              />
            </Form.Item>

            <div>
              <Form.Item
                name="title"
                label="Author name"
                initialValue={ dataQuery.title }
                rules={ [
                  {
                    type: "string",
                  },
                  { required: true, message: 'Please input your Author name' }
                ] }
              >
                <Input placeholder="Enter Author name" />
              </Form.Item>

              <Form.Item
                name="type"
                label="Product type"
                initialValue={ dataQuery.type }
                rules={ [ {
                  required: true,
                  message: "Select Product type!"
                } ] }
              >
                <Select
                  disabled={ true }
                  placeholder="Select Product type"
                >
                  <Option value="book">Book</Option>
                  <Option value="author">Author</Option>
                  <Option value="product">Product</Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              hidden
              name="is_top"
              label="Top"
              initialValue={ radioValueIsTop }
            >

              <Radio.Group
                className="radio-row" onChange={ radioOnChangeIsTop }
                disabled={ true }
              >
                <Radio.Button value={ true }>Yes</Radio.Button>
                <Radio.Button value={ false }>No</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              hidden
              name="is_searchable"
              label="Searchable"
              initialValue={ false }
              rules={ [
                // {
                //   type: "string",
                // },
                // { required: true }
              ] }
            >
              <Input disabled={ true } />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            initialValue={ dataQuery.description }
          // rules={
          //   [
          //     { max: 299, message: 'Field must be maximum 300 characters.' },
          //   ]
          // }
          >
            <TextArea
              maxLength={ 1200 }
              placeholder="Enter short product description"
              autoSize={ { minRows: 3, maxRows: 5 } }
              showCount={
                { formatter: ({ count, maxLength }) => maxLength - count }
              }
            />
          </Form.Item>

          <br/>

          <div className="d-flex justify-content-between">
            <div>
              <Button loading={ loadingMutationProductCreateUpdate } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
            </div>
            <div>
              <EntityRemoveButton
                nameEntity={ 'Product' }
                dataNameEntity={ `${ dataQuery.title }` }
                loading={ loadingProductDelete }
                deleteMutation={ setMutationProductDelete }
                variables={
                  {
                    id: dataQuery.id,
                  }
                }
              />
            </div>
          </div>
        </Form>
      </>
    </RequestHandler>
  );
};


export default NormalProductUpdateAuthorForm;
