import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Avatar, Typography } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
import mobileSize from "../../../../utils/mobile-size";
import EntityRemoveButton from '../../../service/delete-entity';
import "./stand-products-table.scss"


const { Column } = Table;
const { Text } = Typography;

const StandProductsTable = ({
  pagination,
  dataQuery,
  loading,
  handleTableChange,
  setMutationProductDelete,
  loadingProductDelete
}) => {

  return (
    <div className="">
      <Table size={ mobileSize('small') }
        className="main-table stand-products-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange(pagination, filters, sorter, page)
        }
        // scroll={{ x: 800, y: 300 }}
        loading={ loading }
        dataSource={ !!dataQuery && dataQuery.data }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" dataIndex="id" key="id" sorter="true" />

        <Column
          className="table-avatar-link"
          title="Cover"
          render={ item => {
            const imageUrl = item.cover;
            return (
              <Link to={ `/products/${ item.id }` }>
                { !imageUrl ?
                  <Avatar shape="square" size={ 50 } icon={ <FileImageOutlined /> } /> :
                  <Avatar shape="square" size={ 50 } src={ imageUrl } /> }
              </Link>
            )
          } } />
        <Column title="Title" key="title" render={ item => !item.title ? <Link to={ `/products/${ item.id }` } sorter="true"><Text type="secondary">Title is not filled</Text></Link> : <Link to={ `/products/${ item.id }` }>{ `${ item.title }` }</Link> } sorter={ true } />
        <Column title="Type" dataIndex="type" key="type" />
        <Column title="Top" render={ item => `${ item.is_top ? 'Yes' : 'No' }` } />
        <Column title="Status" dataIndex="status" key="status"
          filterMultiple={ false }
          filters={ [
            { text: "Draft", value: "draft" },
            { text: "Active", value: "active" },
            { text: "Block", value: "block" },
          ] }
        />
        <Column title="Action" className="text-align-center" render={
          item =>
            <div className="wrap-link-btn">
              <Link to={ `/products/${ item.id }` }><Button type="primary">Edit</Button></Link>

              <EntityRemoveButton
                nameEntity={ 'Product' }
                dataNameEntity={ `${ item.title }` }
                loading={ loadingProductDelete }
                deleteMutation={ setMutationProductDelete }
                variables={
                  {
                    id: item.id,
                  }
                }
              />
            </div>
        } />

      </Table>
    </div>
  );
};

export default StandProductsTable;

