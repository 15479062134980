import { useState } from "react";
import { useLazyQuery } from "@apollo/client";



const useQueryStands = (query = true, standName = false) => {

  const [ searhText, setSearchText ] = useState('');

  const [ getStands, { loading, error, data } ] = useLazyQuery(query, {
    variables: {
      text: searhText || standName,
      first: 20,
      page: 1
    },
    // notifyOnNetworkStatusChange: true,
    // fetchPolicy: "cache-and-network",
  });


  const onSearch = (value) => {


    if (value.length > 2 || value.length === 0)
    {
      setSearchText(value);
      getStands();
    }
  }


  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: data && data.stands.data,
    onSearch,
    getStands
  };
}

export default useQueryStands;
