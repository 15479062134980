import React from "react";
import { Form, Button } from "antd";
import { errorNotification } from "../../../result";
import SelectPackages from '../../select-packages';



const NormalPackageAddStandForm = (
  {
    dataQuery,
    loadingMutationAddStand,
    setMutationPackageAddStand,
    setModalVisible
  }
) => {

  const [ form ] = Form.useForm();


  const formSubmit = ({ values }) => {

    form.resetFields();
    setModalVisible(false)

    setMutationPackageAddStand({
      variables: {
        id: dataQuery.id,
        package_id: values.package_id
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };


  return (

    <Form
      form={ form }
      onFinish={ (values) => {
        formSubmit({ values });
      } }
      layout="vertical"
      className="add-stand-user"
    >


      <SelectPackages rulesСheck={ true } />

      <div className="d-flex justify-content-between">
        <Button loading={ loadingMutationAddStand } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Confirm</Button>
        <Button type="danger" onClick={ () => setModalVisible(false) } style={ { marginTop: "10px" } }>Cancel</Button>
      </div>
    </Form>
  )
}


export default NormalPackageAddStandForm;
