import React from "react";
import { Link } from "react-router-dom";
import { Table, Typography, Button } from "antd";
import mobileSize from "../../../../utils/mobile-size";

import "./taxonomies-table.scss"

const { Column } = Table;
const { Text } = Typography;


const TaxonomiesTable = ({ dataQuery, loadingQuery, pagination, handleTableChange }) => {

  return (
    <div className="holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        // style={ { whiteSpace: "nowrap" } }
        onChange={
          (pagination, filters, sorter, page) =>
            handleTableChange({ pagination, filters, sorter, page })
        }
        // scroll={{ x: 800, y: 300 }}
        loading={ loadingQuery }
        dataSource={ dataQuery }
        rowKey={ item => item.id }
        pagination={ pagination }
        sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" sorter="true" dataIndex="id" key="id" />
        <Column title="Title" sorter="true" key="title" render={
          item => !item.title ?
            <Link
              to={ {
                pathname: `/taxonomy/${ item.id }`,
                state: {
                  breadcrumbSlug: 'Product name is not filled'
                }
              } }
            >
              <Text type="secondary">Product name is not filled</Text>
            </Link> :
            <Link
              to={ {
                pathname: `/taxonomy/${ item.id }`,
                state: {
                  breadcrumbSlug: item.title
                }
              } }
            >
              { `${ item.title }` }
            </Link> } />
        <Column title="Type"
          key="TYPE"
          render={ item => `${ item.type === 'stand' ? 'showroom' : item.type }` }
          filterMultiple={ false }
          filters={ [
            { text: "Showroom", value: "stand" },
            { text: "Product", value: "product" },
          ] }
        />
        <Column title="Count term" render={ item => item.count_term === null ? '0' : item.count_term } />
        <Column title="Count linked to" render={ item => item.count_obj === null ? '0' : item.count_obj } />
        <Column title="Exhibition event" render={ item =>
          <Link
            to={ {
              pathname: `exhibition-events/${ item.exhibition_event.id }`,
              state: {
                breadcrumbSlug: item.exhibition_event.title
              }
            } }
          >
            { `${ item.exhibition_event.title }` }
          </Link> } />
        <Column title="Created" dataIndex="created_at" key="created_at" />
        <Column title="Action" render={ item =>
          <Link
            to={ {
              pathname: `/taxonomy/${ item.id }`,
              state: {
                breadcrumbSlug: item.title
              }
            } }
          >
            <Button type="primary">Edit</Button>
          </Link> } />
      </Table>
    </div>
  );
};

export default TaxonomiesTable;