import React, { useState } from "react";
import { Link, withRouter } from 'react-router-dom';
import { MenuUnfoldOutlined, MenuFoldOutlined, RightOutlined, HomeOutlined } from '@ant-design/icons';
import { Layout, Affix } from "antd";
import UserDropdown from "../user-dropdown";
import Routes from "../../routes";
import logo from "../../assets/desktop_logo.png";
import { Breadcrumb } from "antd";
import MainMenu from "../main-menu";

import "./main-layout.scss";



const { Content, Header, Footer, Sider } = Layout;

const MainLayout = withRouter(props => {


  const [ collapsed, setColapsed ] = useState(false);

  const { getMe } = props;
  const { location: { pathname } } = props;

  const pathSnippets = pathname.split('/').filter(i => i);
  const breadcrumbSlug = props.location.state?.breadcrumbSlug;

  if (breadcrumbSlug)
  {
    pathSnippets.pop()
  }

  const extraBreadcrumbItems = pathSnippets.map((_, index, item) => {
    const url = `/${ pathSnippets.slice(0, index + 1).join('/') }`;
    const itemName = item[ index ];

    return (
      <Breadcrumb.Item key={ url }>
        <Link to={ url }>{ itemName.replace(/-/g, ' ') }</Link>
      </Breadcrumb.Item>

    );
  });

  const entityTitleBreadcrumbItems = breadcrumbSlug && (
    <Breadcrumb.Item key={ breadcrumbSlug }>
      { breadcrumbSlug }
    </Breadcrumb.Item>
  );

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined style={ { fontSize: '16px' } } />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems, entityTitleBreadcrumbItems);


  const body = document.body;
  const displayWidth = document.documentElement.clientWidth;


  const toggle = () => {

    if (displayWidth < 992)
    {
      setColapsed(!collapsed);
      body.classList.toggle("nav-open");
    }
  };


  return (
    <Layout className="main-layout">
      <Affix>
        <Sider width={ 250 } className="main-nav"
          trigger={ null } collapsed={ collapsed }
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={ (collapsed, type) => {
            setColapsed(collapsed)
          } }
        >
          { React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          }) }
          <Link className="logo" to="/">
            <img src={ logo } alt="MP-CROWD" />
          </Link>
          <MainMenu menuToggle={ toggle } getMe={ getMe } />
        </Sider>
      </Affix>
      <Layout className="main-section">
        <Affix>
          <div>
            <Header className="header">
              { React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggle,
              }) }
              <UserDropdown getMe={ getMe } />
            </Header>
          </div>
        </Affix>
        { pathname !== "/" &&
          <Breadcrumb
            separator={ <RightOutlined style={ { fontSize: '11px' } } /> }
            className="main-breadcrumb">
            { breadcrumbItems }
          </Breadcrumb> }

        <Content className="main-content">
          <Routes />
        </Content>
        <Footer style={ { textAlign: "center" } }>© Leipziger Messe 2020. Alle Rechte vorbehalten</Footer>
      </Layout>
    </Layout>
  );
});

export default MainLayout;
