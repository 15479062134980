import React from "react";
import { Layout } from 'antd';
import PackageRequestsResolvedTable from "./package-requests-resolved-table";
import useQueryPackageRequestsResolved from './hooks/use-query-package-requests-resolved';


import { GET_PACKAGE_REQUESTS } from "../../../graphql/query/packages-gql";

import './package-requests-resolved.scss';







const { Sider, Content } = Layout;



const PackageRequestsResolved = () => {


  const {
    loadingQuery,
    errorQuery,
    dataQuery,
    pagination,
    handleTableChange,
    setStandId,
    setPackageId,
    setUserId,
    setModeratorId

  } = useQueryPackageRequestsResolved(GET_PACKAGE_REQUESTS);


  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Package Requests Resolved</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }></Sider>
      </Layout>
      <PackageRequestsResolvedTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        errorQuery={ errorQuery }
        handleTableChange={ handleTableChange }
        setStandId={ setStandId }
        setPackageId={ setPackageId }
        setUserId={ setUserId }
        setModeratorId={ setModeratorId }
      />
    </>
  );
};

export default PackageRequestsResolved;
