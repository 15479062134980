// import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
// import convertSortName from './../../../utils/convert-sort-name';


const useQueryExbnEvnts = (query) => {


  // const [ searchVal, setSearchVal ] = useState("");
  // const [ status, setStatus ] = useState([]);
  // const [ role, setRole ] = useState([]);
  // const [ order, setOrder ] = useState({
  //   orderBy: {
  //     sortBy: 'id',
  //     sortOrder: 'ASC'
  //   }
  // });

  let history = useHistory();
  const { numPage } = useParams();


  const { loading, error, data } = useQuery(query, {
    variables: {
      // filter: {
      //   searchText: searchVal,
      //   status,
      //   role
      // },
      // ...order,
      first: 10,
      page: +numPage ? +numPage : 1
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { users = {} } = !loading ? data : {};
  const { paginatorInfo = {} } = !loading ? users : {};
  const { total, perPage, currentPage } = paginatorInfo;

  const pagination = {
    current: currentPage,
    pageSize: !loading ? perPage : loading,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };

  const routePage = `/exhibition-events/`;

  const handleTableChange = (pagination, filters, sorter, page) => {

    // setStatus(filters.status)
    // setRole(filters.role)

    // const {
    //   order,
    //   columnKey
    // } = sorter;

    // setOrder({
    //   orderBy: {
    //     sortBy: columnKey,
    //     sortOrder: convertSortName(order)
    //   }
    // });

    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: data && data.eeAll,
    // setSearchVal,
    routePage,
    history,
    pagination,
    handleTableChange
  }
};

export default useQueryExbnEvnts;
