import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
import { GET_PACKAGE_RULES } from "../../../../graphql/query/packages-gql";



const useMutationPackageRuleDelete = (mutation, dataQuery) => {

  const { numPage } = useParams();

  const [ _setMutationPackageRuleDelete, { loading } ] = useMutation(mutation,
    {
      update(cache, { data }) {

        const {
          packageRulesDelete: {
            label,
            message
          }
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },

      refetchQueries: [
        {
          query: GET_PACKAGE_RULES,
          variables: {
            // first: 10,
            page: +numPage ? +numPage : 1
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: "cache-and-network",
        },
      ]
    });

  return {
    _setMutationPackageRuleDelete,
    loadingPackageRuleDelete: loading,
  }
};

export default useMutationPackageRuleDelete;