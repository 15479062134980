import React from "react";
import { Link } from "react-router-dom";
import StandUserTable from "./stand-users-table";
import { Layout, Typography } from 'antd';
import EditModal from '../../service/edit-modal';
import AddStandUserForm from '../../forms/showroom-forms/add-stand-user-form';
import packageRules from '../../../utils/package-rules';

import './stand-users.scss';
const { Sider, Content } = Layout;



const StandUser = ({ dataQueryStand, loadingQuery }) => {

  const { packageRuleForStand } = packageRules;
  const { permittedNumbersStand, activePackageRule, disabledEntityFromRules } = packageRuleForStand(dataQueryStand, 'team_members_amount', 'exhibitors');



  return (
    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Showroom users</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          { dataQueryStand.package?.package_rules &&
            (permittedNumbersStand || !activePackageRule) ?
            <span>
              { permittedNumbersStand && 'Maximum number of team members reached of this package' }
              { (permittedNumbersStand && !activePackageRule) && ' and also ' }
              { !activePackageRule && 'This action is prohibited in the rules of this package:' }

              <Link
                className="form-link"
                to={ {
                  pathname: `/packages/${ dataQueryStand.package?.id }`,
                  state: {
                    breadcrumbSlug: dataQueryStand.package?.title
                  }
                } }
              >
                <Typography.Text type="success">
                  <strong> "{ dataQueryStand.package?.title }" </strong>
                </Typography.Text>
              </Link>
            </span> :
            ''
          }

          <EditModal valueBtn={ 'Add User' }
            titleModal={ 'Add User Stand' }
            disabled={ disabledEntityFromRules }
          >
            <AddStandUserForm dataQueryStand={ dataQueryStand } />
          </EditModal>
        </Sider>
      </Layout>
      <StandUserTable
        dataQuery={ dataQueryStand }
        loadingQuery={ loadingQuery }
      />
    </>
  );
};

export default StandUser;
