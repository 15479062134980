import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
// import convertSortName from '../../../../utils/convert-sort-name';


const useQueryPackageRules = (query, pckgId) => {

  const routePage = `/packages/${ pckgId }/rules/`;


  const [ pageSize, setPageSize ] = useState(10);

  // const [ order, setOrder ] = useState({
  //   orderBy: [
  //     {
  //       column: "ID",
  //       order: 'ASC'
  //     }
  //   ]
  // });

  let history = useHistory();
  const { numPage } = useParams();


  const { loading, error, data } = useQuery(query, {
    variables: {
      package_id: pckgId,
      // ...order,
      first: pageSize,
      page: +numPage ? +numPage : 1,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });



  const packageRules = !loading && data?.packageRules;
  const { paginatorInfo } = !loading && packageRules;
  const { total, currentPage } = !loading && paginatorInfo;


  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: 1,
    defaultPageSize: 10,
  };


  const handleTableChange = (pagination, filters, sorter, page) => {

    setPageSize(pagination.pageSize)

    // const {
    //   order,
    //   columnKey
    // } = sorter;


    // setOrder({
    //   orderBy: [
    //     {
    //       column: !!columnKey ? columnKey.toUpperCase() : 'ID',
    //       order: !!convertSortName(order) ? convertSortName(order) : 'ASC'
    //     }
    //   ]
    // });


    if (pagination.current === 1)
    {
      history.push(routePage);
    } else
    {
      history.push(`${ routePage }page-${ pagination.current }`);
    }
  }

  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: packageRules.data,
    pagination,
    handleTableChange
  }
};

export default useQueryPackageRules;
