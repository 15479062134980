import React from "react";
import UserAccountForm from '../../../forms/user-form/user-account-form';

const AccountTabSettings = ({ userId, getUser, loadingUser, errorUser }) => {

  return (
    <>
      <UserAccountForm
        userId={ userId }
        loadingUser={ loadingUser }
        getUser={ getUser }
        errorUser={ errorUser } />
    </>
  );
};

export default AccountTabSettings;