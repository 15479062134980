import React from "react";
import Packages from '../components/packages/packages-all';



const PackagesPage = () => {

  return (
    <div className="page-holder bg-white">
      <Packages />
    </div>
  );
};

export default PackagesPage;
