// import React from "react";
import { notification } from "antd";

// import Icons from "../../icons";


const successNotification = (props) => {

    let {
        title,
        description,
        duration = 4,
    } = props;

    (title === null) && (title = 'Field Title: invalid value');
    (description === null) && (title = 'Field Description: invalid value');


    notification[ 'success' ]({
        message: title,
        description: description,
        duration: duration,
        className: "success-notification",
        // icon: <Icons.ActionStatus type="success"/>,
        // closeIcon: <Icons.Close />,
    });

};

export default successNotification;