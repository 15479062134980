import React from "react";
import NormalTranslationKeysForm from './normal-translation-keys-form';
import useMutationKeyCreate from './hooks/use-mutation-key-create';
import useMutationKeyUpdate from './hooks/use-mutation-key-update';


import {
  TRNS_KEY_CREATE,
  TRNS_KEY_UPDATE
} from '../../../../graphql/mutation/languages-gql'


import './translation-keys-form.scss';



const TranslationKeysForm = ({ dataQuery, loadingQuery, setModalVisible }) => {

  const {
    _setMutationVariableCreate,
    loadingMutationCreate,

  } = useMutationKeyCreate(TRNS_KEY_CREATE, dataQuery);

  const {
    _setMutationVariableUpdate,
    loadingMutationUpdate,

  } = useMutationKeyUpdate(TRNS_KEY_UPDATE, dataQuery);


  return (
    <NormalTranslationKeysForm
      dataQuery={ dataQuery }
      loadingQuery={ loadingQuery }
      loadingMutationCreate={ loadingMutationCreate }
      loadingMutationUpdate={ loadingMutationUpdate }
      setMutationVariableCreate={ _setMutationVariableCreate }
      setMutationVariableUpdate={ _setMutationVariableUpdate }
      setModalVisible={setModalVisible}
    />
  );
};


export default TranslationKeysForm;
