import { useState } from "react";
import { useQuery } from "@apollo/client";



const useQueryLanguages = (query) => {

  // const [ order, setOrder ] = useState({
  //   orderBy: [
  //     {
  //       column: 'ID',
  //       order: 'ASC'
  //     }
  //   ]
  // });

  const [ status, setStatus ] = useState(null);
  const { loading, error, data } = useQuery(query, {
    variables: (status !== null) ? { status } : {},
    // notifyOnNetworkStatusChange: true,
    // fetchPolicy: "cache-and-network",
  });

  const handleTableChange = (pagination, filter, sorter) => {

    let filterVal = filter.status !== null ? filter.status[ 0 ] : null;
    setStatus(filterVal)
  }


  return {
    loadingQuery: loading,
    errorQuery: error,
    dataQuery: !!data && data.languages,
    handleTableChange
    // setSearchVal,
    // routePage,
    // history,
  }
};

export default useQueryLanguages;
