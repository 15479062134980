import React from "react";
import NormalDownloadPageForm from './normal-company-download-form';


const CompanyDownloadForm = ({ dataQueryStand, loadingQueryStand }) => {


  return (
    <>
      <NormalDownloadPageForm
        dataQueryStand={ dataQueryStand }
        loadingQueryStand={ loadingQueryStand }
      />
    </>
  );
};


export default CompanyDownloadForm;
