import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginForm from "../../components/forms/login-form";
import ForgotPasswordForm from "../../components/forms/forgot-password-form";


import './authorization-page.scss';


const AuthorizationPage = ({ setAuthToken }) => {
    return (
        <main className="authorization-page">
            <Switch>
                <Route path="/forgot-password/" render={ () => <ForgotPasswordForm /> } exact />
                <Route render={ () => <LoginForm /> } />
            </Switch>
        </main>
    );
};

export default AuthorizationPage;