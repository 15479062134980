import { gql } from "@apollo/client";


export const GET_TERMS = gql`
query GetTerms(
$text: String,
$where: QueryTermsWhereWhereConditions,
$orderBy: [QueryTermsOrderByOrderByClause!],
){
  terms(
  text: $text,
  where: $where,
  orderBy: $orderBy,
  ){
    id
    title
    taxonomy_id
    sort_number
    count_obj
    taxonomy {
      title
    }
  }
}
`;


