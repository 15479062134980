import { useQuery } from "@apollo/client";



const useQueryPackageRuleConstants = (query) => {

  const { loading, error, data } = useQuery(query, {
    variables: {
      // first: 20,
      page: 1
    },
  });


  return {
    loadingQueryRuleConstants: loading,
    errorQueryRuleConstants: error,
    dataQueryRuleConstants: data && data.packageRuleConstants.data,
  };
}

export default useQueryPackageRuleConstants;
