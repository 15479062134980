import React from "react";
import { Form, Button, Input, Spin } from "antd";
import { errorNotification } from "../../../result";


const { TextArea } = Input;

const NormalPackageRequest = ({
  reqstId,
  requestStatus,
  setModalVisible,
  dataQuery,
  loadingQuery,
  setMutationPackageRequest,
  loadingMutationPackageRequest
}) => {


  const [ form ] = Form.useForm();

  const formSubmit = ({ values }) => {

    form.resetFields();

    setModalVisible(false);

    setMutationPackageRequest({
      variables: {
        id: reqstId,
        status: requestStatus,
        message_answer: values.message_answer
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };


  return (

    <div className="form-container lg">
      { loadingQuery ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          className=""
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
        >
          <Form.Item
            initialValue={ dataQuery ? dataQuery.description : '' }
            name="message_answer"
            label="Reason text:"
          >
            <TextArea
              maxLength={ 300 }
              placeholder="Enter Message Answer"
              autoSize={ { minRows: 3, maxRows: 5 } }
              showCount={
                { formatter: ({ count, maxLength }) => maxLength - count }
              }
            />
          </Form.Item>

          <div className="d-flex justify-content-between">
            <Button loading={ loadingMutationPackageRequest } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Confirm</Button>
            <Button type="danger" onClick={ () => setModalVisible(false) } style={ { marginTop: "10px" } }>Cancel</Button>
          </div>

        </Form>
      )
      }
    </div>
  );
};


export default NormalPackageRequest;
