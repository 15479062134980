import React from "react";
import { Link } from 'react-router-dom';
import { Table, Typography } from "antd";
import mobileSize from "../../../../utils/mobile-size";
import arrSortDescend from '../../../../utils/arr-sort-descend';

import "./package-requests-stand-table.scss"


const { Column } = Table;


const PackageRequestsStandTable = ({
  dataQuery,
  loadingQuery,
}) => {


  const arrDescend = dataQuery && arrSortDescend(dataQuery.package_request);

  return (
    <div className="_holder-table">
      <Table size={ mobileSize('small') }
        className="main-table"
        // style={ { whiteSpace: "nowrap" } }
        loading={ loadingQuery }
        dataSource={ arrDescend }
        rowKey={ item => item.id }
      // sortDirections={ [ 'ascend', 'descend', 'ascend' ] }
      >
        <Column title="ID" dataIndex="id" key="id" />

        <Column title="Package" render={ item =>
          <Link
            to={ {
              pathname: `/packages/${ item.package.id }`,
              state: {
                breadcrumbSlug: item.package.title
              }
            } }
          >
            { `${ item.package.title }` }
          </Link> }
        />

        <Column title="User" render={
          item =>
            <>
              { !!item.user?.id ?
                <Link
                  to={ {
                    pathname: `/users/id-${ item.user?.id }-${ item.user?.name }-${ item.user?.surname }`.toLowerCase(),
                  } }
                >
                  { item.user?.name } { item.user?.surname }
                </Link>
                :
                'User not provided' }
            </>
        }
        />

        <Column title="Moderator" render={
          item =>
            <>
              { !!item.moderator?.id ?
                <Link
                  to={ {
                    pathname: `/users/id-${ item.moderator?.id }-${ item.moderator?.name }-${ item.moderator?.surname }`.toLowerCase(),
                  } }
                >
                  { item.moderator?.name } { item.moderator?.surname }
                </Link>
                :
                'The moderator has not yet processed the request' }
            </>
        }
        />

        <Column title="Description" dataIndex="message_answer" key="message_answer" />

        <Column title="Status" render={ item =>
          item?.status === 'approved' ?
            <Typography.Text type="success">{ item.status }</Typography.Text> :

            item?.status === 'declined' ?
              <Typography.Text type="danger">{ item.status }</Typography.Text> :
              item.status
        } />

        <Column title="Created" dataIndex="created_at" key="created_at" />
      </Table>
    </div>
  );
};

export default PackageRequestsStandTable;

