import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";


const useMutationUpdateUser = (mutation) => {


  const [ _setMutationVariableUpdate,
    { loading: loadingMutationUpdate,
      error: errorMutationUpdate, data } ] = useMutation(mutation,
        {
          update(_, { data }) {
            const {
              userUpdate: {
                label,
                message,
              }
            } = data;

            successNotification({
              title: label.toUpperCase(),
              description: message
            });
          },
        });


  return {
    _setMutationVariableUpdate,
    loadingMutationUpdate,
    errorMutationUpdate,
    dataMutationUpdate: data && data.nameMutatiom
  }
};

export default useMutationUpdateUser;