import React from "react";
import UserTable from "./user-table";
import SearchForm from "../forms/search-form"
import { Layout } from 'antd';
import { GET_USERS } from "../../graphql/query/user-gql";
import useQueryUsers from './hooks/use-query-users';
const { Sider, Content } = Layout;

const Users = () => {

  const {
    loadingQuery,
    dataQuery,
    history,
    setSearchVal,
    routePage,
    pagination,
    handleTableChange
  } = useQueryUsers(GET_USERS);

  return (

    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Users</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>
      <UserTable
        pagination={ pagination }
        users={ dataQuery }
        loading={ loadingQuery }
        handleTableChange={ handleTableChange }
      />
    </>
  );
};

export default Users;
