import React from "react";
import ExhibitionEvents from "../components/exhibition-events"


const ExhibitionEventsPage = () => {


  return (
    <div className="page-holder bg-white">
      <ExhibitionEvents />
    </div>
  );
};

export default ExhibitionEventsPage;
