import React from "react";
import RequestHandler from "../../request-handler"
import { Form, Select } from "antd";

//Hooks
import useQueryPackages from './hooks/use-query-packages';

//Query
import { GET_PACKAGES_FOR_SELECT } from '../../../graphql/query/packages-gql';

//CSS
import './select-packages.scss';



const SelectPackages = ({
  disabledSelect = false,
  rulesСheck = false,
  initVal = [],
  selectMode = '',
  fieldName = 'package_id',
  selectLabel = 'Package'
}) => {

  const {
    loadingQuery,
    errorQuery,
    dataQuery,
  } = useQueryPackages(GET_PACKAGES_FOR_SELECT);


  const { Option } = Select;

  const selectSearchField = dataQuery && (
    dataQuery.map((item) => {
      return (
        <Option key={ item.id } value={ item.id }>
          { item.title }
        </Option>
      )
    })
  );


  return (
    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQuery }
    >
      <Form.Item

        name={ fieldName }
        label={ selectLabel }
        initialValue={ initVal }
        rules={ rulesСheck && [ { required: true, message: 'Please choose package for showroom' } ] }
      >
        <Select
          mode={ selectMode }
          className="select-input-space-between"
          dropdownClassName="select-dropdown-space-between"
          disabled={ disabledSelect }
          showSearch
          placeholder="Choose package for showroom"
          optionFilterProp="children"
        >
          { selectSearchField }
        </Select>
      </Form.Item>
    </RequestHandler>
  )
};

export default SelectPackages;