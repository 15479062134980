import React from 'react';
import TranslationKeyForm from '../components/forms/localization-forms/translations-form';


const LocalizationTrnslnsPage = () => {

    return (
        <div className="page-holder bg-white">
            <TranslationKeyForm />
        </div>
    );
};

export default LocalizationTrnslnsPage;