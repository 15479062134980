import React from "react";
import { MailOutlined } from '@ant-design/icons';
import { Form, Button, Input } from "antd";
import { errorNotification } from "../../result";

import './forgot-password-form.scss';
import logo from "../../..//assets/desktop_logo.png";


const error = undefined;


const submitForm = ({ values, forgotRequest }) => {

    if (!!values)
    {
        forgotRequest(
            {
                variables: {
                    input: {
                        email: values.email,
                    }
                }
            }
        );
    } else
    {
        errorNotification(error)
    }

}

const NormalForgotPasswordForm = (props) => {

    const [ form ] = Form.useForm();

    const {
        sendEmail,
        loading,
        forgotRequest

    } = props;

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <div className="form-holder">
            <div className="logo-img">
                <img src={ logo } alt="logo" />
            </div>
            <h2>Forgot password</h2>
            <>
                { sendEmail ?
                    <div className="forgot-form-message">
                        <p>We sent you a password recovery link in your email!</p>
                    </div> :
                    <Form
                        className="forgot-password-form"
                        form={ form }
                        onFinish={
                            (values) => {

                                submitForm({
                                    values,
                                    form,
                                    forgotRequest
                                })
                            }
                        }
                    >
                        <Form.Item
                            label={ <span className="site-label-text-color">Email</span> }
                            { ...formItemLayout }
                            name="email"
                            rules={ [
                                { required: true, message: 'Please input your username!' },
                                {
                                    type: "email"
                                }
                            ]
                            }>

                            <Input
                                prefix={ <MailOutlined className="form-icon" /> }
                                placeholder="Email"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="forgot-submit"
                                loading={ loading }>
                                Send Forgot Link
                             </Button>
                        </Form.Item>
                    </Form>
                }
            </>
            {/* <div className="sign-link-wrap">
                <span className="site-label-text-color">New user?</span>
                <Link to="/registration/" className="company-color">Sign up</Link>
            </div> */}
        </div>
    );
};

const ForgotPasswordForm = (props) => {

    // const [ sendEmail, setSendEmail ] = useState(false);

    // const [ forgotRequest, { loading } ] = useMutation(USER_FORGOT_PASSWORD,
    //     {
    //         update(cache, { data: { userForgotPassword } }) {

    //             const { status } = userForgotPassword;

    //             if (status === "EMAIL_SENT")
    //             {
    //                 setSendEmail(true);
    //             }

    //         },
    //         onError(error) {
    //             errorNotification(error)
    //         }
    //     }
    // );

    return (
        <NormalForgotPasswordForm
            { ...props }
        // forgotRequest={ forgotRequest }
        // sendEmail={ sendEmail }
        // loading={ loading }
        />
    );
};

export default ForgotPasswordForm;