import React from "react";
import PackageRequests from '../components/packages/package-requests';



const PackageRequestsPage = () => {

  return (
    <div className="page-holder bg-white">
      <PackageRequests />
    </div>
  );
};

export default PackageRequestsPage;
