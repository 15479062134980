import React, { useState, useEffect } from "react";
import { Form, Button, Input, Radio, Spin, Typography } from "antd";
import { errorNotification } from "../../../result";
import metaFields from './../../../../utils/meta-fields';
import AvatarUpload from './../../../upload/avatar/avatar-upload';
import TaxanomyInput from '../../taxonomy-input/index';
import useMutationMetaUpload from './hooks/use-mutation-meta-upload';

import { USER_META_UPLOAD } from '../../../../graphql/mutation/user-gql'

const { TextArea } = Input;


const NormalProfileForm = ({ getUser,
  setMutationVariable,
  loadingUser,
  loadingMutationMetaSet,

}) => {


  const {
    _setMutationVariableUpload,
    loadingMutation,

  } = useMutationMetaUpload(USER_META_UPLOAD, getUser);



  const [ form ] = Form.useForm();


  const { getValue, normalize } = metaFields;
  const meta = normalize(getUser.meta_fields);

  let metaUserOrStand;

  let conditionUserStand = getUser && (getUser.role === 'exhibitor' && getUser.stand.length !== 0)

  if (conditionUserStand)
  {
    metaUserOrStand = normalize(getUser && getUser.stand[ 0 ].meta_fields);
  } else
  {
    metaUserOrStand = normalize(getUser && getUser.meta_fields);
  }

  const metaUserTaxonomiesId = normalize(
    getUser && getUser.exhibition_event.length !== 0 ?
      getUser.exhibition_event[ 0 ].meta_fields : false
  )


  const logo = conditionUserStand ? getUser.stand[ 0 ].logo : getValue(meta, "company_logo");


  const arrUserTaxonomiesId = getValue(metaUserTaxonomiesId, "user_interest_taxonomies")


  const defaultRadioVal = getValue(meta, "company_member_flag") === '' ? '0' : getValue(meta, "company_member_flag");


  const [ radioValue, setRadioValue ] = useState(defaultRadioVal);

  useEffect(() => {
    setRadioValue(defaultRadioVal)
  }, [ defaultRadioVal ]);



  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };


  form.setFieldsValue({
    "company_logo-string-unset": logo,
  });




  const formSubmit = ({ values }) => {

    let { metaValues } = metaFields.parseForm(values);
    metaValues = metaFields.filterEmpty(metaValues, getUser.meta_fields);

    setMutationVariable({
      variables: {
        userMetaSetUserId: getUser.id,
        userMetaSetInput: [ ...metaValues ]
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });
  };



  return (
    <div>
      { loadingUser ? (
        <div className="block-loader h-300px">
          <Spin size="small" />
        </div>
      ) : (
        <Form
          form={ form }
          onFinish={ (values) => {
            formSubmit({ values });
          } }
          layout="vertical"
          className="profile-form"
        >
          <Typography.Link className="text-decorator">
            <h6>Profile info</h6>
            <div className="separator"></div>
          </Typography.Link>

          <Form.Item className="form-group">
            <Form.Item
              initialValue={ getValue(meta, "profile_function") }
              name="profile_function-string"
              label="Function"
            >
              <Input placeholder="Manager" />
            </Form.Item>

            <Form.Item
              initialValue={ getValue(meta, "profile_email") }
              name="profile_email-string"
              label="Contact email"
              rules={ [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail',
                },
                {
                  required: true,
                  message: 'Please input your Contact email'
                }
              ] }
            >
              <Input placeholder="someone@mail.com" />
            </Form.Item>
          </Form.Item>

          <Form.Item className="form-group">
            <Form.Item
              initialValue={ getValue(meta, "profile_mobile_phone") }
              name="profile_mobile_phone-string"
              label="Mobile phone"
              rules={ [
                {
                  // required: true,
                  message: 'Please input your Mobile phone number!',
                },
              ] }
            >
              <Input placeholder="+0 000 000 00 00" />
            </Form.Item>

            <Form.Item
              initialValue={ getValue(meta, "profile_phone") }
              name="profile_phone-string"
              label="Phone"
            >
              <Input placeholder="+0 000 000 00 00" />
            </Form.Item>
          </Form.Item>

          <Typography.Link className="text-decorator">
            <h6>Company info</h6>
            <div className="separator"></div>
          </Typography.Link>

          { !conditionUserStand && (
            <Form.Item
              initialValue={ defaultRadioVal }
              name="company_member_flag-string"
              className="form-group"
              style={ { margin: "10px 0" } }>
              <Radio.Group onChange={ radioOnChange }>
                <Radio value="1">Company member</Radio>
                <Radio value="0">Private person</Radio>
              </Radio.Group>
            </Form.Item>
          ) }

          { (!!+radioValue || conditionUserStand) &&
            <>
              <Form.Item className="form-group">

                <Form.Item
                  initialValue={ logo }
                  label="Company logo"
                  name="company_logo-string-unset"
                  rules={ [
                    {
                      required: true,
                      message: 'Please upload your Logo!',
                    },
                  ] }
                >
                  <AvatarUpload
                    disabled={ conditionUserStand }
                    size={ 140 }
                    uploadMutation={ _setMutationVariableUpload }
                    image={ logo }
                    loading={ loadingMutation }
                    variables={
                      {
                        userId: getUser.id,
                        userKey: "company_logo",
                      }
                    }
                    extraClass={ "contain" }
                    uploadButtonText={ "Upload Logo" }
                    shape={ "square" }
                  />
                </Form.Item>

                <br />

                <Form.Item
                  initialValue={ conditionUserStand ? getUser.stand[ 0 ].title : getValue(meta, "company_name") }
                  name={ conditionUserStand ? 'title-string' : 'company_name-string' }
                  label="Company name">
                  <Input disabled={ conditionUserStand } placeholder="Enter Company name" />
                </Form.Item>

                <Form.Item
                  initialValue={ getValue(metaUserOrStand, "company_site_url") }
                  name="company_site_url-string"
                  label="Website">
                  <Input disabled={ conditionUserStand } placeholder="Enter Website" />
                </Form.Item>

                <Form.Item
                  initialValue={ getValue(metaUserOrStand, "company_address") }
                  name="company_address-string"
                  label="Address">
                  <Input disabled={ conditionUserStand } placeholder="Enter Address" />
                </Form.Item>

                <Form.Item
                  initialValue={ getValue(metaUserOrStand, "company_city") }
                  name="company_city-string"
                  label="City">
                  <Input disabled={ conditionUserStand } placeholder="Enter City" />
                </Form.Item>

                <Form.Item
                  initialValue={ getValue(metaUserOrStand, "company_country") }
                  name="company_country-string"
                  label="Country">
                  <Input disabled={ conditionUserStand } placeholder="Enter Country" />
                </Form.Item>

                <Form.Item
                  initialValue={ getValue(metaUserOrStand, "company_postcode") }
                  name="company_postcode-string"
                  label="Postcode">
                  <Input disabled={ conditionUserStand } placeholder="Enter Postcode" />
                </Form.Item>
              </Form.Item>


              <Typography.Link className="text-decorator">
                <h6>Exhibition info</h6>
                <div className="separator"></div>
              </Typography.Link>

              { arrUserTaxonomiesId.length !== 0 && getUser.role !== 'editor' && getUser.role !== 'admin' ?
                arrUserTaxonomiesId.map((id) =>
                  <TaxanomyInput
                    key={ id }
                    id={ id }
                    name={ `user_interest_${ id }-string--interest_taxonomy` }
                    initialValue={ getValue(meta, `user_interest_${ id }`) === '' ? [] : getValue(meta, `user_interest_${ id }`) }
                    label="Interesting in"
                    placeholder="Please select interested"
                  />

                ) : false }

              <Form.Item
                initialValue={ getValue(meta, "looking_for") }
                name="looking_for-string"
                label="Looking for"
              // rules={
              //   [
              //     { max: 179, message: 'Field must be maximum 180 characters.' },
              //   ]
              // }
              >
                <TextArea
                  maxLength={ 180 }
                  placeholder="Enter Looking for"
                  autoSize={ { minRows: 3, maxRows: 5 } }
                  showCount={
                    { formatter: ({ count, maxLength }) => maxLength - count }
                  }
                />
              </Form.Item>
            </>
          }

          <Button loading={ loadingMutationMetaSet } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
        </Form>
      )
      }
    </div>
  );
};

export default NormalProfileForm;
