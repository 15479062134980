import React, { useState } from "react";
import { Table, Form, Button } from "antd";
const { Column } = Table;



const TableColumnSelectFilter = ({
  setFilter,
  columnTitle = 'Your Column Title',
  columnKey = 'Your Column Key',
  columnRender = () => { },
  columnFormOnFinish = () => { },
  columnSelectFilter = () => { }
}) => {


  const [ form ] = Form.useForm();
  const [ isActive, setActive ] = useState(false);
  const onFilterDropdownVisibleChange = (visible) => {
    setActive(visible);
  }


  return (
    <Column title={ columnTitle } key={ columnKey } render={ columnRender }
      filterDropdownVisible={ isActive }
      onFilterDropdownVisibleChange={
        (visible) => onFilterDropdownVisibleChange(visible)
      }
      filterDropdown={
        <div className="custom-drop-filter">
          <Form
            className=""
            form={ form }
            onFinish={ (values) => {
              columnFormOnFinish(values, setFilter)
            } }
            layout="vertical">

            { columnSelectFilter }

            <div className="d-flex justify-content-between">
              <Button className="" type="danger"
                style={ { marginTop: "10px" } }
                onClick={
                  () => {
                    setActive(!isActive);
                    setFilter(undefined);
                    form.resetFields();
                  } }> Reset </Button>

              <Button className="" type="primary" onClick={
                () => {
                  setActive(!isActive);
                }
              }
                htmlType="submit"
                style={ { marginTop: "10px" } }>Search</Button>
            </div>
          </Form>
        </div>
      }
    />
  )
}

export default TableColumnSelectFilter;