import React from "react";
import { Form, Input, Typography } from "antd";
import metaFields from '../../../../utils/meta-fields';
// import link from '../../../../utils/link';
import UploadFile from '../../../upload/upload-files';

import './product-info-form.scss';




const ProductInfoForm = ({
  dataQuery,
  setMutationProductUpload,
  loadingMutationProductUpload,
  setMutationProductMetaDelete,
}) => {


  const { getValue, normalize } = metaFields;
  const meta = normalize(dataQuery.meta_fields);



  return (

    <>
      <Typography.Link className="text-decorator">
        <h6>Product info</h6>
        <div className="separator"></div>
      </Typography.Link>

      <Form.Item className="form-group">

        <div>
          <Form.Item
            name="product_link-link"
            label="Link to product"
            initialValue={ getValue(meta, "product_link") }
          // rules={ [
          //   {
          //     pattern: link.regExp,
          //     message: 'Please insert valid product link'
          //   }
          // ]}
          >
            <Input placeholder="Enter your product link" />
          </Form.Item>

          { dataQuery.type !== 'product' &&
            (<Form.Item
              name="product_audio_1-audio-unset-attachments"
              label="Add Audio file"
              initialValue={ getValue(meta, "product_audio_1") }
            >
              <UploadFile
                // size={ 140 }
                url={ getValue(meta, "product_audio_1") }
                loading={ loadingMutationProductUpload }
                uploadMutation={ setMutationProductUpload }
                uploadButtonText={ "Add audio file" }
                accept={ 'audio/*' }
                variables={
                  {
                    input: {
                      product_id: dataQuery.id,
                      meta_key: "product_audio_1",
                      meta_group: "attachments",
                      meta_type: "audio"
                    }
                  }
                }
                deleteMutation={ setMutationProductMetaDelete }
                deleteVariables={
                  {
                    product_id: dataQuery.id,
                    meta_key: "product_audio_1"
                  }
                }
              />
            </Form.Item>)
          }

          <Form.Item
            name="product_file_1-file-unset-attachments"
            label="Add PDF file"
            initialValue={ getValue(meta, "product_file_1") }
          >
            <UploadFile
              // size={ 140 }
              url={ getValue(meta, "product_file_1") }
              loading={ loadingMutationProductUpload }
              uploadMutation={ setMutationProductUpload }
              uploadButtonText={ "Add PDF file" }
              accept={ 'application/pdf' }
              variables={
                {
                  input: {
                    product_id: dataQuery.id,
                    meta_key: "product_file_1",
                    meta_group: "attachments",
                    meta_type: "file"
                  }
                }
              }
              deleteMutation={ setMutationProductMetaDelete }
              deleteVariables={
                {
                  product_id: dataQuery.id,
                  meta_key: "product_file_1"
                }
              }
            />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
}

export default ProductInfoForm;
