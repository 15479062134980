import React from 'react'
import { Input } from 'antd';


import "./search-form.scss";

const { Search } = Input;

const SearchForm = ({ setSearchVal, history, routePage }) => {

  return (

    <Search className="search-form"
      placeholder="input search text"
      onChange={
        ({ target: { value } }) => {
          setSearchVal(value);
          history.push(routePage)
        }
      }
    />
  )
};

export default SearchForm;


