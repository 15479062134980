import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { successNotification } from "../../../../result";

const useMutationPackageCreateUpdate = (mutation, dataQuery) => {

  const history = useHistory();

  const [ _setMutationPackageCreateUpdate,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            packageCreateOrUpdate: {
              label,
              message,
            }
          } = data;

          history.push(`/packages/`);


          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationPackageCreateUpdate,
    loadingMutationPackageCreateUpdate: loading,
  }
};

export default useMutationPackageCreateUpdate;