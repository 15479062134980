import React from 'react';
import Icon from '@ant-design/icons';


const EyeSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M41.7,23.6c-6.1-6.3-12.3-9.2-18.6-8.8c-9.3,0.7-15.1,8.6-15.4,8.9c-0.3,0.4-0.2,0.9,0.1,1.2c5.2,5.9,10.8,8.9,16.7,8.9
		c0.1,0,0.2,0,0.4,0c9.8-0.2,16.7-8.6,16.9-9C42,24.5,42,24,41.7,23.6z M9.8,24.3c1.1-1.3,3.8-4.1,7.7-5.9c-1.3,1.6-2.1,3.6-2.1,5.9
		c0,2.4,0.9,4.5,2.4,6.2C15,29.1,12.4,27.1,9.8,24.3z M24.7,31.5c-4,0-7.3-3.3-7.3-7.3c0-4,3.3-7.3,7.3-7.3c4,0,7.3,3.3,7.3,7.3
		C32.1,28.2,28.8,31.5,24.7,31.5z M32,30c1.3-1.6,2.1-3.6,2.1-5.8c0-2-0.6-3.8-1.7-5.3c2.4,1.3,4.8,3.1,7.2,5.4
		C38.4,25.6,35.7,28.2,32,30z"/>
        <path d="M28.2,23.1c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.4,0,0.7,0.1,0.9,0.4c-0.7-1-1.8-1.7-3.1-1.7
		c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7c0-0.5-0.1-1-0.3-1.4C28.2,22.9,28.2,23,28.2,23.1z"/>
    </svg>
);

const EyeIcon = props => {
    return <Icon component={ EyeSvg } />;

};


export default EyeIcon;