const hexToRgb = (hex, type = "string") => {

  if (!hex)
  {
    return false
  }

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (type === "string")
  {
    return `${ parseInt(result[ 1 ], 16) }, ${ parseInt(result[ 2 ], 16) }, ${ parseInt(result[ 3 ], 16) }`;
  }

  return result ? {
    r: parseInt(result[ 1 ], 16),
    g: parseInt(result[ 2 ], 16),
    b: parseInt(result[ 3 ], 16)
  } : null;

};

const lightOrDark = (hex) => {


  const { r, g, b } = hexToRgb(hex, false);

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark

  return (hsp > 127.5) ? "black" : "white";

}

const randomHex = () => {
  let rand = Math.floor(Math.random() * 16777215).toString(16);

  if (rand.length < 6)
  {
    for (let i = 0, y = 6 - rand.length; i < y; i++)
    {
      rand += "0";
    }
  }

  return rand;
};

const hex = {
  random: randomHex,
  toRgb: hexToRgb,
  lightOrDark
};

export default hex;
