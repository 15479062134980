import React from "react";
import NormalEventContactForm from './normal-event-contact-form';
import useMutationEeMetaSet from '../hooks/use-mutation-exbn-evnt-meta-set';
import { EXBN_EVNT_META_SET } from '../../../../graphql/mutation/exhibition-gql';

import './event-contact-form.scss';




const EventContactForm = ({ exbnEvnt, loadingQueryEvent }) => {


  const {
    _setMutationVariableEeMetaSet,
    loadingMutationEeMetaSet
  } = useMutationEeMetaSet(EXBN_EVNT_META_SET, exbnEvnt);


  return (
    <NormalEventContactForm
      exbnEvnt={ exbnEvnt }
      loadingQueryEvent={ loadingQueryEvent }
      setMutationVariableEeMetaSet={ _setMutationVariableEeMetaSet }
      loadingMutationEeMetaSet={ loadingMutationEeMetaSet }
    />
  );
};

export default EventContactForm;
