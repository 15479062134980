import React from 'react';
import Icon from '@ant-design/icons';

const AddedFilesSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 9 11">
        <path d="M5.7,0H0v11h9V3.6L5.7,0z M4.9,4.3V0.8l3.3,3.5H4.9z" />
    </svg>
);

const ImageFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"

    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39 13.8l-8.7-8.6c-.2-.2-.4-.3-.6-.3H10.9c-.5 0-.9.4-.9.9v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.4c0-.2-.1-.4-.3-.6zm-8.4-5.9l5.7 5.6h-5.7V7.9zM11.7 41.2V6.7h17.2v7.8c0 .5.4.9.9.9h7.8v25.9H11.7z"
        />
        <path
            d="M21.7 18.8H7.1c-1.4 0-2.6-1.1-2.6-2.6v-4.8c0-1.4 1.1-2.6 2.6-2.6h14.6c1.4 0 2.6 1.1 2.6 2.6v4.8c-.1 1.5-1.2 2.6-2.6 2.6z"
            fill="#a9cf38"
        />
        <path
            d="M10.9 14.6c0 .3 0 .5-.1.7-.1.2-.2.4-.3.5-.1.1-.3.3-.5.3-.2.1-.4.1-.7.1H9c-.1 0-.2 0-.3-.1v-.5s0-.1.1-.1h.4c.1 0 .2 0 .3-.1.1 0 .2-.1.2-.2.1-.1.1-.2.1-.3v-3.4h.8v3.1zM12.7 14.6v1.6h-.8v-4.6h1.5c.3 0 .6 0 .8.1l.6.3c.1.1.3.3.3.5.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.6.3-.2.1-.5.1-.8.1h-.7zm0-.7h.6c.2 0 .3 0 .4-.1.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.4-.1h-.6v1.8zM17.9 15.6c.2 0 .4 0 .5-.1.1 0 .3-.1.4-.1v-.8h-.7V14h1.5v1.8c-.1.1-.2.2-.4.2-.1.1-.3.1-.4.2-.1 0-.3.1-.5.1h-.5c-.3 0-.6-.1-.9-.2-.3-.1-.5-.3-.7-.5-.2-.2-.4-.5-.5-.7-.1-.3-.2-.6-.2-.9 0-.3.1-.7.2-.9.1-.3.3-.5.5-.7.2-.2.5-.4.7-.5.3-.1.6-.2 1-.2s.7.1 1 .2c.3.1.5.3.7.4l-.2.4c0 .1-.1.1-.2.1H19c-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1-.2-.1-.3-.1h-.4c-.2 0-.4 0-.6.1-.2.1-.3.2-.5.3-.1.1-.2.3-.3.5s-.1.4-.1.7c0 .3 0 .5.1.7.1.2.2.4.3.5.1.1.3.2.5.3h.6z"
            fill="#fff"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M32.3 35H17.4c-.3 0-.6-.2-.8-.4-.2-.3-.1-.6 0-.9l4.2-6.8c.2-.2.4-.4.7-.4.3 0 .6.1.7.4l1.7 2.3 2.8-4.4c.2-.3.5-.4.8-.4.3 0 .6.2.7.5l4.8 8.9c.1.3.1.6 0 .9-.1.1-.4.3-.7.3zM19 33.3h11.9l-3.4-6.4-2.3 3.7.3.4c.3.4.2.9-.2 1.2-.4.3-.9.2-1.2-.2l-2.3-3.2-2.8 4.5zM22 25.7c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5zm0-3.2c-.4 0-.7.3-.7.7s.3.8.7.8.7-.3.7-.7-.3-.8-.7-.8z"
        />
    </svg>
);


const PngImageFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"

    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39.1 13.9l-8.7-8.6c-.2-.2-.4-.3-.6-.3H11c-.5 0-.9.4-.9.9v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.6c0-.3-.1-.5-.3-.7zm-8.4-5.8l5.7 5.6h-5.7V8.1zM11.8 41.3V6.8H29v7.8c0 .5.4.9.9.9h7.8v25.9H11.8z"
        />
        <path
            d="M21.8 18.9H7.2c-1.4 0-2.6-1.1-2.6-2.6v-4.8C4.6 10.2 5.8 9 7.2 9h14.6c1.4 0 2.6 1.1 2.6 2.6v4.8c-.1 1.4-1.2 2.5-2.6 2.5z"
            fill="#2cbdb7"
        />
        <path
            d="M9.2 14.7v1.6h-.8v-4.6h1.5c.3 0 .6 0 .8.1l.6.3c.1.1.3.3.3.5.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.6.3-.2.1-.5.1-.8.1h-.7zm0-.6h.6c.2 0 .3 0 .4-.1.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.4-.1h-.6v1.8zM12.8 11.8c.1 0 .1 0 .1.1l.1.1 2.4 3v-.2-3h.7v4.6H15.5l-.1-.1-2.4-3V16.5h-.7v-4.6l.5-.1c-.1 0 0 0 0 0zM19.1 15.7c.2 0 .4 0 .5-.1s.3-.1.4-.1v-.8h-.7v-.6h1.5v1.8c-.1.1-.2.2-.4.2-.1.1-.3.1-.4.2-.1 0-.3.1-.5.1H19c-.3 0-.6-.1-.9-.2-.3-.1-.5-.3-.7-.5-.2-.2-.4-.5-.5-.7-.1-.3-.2-.6-.2-.9 0-.3.1-.7.2-.9.1-.3.3-.5.5-.7.2-.2.5-.4.7-.5.3-.1.6-.2 1-.2s.7.1 1 .2c.3.1.5.3.7.4l-.2.4c0 .1-.1.1-.2.1h-.2c-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1-.2-.1-.3-.1h-.4c-.2 0-.4 0-.6.1-.2.1-.3.2-.5.3-.1.1-.2.3-.3.5s-.1.4-.1.7c0 .3 0 .5.1.7.1.2.2.4.3.5.1.1.3.2.5.3h.6z"
            fill="#fff"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M32.4 35.1H17.5c-.3 0-.6-.2-.8-.4-.2-.3-.1-.6 0-.9L21 27c.2-.2.4-.4.7-.4.3 0 .6.1.7.4l1.7 2.3 2.8-4.4c.2-.3.5-.4.8-.4.3 0 .6.2.7.5l4.8 8.9c.1.3.1.6 0 .9s-.5.3-.8.3zm-13.3-1.7H31L27.6 27l-2.3 3.7.3.4c.3.4.2.9-.2 1.2-.4.3-.9.2-1.2-.2L21.8 29l-2.7 4.4zM22.1 25.8c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5zm0-3.2c-.4 0-.7.3-.7.7s.3.7.7.7.7-.3.7-.7-.3-.7-.7-.7z"
        />
    </svg>
);


const VideoFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"

    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39.1 13.9l-8.7-8.6c-.2-.2-.4-.3-.6-.3H11c-.5 0-.9.4-.9.9v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.5c0-.3-.1-.5-.3-.6zM30.7 8l5.7 5.6h-5.7V8zM11.8 41.2V6.7H29v7.8c0 .5.4.9.9.9h7.8v25.9H11.8z"
        />
        <path
            d="M22.6 18.9H7.2c-1.4 0-2.6-1.1-2.6-2.6v-4.8c0-1.4 1.1-2.6 2.6-2.6h15.4c1.4 0 2.6 1.1 2.6 2.6v4.8c0 1.4-1.2 2.6-2.6 2.6z"
            fill="#b93192"
        />
        <path
            d="M10.1 14.6c0 .1.1.1.1.2 0-.1.1-.2.1-.2 0-.1.1-.1.1-.2l1.3-2.5s0-.1.1-.1h.9v4.6H12v-2.9-.2-.2l-1.4 2.6c0 .1-.1.1-.1.1H10c-.1 0-.1-.1-.1-.1l-1.4-2.6v3.3h-.7v-4.6H8.7l.1.1 1.4 2.5c-.1 0-.1.1-.1.2zM18 14c0 .3-.1.6-.2.9-.1.3-.3.5-.5.7-.2.2-.4.4-.7.5-.3.1-.6.2-.9.2-.3 0-.7-.1-.9-.2-.3-.1-.5-.3-.7-.5-.2-.2-.4-.5-.5-.7-.1-.3-.2-.6-.2-.9s.1-.6.2-.9c.1-.3.3-.5.5-.7.2-.2.4-.4.7-.5.3-.1.6-.2.9-.2.3 0 .7.1.9.2.3.1.5.3.7.5.2.2.4.5.5.7.1.2.2.5.2.9zm-.9 0c0-.3 0-.5-.1-.7-.1-.2-.2-.4-.3-.5s-.3-.2-.5-.3c-.2-.1-.4-.1-.6-.1s-.4 0-.6.1c-.2.1-.3.2-.5.3-.1.1-.2.3-.3.5-.1.2-.1.4-.1.7 0 .2 0 .5.1.7.1.2.2.4.3.5.1.1.3.2.5.3.2.1.4.1.6.1s.4 0 .6-.1c.2-.1.3-.2.5-.3.1-.1.2-.3.3-.5.1-.2.1-.5.1-.7zM18 11.7h.7c.1 0 .1 0 .2.1l.1.1 1.1 2.8c0 .1.1.2.1.3 0 .1.1.2.1.3 0-.2.1-.5.2-.6l1.1-2.8.1-.1s.1-.1.2-.1h.7l-1.8 4.6H20l-2-4.6z"
            fill="#fff"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M24.9 37.2c-4.5 0-8.2-3.7-8.2-8.2 0-4.5 3.7-8.2 8.2-8.2s8.2 3.7 8.2 8.2c0 4.5-3.7 8.2-8.2 8.2zm0-14.7c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-3-6.5-6.5-6.5z"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M23.5 32.2c-.1 0-.3 0-.4-.1-.3-.2-.4-.4-.4-.7v-4.6c0-.3.2-.6.4-.7.3-.2.6-.2.9 0l4 2.3c.3.2.4.4.4.7 0 .3-.2.6-.4.7l-4 2.3c-.2.1-.4.1-.5.1zm.8-4v1.6l1.4-.8-1.4-.8z"
        />
    </svg>
);

const MpegVideoFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"
    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39 13.9l-8.7-8.6c-.1-.2-.3-.3-.6-.3H10.9c-.5 0-.9.4-.9.8v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.5c0-.2-.1-.5-.3-.6zM30.7 8l5.7 5.6h-5.7V8zm-19 33.3V6.7h17.2v7.8c0 .5.4.9.9.9h7.8v25.9H11.7z"
        />
        <path
            d="M21.7 18.9H7.1c-1.4 0-2.6-1.1-2.6-2.6v-4.8c0-1.4 1.1-2.6 2.6-2.6h14.6c1.4 0 2.6 1.1 2.6 2.6v4.8c0 1.4-1.2 2.6-2.6 2.6z"
            fill="#7b3594"
        />
        <path
            d="M10.1 14.6c0 .1.1.1.1.2 0-.1.1-.2.1-.2 0-.1.1-.1.1-.2l1.3-2.5s0-.1.1-.1h.9v4.6H12v-2.9-.2-.2l-1.4 2.6c0 .1-.1.1-.1.1H10c-.1 0-.1-.1-.1-.1l-1.4-2.6v3.3h-.8v-4.6H8.6l.1.1 1.4 2.5c-.1 0-.1.1 0 .2zM14.4 14.7v1.6h-.8v-4.6h1.5c.3 0 .6 0 .8.1l.6.3c.1.1.3.3.3.5.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.6.3-.2.1-.5.1-.8.1h-.7zm0-.7h.6c.2 0 .3 0 .4-.1.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.4-.1h-.6V14zM20 14.6h.6v.5H20v1.1h-.7v-1.1h-2l-.1-.1-.1-.4 2.2-2.9h.7v2.9zm-.7-1.5v-.2-.2l-1.4 1.9h1.4v-1.5z"
            fill="#fff"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M24.8 37.2c-4.5 0-8.2-3.7-8.2-8.2 0-4.5 3.7-8.2 8.2-8.2 4.5 0 8.2 3.7 8.2 8.2 0 4.6-3.7 8.2-8.2 8.2zm0-14.7c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-2.9-6.5-6.5-6.5z"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M23.4 32.2c-.1 0-.3 0-.4-.1-.3-.2-.4-.4-.4-.7v-4.6c0-.3.2-.6.4-.7.3-.2.6-.2.9 0l4 2.3c.3.2.4.4.4.7 0 .3-.2.6-.4.7l-4 2.3c-.2.1-.3.1-.5.1zm.9-4v1.6l1.4-.8-1.4-.8z"
        />
    </svg>
);


const AudioFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"

    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39.1 13.9l-8.7-8.6c-.2-.2-.4-.3-.7-.3H10.9c-.5 0-.9.4-.9.9v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.5c0-.2-.1-.4-.2-.6zM30.7 8l5.7 5.6h-5.7V8zM11.8 41.3V6.7H29v7.8c0 .5.4.9.9.9h7.8v25.9H11.8z"
        />
        <path
            d="M21.7 18.9H7.1c-1.4 0-2.6-1.1-2.6-2.6v-4.8C4.6 10.1 5.7 9 7.1 9h14.6c1.4 0 2.6 1.1 2.6 2.6v4.8c0 1.4-1.2 2.5-2.6 2.5z"
            fill="#f99d1c"
        />
        <path
            d="M10.1 14.6c0 .1.1.1.1.2 0-.1.1-.2.1-.2 0-.1.1-.1.1-.2l1.3-2.5s0-.1.1-.1h.9v4.6H12v-2.9-.2-.2l-1.4 2.6c0 .1-.1.1-.1.1H10c-.1 0-.1-.1-.1-.1L8.4 13v3.3h-.7v-4.6H8.6l.1.1 1.4 2.5c-.1.2 0 .3 0 .3zM14.4 14.7v1.6h-.8v-4.6h1.5c.3 0 .6 0 .8.1l.6.3c.1.1.3.3.3.5.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.6.3-.2.1-.5.1-.8.1h-.7zm0-.7h.6c.2 0 .3 0 .4-.1.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.4-.1h-.6V14zM19 11.7c.2 0 .4 0 .6.1.2.1.3.1.4.2.1.1.2.2.3.4.1.1.1.3.1.5v.4c0 .1-.1.2-.1.3-.1.1-.1.2-.2.2s-.2.1-.3.1c.3.1.5.2.6.4s.2.4.2.7c0 .2 0 .4-.1.6-.1.2-.2.3-.3.4-.1.1-.3.2-.5.3-.2.1-.4.1-.6.1-.2 0-.4 0-.6-.1-.2-.1-.3-.1-.4-.2s-.2-.2-.3-.4c-.1-.1-.2-.3-.2-.5l.3-.1H18.2l.1.1c0 .1.1.1.1.2s.1.1.2.2.1.1.2.1.2.1.3.1c.1 0 .2 0 .3-.1.1 0 .2-.1.2-.2.1-.1.1-.1.1-.2V15v-.3c0-.1-.1-.2-.1-.2-.1-.1-.2-.1-.3-.1-.1 0-.3-.1-.6-.1v-.6c.2 0 .3 0 .5-.1.1 0 .2-.1.3-.1l.2-.2V13c0-.2-.1-.4-.2-.5-.1-.1-.3-.2-.5-.2s-.3 0-.5.1c-.1.1-.2.2-.3.4 0 .1-.1.1-.1.2 0 0-.1.1-.2.1h-.1l-.3-.1c0-.2.1-.4.2-.6.1-.2.2-.3.3-.4.1-.1.3-.2.5-.2s.3-.1.5-.1z"
            fill="#fff"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M30.6 21.4c-.2-.2-.5-.2-.7-.2l-8.7 1.7c-.4.1-.7.4-.7.8v7.4c-.3-.1-.6-.2-.9-.2-1.4 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6c1.4 0 2.5-1.1 2.6-2.4v-9.3l7-1.4v6.1c-.3-.1-.6-.1-.9-.1-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.2 2.6 2.6 2.6 1.3 0 2.4-.9 2.6-2.2V22c0-.2-.1-.5-.3-.6zm-11 13.1c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9zm8.7-2c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9z"
        />
    </svg>
);

const WordFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"

    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39.1 13.9l-8.7-8.6c-.1-.2-.3-.3-.6-.3H11c-.5 0-.9.4-.9.9v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.5c0-.3-.1-.5-.3-.6zM30.7 8l5.7 5.6h-5.7V8zM11.8 41.2V6.7H29v7.8c0 .5.4.9.9.9h7.8v25.9H11.8z"
        />
        <path
            d="M21.8 18.9H7.2c-1.4 0-2.6-1.1-2.6-2.6v-4.8c0-1.4 1.1-2.6 2.6-2.6h14.6c1.4 0 2.6 1.1 2.6 2.6v4.8c-.1 1.4-1.2 2.6-2.6 2.6z"
            fill="#008ed1"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M28.2 23.5H15.7c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h12.5c.5 0 .9.4.9.9s-.5.9-.9.9zM33.9 27.4H15.7c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h18.2c.5 0 .9.4.9.9s-.5.9-.9.9zM33.9 31.3H15.7c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h18.2c.5 0 .9.4.9.9-.1.5-.5.9-.9.9zM33.9 35.2H15.7c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h18.2c.5 0 .9.4.9.9-.1.5-.5.9-.9.9z"
        />
        <path
            d="M11.8 14c0 .3-.1.6-.2.9-.1.3-.3.5-.5.7-.2.2-.4.4-.7.5-.3.1-.6.2-.9.2H7.8v-4.6h1.7c.3 0 .7.1.9.2.3.1.5.3.7.5.2.2.4.4.5.7.2.2.2.5.2.9zm-.8 0c0-.3 0-.5-.1-.7-.1-.2-.2-.4-.3-.5-.1-.1-.3-.2-.5-.3-.2-.1-.4-.1-.6-.1h-.9v3.2h.9c.2 0 .4 0 .6-.1.2-.1.3-.2.5-.3.1-.1.2-.3.3-.5 0-.2.1-.5.1-.7zM16.9 14c0 .3-.1.6-.2.9-.1.3-.3.5-.5.7-.2.2-.4.4-.7.5-.3.1-.6.2-.9.2-.3 0-.7-.1-.9-.2-.3-.1-.5-.3-.7-.5-.2-.2-.4-.5-.5-.7-.1-.3-.2-.6-.2-.9s.1-.6.2-.9c.1-.3.3-.5.5-.7.2-.2.4-.4.7-.5.3-.1.6-.2.9-.2.3 0 .7.1.9.2.3.1.5.3.7.5.2.2.4.5.5.7.1.2.2.5.2.9zm-.9 0c0-.3 0-.5-.1-.7-.1-.2-.2-.4-.3-.5-.1-.1-.3-.2-.5-.3-.2-.1-.4-.1-.6-.1s-.4 0-.6.1c-.2.1-.3.2-.5.3-.1.1-.2.3-.3.5-.1.2-.1.4-.1.7 0 .2 0 .5.1.7.1.2.2.4.3.5.1.1.3.2.5.3.2.1.4.1.6.1s.4 0 .6-.1c.2-.1.3-.2.5-.3.1-.1.2-.3.3-.5.1-.2.1-.5.1-.7zM20.7 15.2s.1 0 .1.1l.3.4c-.2.2-.4.4-.7.5-.3.1-.6.2-1 .2-.3 0-.6-.1-.9-.2-.3-.1-.5-.3-.7-.5-.2-.2-.3-.5-.4-.7-.1-.3-.2-.6-.2-.9 0-.3.1-.7.2-.9.1-.3.3-.5.5-.7.2-.2.5-.4.7-.5.3-.1.6-.2.9-.2.3 0 .6.1.9.2.3.1.5.3.7.4l-.3.4-.1.1c-.1.1-.1 0-.1 0s-.1 0-.1-.1c0 0-.1-.1-.2-.1s-.2-.1-.3-.1c-.1 0-.3-.1-.5-.1s-.4 0-.6.1c-.2.1-.3.2-.5.3-.1.1-.2.3-.3.5-.1.2-.1.4-.1.7 0 .3 0 .5.1.7.1.2.2.4.3.5.1.1.3.2.4.3s.3.1.5.1h.3c.1 0 .2 0 .3-.1.1 0 .2-.1.2-.1.1 0 .1-.1.2-.2.2-.1.3-.1.4-.1-.1 0-.1 0 0 0z"
            fill="#fff"
        />
    </svg>
);

const ExcelFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"

    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39.2 13.9l-8.7-8.6c-.2-.2-.4-.3-.6-.3H11c-.5 0-.9.4-.9.9v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.5c0-.2-.1-.5-.2-.6zM30.8 8l5.7 5.6h-5.7V8zM11.9 41.3V6.7H29v7.8c0 .5.4.9.9.9h7.8v25.9H11.9z"
        />
        <path
            d="M21.8 18.9H7.3c-1.4 0-2.6-1.1-2.6-2.6v-4.8c0-1.4 1.1-2.6 2.6-2.6h14.6c1.4 0 2.6 1.1 2.6 2.6v4.8c-.1 1.4-1.3 2.6-2.7 2.6z"
            fill="#008e47"
        />
        <path
            d="M10.8 13.9l-1.5-2.2h.9l.1.1 1 1.7v-.1-.1l.9-1.5c0-.1.1-.1.2-.1h.8l-1.5 2.2 1.5 2.4h-.9l-.1-.1-1.1-1.7v.2l-1 1.6-.1.1h-.7l1.5-2.5zM14.8 15.6h1.8v.7H14v-4.6h.8v3.9zM19.7 12.6c-.1 0-.1 0 0 0-.1.1-.1.1-.2.1h-.1c-.1 0-.1-.1-.2-.1s-.2-.1-.3-.1h-.6c-.1 0-.2.1-.2.1s-.1.1-.1.2v.2c0 .1 0 .2.1.3l.2.2c.1.1.2.1.3.1.1 0 .3.1.4.1.1 0 .3.1.4.2.1.1.2.1.3.2.1.1.2.2.2.3s.1.3.1.5 0 .4-.1.6c-.1.2-.2.3-.3.5-.1.1-.3.2-.5.3-.2.1-.4.1-.7.1H18c-.1 0-.3-.1-.4-.1-.1-.1-.3-.1-.4-.2-.1-.1-.2-.2-.3-.2l.2-.4.1-.1h.1c.1 0 .1 0 .2.1.1 0 .1.1.2.1.1.1.2.1.3.1.1 0 .2.1.4.1s.4-.1.6-.2c.1-.1.2-.3.2-.5 0-.1 0-.2-.1-.3l-.2-.2c-.1-.1-.2-.1-.3-.1-.1 0-.3-.1-.4-.1-.1 0-.3-.1-.4-.2-.1-.1-.2-.1-.3-.2-.1-.1-.2-.2-.2-.4-.2-.2-.3-.4-.3-.6 0-.2 0-.3.1-.5s.2-.3.3-.4c.1-.1.3-.2.5-.3.2-.1.4-.1.6-.1.3 0 .5 0 .7.1.2.1.4.2.6.4l-.1.4z"
            fill="#fff"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M32 22.3H17.7c-.5 0-.9.4-.9.9V36c0 .5.4.9.9.9H32c.5 0 .9-.4.9-.9V23.1c0-.4-.4-.8-.9-.8zm-.9 4.1H23V24h8.1v2.4zM23 28.1h8.1v2.4H23v-2.4zm-1.7 2.4h-2.8v-2.4h2.8v2.4zm0-6.5v2.4h-2.8V24h2.8zm-2.7 8.3h2.8V35h-2.8v-2.7zM23 35v-2.8h8.1V35H23z"
        />
    </svg>
);

const PdfFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"

    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M33.9 24h-8.1c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h8.1c.5 0 .9.4.9.9s-.4.9-.9.9zM33.9 27.9h-8.1c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h8.1c.5 0 .9.4.9.9s-.4.9-.9.9zM33.9 31.8h-8.1c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h8.1c.5 0 .9.4.9.9s-.4.9-.9.9zM33.9 35.8H15.8c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h18.1c.5 0 .9.4.9.9s-.4.9-.9.9z"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39.1 13.9l-8.7-8.6c-.2-.2-.4-.3-.6-.3H10.9c-.5 0-.9.4-.9.9v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.5c0-.2-.1-.4-.2-.6zM30.7 8l5.7 5.6h-5.7V8zM11.8 41.3V6.7H29v7.8c0 .5.4.9.9.9h7.8v25.9H11.8z"
        />
        <path
            d="M21.7 18.9H7.2c-1.4 0-2.6-1.1-2.6-2.6v-4.8C4.6 10.1 5.8 9 7.2 9h14.6c1.4 0 2.6 1.1 2.6 2.6v4.8c-.1 1.3-1.3 2.5-2.7 2.5z"
            fill="#ed1c24"
        />
        <path
            d="M9.4 14.7v1.6h-.9v-4.6H10c.3 0 .6 0 .8.1l.6.3c.1.1.3.3.3.5.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.6.3-.2.1-.5.1-.8.1h-.6zm0-.7h.6c.2 0 .3 0 .4-.1.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.4-.1h-.6V14zM16.5 14c0 .3-.1.6-.2.9-.1.3-.3.5-.5.7-.2.2-.4.4-.7.5s-.6.2-.9.2h-1.7v-4.6h1.7c.3 0 .7.1.9.2.3.1.5.3.7.5.2.2.4.4.5.7.1.3.2.6.2.9zm-.9 0c0-.3 0-.5-.1-.7-.1-.2-.2-.4-.3-.5-.1-.1-.3-.2-.5-.3-.2-.1-.4-.1-.6-.1h-.9v3.2h.9c.2 0 .4 0 .6-.1.2-.1.3-.2.5-.3.1-.1.2-.3.3-.5.1-.2.1-.4.1-.7zM20 11.7v.7h-2v1.3h1.7v.7H18v1.9h-.9v-4.6H20z"
            fill="#fff"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M22.2 31.9h-6.7c-.5 0-.9-.4-.9-.9v-8c0-.5.4-.9.9-.9h6.7c.5 0 .9.4.9.9v8c0 .5-.4.9-.9.9zm-5.8-1.7h5v-6.3h-5v6.3z"
        />
    </svg>
);


const PptFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"
    >
        <path
            className="st0"
            fill="#c3c3c3"
            d="M39.1 13.9l-8.7-8.6c-.2-.2-.4-.3-.6-.3H10.9c-.5 0-.9.4-.9.9v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.5c0-.2-.1-.4-.2-.6zM30.7 8l5.7 5.6h-5.7V8zM11.8 41.3V6.8H29v7.8c0 .5.4.9.9.9h7.8v25.9H11.8z"
        />
        <path
            d="M21.7 18.9H7.2c-1.4 0-2.6-1.1-2.6-2.6v-4.8C4.6 10.1 5.8 9 7.2 9h14.6c1.4 0 2.6 1.1 2.6 2.6v4.8c-.1 1.4-1.3 2.5-2.7 2.5z"
            fill="#c6451b"
        />
        <path
            d="M10.3 14.7v1.6h-.8v-4.6H11c.3 0 .6 0 .8.1l.6.3c.1.1.3.3.3.5.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.6.3-.2.1-.5.1-.8.1h-.7zm0-.7h.7c.2 0 .3 0 .4-.1.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.4-.1h-.6V14zM14.3 14.7v1.6h-.8v-4.6H15c.3 0 .6 0 .8.1l.6.3c.1.1.3.3.3.5.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.6.3-.2.1-.5.1-.8.1h-.7zm0-.7h.6c.2 0 .3 0 .4-.1.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.4-.1h-.6V14zM20.5 11.8v.7h-1.4v3.9h-.8v-3.9h-1.4v-.7h3.6z"
            fill="#fff"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M30.7 26.3c-.3 0-.7-.2-.8-.5-.5-1.2-1.5-2.3-2.7-2.8-.4-.2-.6-.7-.4-1.2.2-.4.7-.6 1.2-.4 1.6.8 2.9 2.1 3.5 3.7.2.4 0 .9-.5 1.1-.1.1-.2.1-.3.1z"
        />
        <path
            className="st0"
            fill="#c3c3c3"
            d="M24.9 35.2c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2h.1c.5 0 .8.4.8.9v5.6h5.4c.2 0 .5.1.6.3.2.2.3.4.2.6 0 3.8-3.2 7-7.1 7zm-.8-12.7c-2.7.4-4.7 2.7-4.7 5.5 0 3 2.5 5.5 5.5 5.5 2.7 0 5-2 5.4-4.5H25c-.5 0-.9-.4-.9-.9v-5.6z"
        />
    </svg>
);


const ZipFileSvg = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
        enableBackground="new 0 0 48 48"

    >
        <path
            d="M39 13.8l-8.7-8.6c-.1-.2-.3-.2-.6-.2H10.9c-.5 0-.9.3-.9.8v36.3c0 .5.4.9.9.9h27.5c.5 0 .9-.4.9-.9V14.5c0-.3-.1-.5-.3-.7zM30.7 8l5.7 5.6h-5.7V8zm-11.9 3.3h-1.3c-.5 0-.9.4-.9.9s.4.9.9.9h1.3v10.3h-1.3c-.5 0-.9.4-.9.9s.4.7.9.7h1.3v4.3h-1.3c-.5 0-.9.4-.9.9s.4.9.9.9h1.3v4.3h-1.3c-.5 0-.9.4-.9.9s.4.9.9.9h1.3v4.2h-3.1v-1.1h.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-.8v-4.3h.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-.8v-4.3h.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-.8V22h.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-.8v-4.3h.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-.8V6.7h3.1v4.6zm-7.1-4.6h2.2v34.5h-2.2V6.7zm25.9 34.5H20.5V6.7h8.4v7.8c0 .5.4.9.9.9h7.8v25.8z"
            fill="#c3c3c3"
        />
        <path
            d="M21.9 18.6H7.3c-1.4 0-2.6-1.1-2.6-2.6v-4.8c0-1.4 1.1-2.6 2.6-2.6h14.6c1.4 0 2.6 1.1 2.6 2.6V16c-.1 1.5-1.2 2.6-2.6 2.6z"
            fill="#888a8c"
        />
        <path
            d="M13.4 11.5v.3c0 .1 0 .2-.1.3L11 15.3h2.4v.7H9.9v-.3-.1s0-.1.1-.1l2.3-3.3H10v-.7h3.4zM15 16h-.9v-4.6h.9V16zM16.9 14.4V16h-.8v-4.6h1.5c.3 0 .6 0 .8.1l.6.3c.2.1.3.3.3.5.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.6.3-.2.1-.5.1-.8.1h-.7zm0-.6h.6c.2 0 .3 0 .4-.1.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.4-.1h-.6v1.8z"
            fill="#fff"
        />
    </svg>
);



const FilesIcon = props => {

    if (props.type === "image")
    {
        return <Icon component={ ImageFileSvg } />;
    }

    if (props.type === "png-image")
    {
        return <Icon component={ PngImageFileSvg } />;
    }

    if (props.type === "video")
    {
        return <Icon component={ VideoFileSvg } />;
    }

    if (props.type === "mp4-video")
    {
        return <Icon component={ MpegVideoFileSvg } />;
    }


    if (props.type === "audio")
    {
        return <Icon component={ AudioFileSvg } />;
    }

    if (props.type === "word")
    {
        return <Icon component={ WordFileSvg } />;
    }

    if (props.type === "excel")
    {
        return <Icon component={ ExcelFileSvg } />;
    }

    if (props.type === "pdf")
    {
        return <Icon component={ PdfFileSvg } />;
    }

    if (props.type === "ppt")
    {
        return <Icon component={ PptFileSvg } />;
    }

    if (props.type === "file-zip")
    {
        return <Icon component={ ZipFileSvg } />;
    }

    return <Icon component={ AddedFilesSvg } />;
};


export default FilesIcon;