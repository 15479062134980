import React from "react";
import NormalLanguageForm from './normal-language-form';
import useMutationLangCreate from './hooks/use-mutation-lang-create';


import {
  LANG_CRTE_UPDTE
} from '../../../../graphql/mutation/languages-gql'


import './language-form.scss';



const LanguageForm = ({ dataQuery, loadingQuery, setModalVisible }) => {

  const {
    _setMutationVariableCreate,
    loadingMutationCreate,

  } = useMutationLangCreate(LANG_CRTE_UPDTE, dataQuery);

  return (
    <NormalLanguageForm
      dataQuery={ dataQuery }
      loadingQuery={ loadingQuery }
      loadingMutationCreate={ loadingMutationCreate }
      setMutationVariableCreate={ _setMutationVariableCreate }
      setModalVisible={ setModalVisible }
    />
  );
};


export default LanguageForm;
