import { useMutation } from "@apollo/client";
import { successNotification } from "../../../result";
import metaFields from '../../../../utils/meta-fields';


const useMutationMetaEeUpload = (mutation, dataQuery, form) => {

  const { normalize } = metaFields

  const [ _setMutationVariableEeUpload,
    { loading } ] = useMutation(mutation,
      {
        update(cache, { data }) {
          const {
            eeMetaUpload: {
              label,
              message,
              exhibition_event: {
                meta_fields
              }
            }
          } = data;

          const meta = normalize(meta_fields);

          cache.modify({
            id: cache.identify(dataQuery),
            fields: {

              meta_fields() {
                return meta_fields;
              },
            },
          });



          form.setFieldsValue({
            "ee_login_bg": meta.ee_logo.meta_value,
            "ee_logo": meta.ee_logo.meta_value
          });

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        },
      });


  return {
    _setMutationVariableEeUpload,
    loadingMutationEeUpload: loading,
  }
};

export default useMutationMetaEeUpload;