import React from "react";
import NormalAddStandUserForm from './normal-add-stand-user-form';
import useMutationStandCreateUpdate from '../hooks/use-mutation-stand-create-update';



import {
  STAND_CREATE_UPDATE
} from '../../../../graphql/mutation/showroom-gql'


import './add-stand-user-form.scss';



const AddStandUserForm = ({ dataQueryStand, setModalVisible }) => {

  const {
    _setMutationVariableCreateUpdate,
    loadingMutationCreateUpdate,

  } = useMutationStandCreateUpdate(STAND_CREATE_UPDATE, dataQueryStand);

  return (
    <NormalAddStandUserForm
      dataQueryStand={ dataQueryStand }
      loadingMutationCreateUpdate={ loadingMutationCreateUpdate }
      setMutationVariableCreateUpdate={ _setMutationVariableCreateUpdate }
      setModalVisible={ setModalVisible }
    />
  );
};


export default AddStandUserForm;
