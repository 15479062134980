import React from "react";
import ProductsTable from "./products-table";
import SearchForm from "../../forms/search-form"
import { Layout } from 'antd';
import { GET_PRODUCTS } from "../../../graphql/query/products-gql";
import useQueryProducts from './hooks/use-query-products';

import './products.scss';


const { Sider, Content } = Layout;

const Products = () => {

  const {
    loadingQuery,
    dataQuery,
    history,
    setSearchVal,
    routePage,
    pagination,
    handleTableChange
  } = useQueryProducts(GET_PRODUCTS);



  return (

    <>
      <Layout className="table-header" style={ { background: "white" } }>
        <Content>
          <h1>Products</h1>
        </Content>
        <Sider className="search-holder" style={ { background: "white" } }>
          <SearchForm setSearchVal={ setSearchVal } routePage={ routePage } history={ history } />
        </Sider>
      </Layout>
      <ProductsTable
        pagination={ pagination }
        dataQuery={ dataQuery }
        loadingQuery={ loadingQuery }
        handleTableChange={ handleTableChange }
      />
    </>
  );
};

export default Products;
