import React from "react";
import NormalProductPageForm from './normal-company-products-form';
import useMutationStandMetaSet from '../hooks/use-mutation-stand-meta-set';


import {
  STAND_META_SET
} from '../../../../graphql/mutation/showroom-gql'


const CompanyProductsForm = ({ dataQueryStand, loadingQueryStand }) => {

  const {
    _setMutationVariableStandMetaSet,
    loadingMutationStandMetaSet,

  } = useMutationStandMetaSet(STAND_META_SET, dataQueryStand);

  return (
    <>
      <NormalProductPageForm
        dataQueryStand={ dataQueryStand }
        loadingQueryStand={ loadingQueryStand }
        loadingMutationStandMetaSet={ loadingMutationStandMetaSet }
        setMutationVariableStandMetaSet={ _setMutationVariableStandMetaSet }
      />
    </>
  );
};


export default CompanyProductsForm;
