import { gql } from "@apollo/client";

export const LANG_CRTE_UPDTE = gql`
mutation LangCrteUpdte(
$langCrteUpdte: LanguageCreateOrUpdateInput!
) {
  languageCreateOrUpdate(input: $langCrteUpdte) {
    message
    label
    language {
      id
      label
      slug
      status
    }
  }
}
`;

export const TRNS_KEY_CREATE = gql`
mutation TranslationKeyCreate($key: String!, $description: String!, $type: translationTypeEnum!) {
  translationKeyCreate(key: $key, description: $description, type: $type) {
    label
    message
    translation_key {
      id
      key
      description
    }
  }
}
`;


export const TRNS_KEY_UPDATE = gql`
mutation TranslationKeyUpdate($input: TranslationKeyUpdateInput) {
  translationKeyUpdate(input: $input) {
    label
    message
    translation_key {
      id
      key
      description
    }
  }
}
`;

export const TRANSLATION_UPDATE = gql`
mutation TranslationUpdate($id: ID, $value: String, $tr_key: String, $locale: String) {
  translationUpdate(id: $id, value: $value, tr_key: $tr_key, locale: $locale) {
    translation_key {
      id
      value
    }
    label
    message
  }
}
`;

export const TRANSLATION_SYNC = gql`
mutation TranslationsSync($locale: String!, $type: translationTypeEnum!) {
  translationsSync(locale: $locale, type: $type) {
    label
    message
  }
}
`;


