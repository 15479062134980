import React, { useState, useEffect } from "react";
import { Form, Button, Input, Radio, Spin } from "antd";
import { errorNotification } from "../../../result";
// import RequestHandler from "../../../request-handler"
// import getFormMeta from './../../../../utils/meta-fields';

const { TextArea } = Input;

const NormalTranslationKeysForm = ({
  dataQuery,
  loadingQuery,
  loadingMutationCreate,
  // loadingMutationUpdate,
  setMutationVariableCreate,
  setMutationVariableUpdate,
  setModalVisible

}) => {


  const [ form ] = Form.useForm();

  const keyId = dataQuery && dataQuery.id;

  const initRadioVal = !!keyId ? dataQuery.type : 'admin';

  const [ radioValue, setRadioValue ] = useState(initRadioVal);

  useEffect(() => {
    setRadioValue(initRadioVal)
  }, [ initRadioVal ]);


  const radioOnChange = e => {
    setRadioValue(e.target.value);
  };

  const formSubmit = ({ setMutationVariableCreate, values }) => {

    form.resetFields();
    setModalVisible(false)

    const formFields = {
      id: keyId,
      key: values.key,
      description: values.description,
      type: values.type,
    }

    if (!keyId)
    {
      setMutationVariableCreate({
        variables: formFields
      }).catch((errorMutation) => {
        errorNotification(errorMutation);
      });
    } else
    {
      setMutationVariableUpdate({
        variables: {
          input: formFields
        }
      }).catch((errorMutation) => {
        errorNotification(errorMutation);
      });
    }
  };

  return (

    loadingQuery ? (
      <div className="block-loader h-300px" >
        <Spin size="small" />
      </div>
    ) : (
        <Form
          form={ form }
          onFinish={ (values) => {
            formSubmit({ setMutationVariableCreate, values });
          } }
          layout="vertical"
          className="translation-keys-form"
        >

          <Form.Item
            initialValue={ radioValue }
            name="type"
            label="Type"
            style={ { margin: "10px 0" } }>
            <Radio.Group className="radio-row" onChange={ radioOnChange } value={ radioValue }>
              <Radio value="admin">Admin</Radio>
              <Radio value="site">Site</Radio>
              <Radio value="core">Core</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            initialValue={ dataQuery && dataQuery.key }
            name="key"
            label="Key"
            rules={ [
              {
                type: 'string',
                // message: '',
              },
              {
                required: true,
                message: 'Please input translation key'
              },
              { min: 5, message: 'translation key must be minimum 5 characters.' },
              { max: 190, message: 'translation key must be maximum 191 characters.' },
            ] }
          >
            <Input
              maxLength={ 191 }
              placeholder="Enter translation key" />
          </Form.Item>

          <Form.Item
            initialValue={ dataQuery && dataQuery.description }
            name="description"
            label="Description"
          >
            <TextArea
              maxLength={ 191 }
              placeholder="Enter Description"
              autoSize={ { minRows: 3, maxRows: 5 } }
              showCount={
                { formatter: ({ count, maxLength }) => maxLength - count }
              }
            />
          </Form.Item>

          <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
            <Button loading={ loadingMutationCreate } type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
          </Form.Item>
        </Form>
      )
  );
};


export default NormalTranslationKeysForm;
