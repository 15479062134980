import React from "react";
import { Form, Button, Input, Radio } from "antd";
import { errorNotification } from "../../../result";
import RequestHandler from "../../../request-handler"
import metaFields from './../../../../utils/meta-fields';
const { TextArea } = Input;

const NormalTranslationKeyForm = ({
  dataQueryKey,
  dataQueryLang,
  errorQuery,
  loadingQuery,
  setMutationVariableCreate,
  setModalVisible,

}) => {



  const [ form ] = Form.useForm();

  const arrTypes = !!dataQueryKey && dataQueryKey.translation.map(
    item => item.type
  )
  const unionArrTypes = !!dataQueryKey && [ ...new Set([].concat(...arrTypes)) ];


  const formSubmit = ({ values }) => {

    let { metaValues } = metaFields.parseForm(values)

    setMutationVariableCreate({
      variables: {
        id: metaValues[ 0 ].meta_key !== 'undefined' ? metaValues[ 0 ].meta_key : undefined,
        value: metaValues[ 0 ].meta_value,
        locale: metaValues[ 1 ].meta_value,
        tr_key: metaValues[ 2 ].meta_value
      }
    }).catch((errorMutation) => {
      errorNotification(errorMutation);
    });

    form.resetFields();
    setModalVisible(false)
  };



  const arrForms = !!dataQueryLang && dataQueryLang.map(({ slug, label }, index) => {

    return (
      <Form
        key={ index }
        // form={ form }
        onFinish={ (values) => {
          formSubmit({ values });
        } }
        layout="vertical"
        className="_translations-form"
      >
        <Form.Item
          initialValue={
            dataQueryKey.translation[ index ]?.value
          }
          name={ `${ dataQueryKey.translation[ index ]?.id }-key_translation` }
          label={ `${ label }, ${ slug }` }
        >
          <TextArea
            maxLength={ 191 }
            placeholder="Translation text"
            autoSize={ { minRows: 2, maxRows: 5 } }
            showCount={
              { formatter: ({ count, maxLength }) => maxLength - count }
            }
          />
        </Form.Item>

        <Form.Item
          style={{position: 'absolute'}}
          disabled={ true }
          initialValue={
            slug
          }
          name={ `locale-key_translation` }
        >
        </Form.Item>

        <Form.Item
          style={{position: 'absolute'}}
          disabled={ true }
          initialValue={
            dataQueryKey.key
          }
          name={ `tr_key-key_translation` }
        >

        </Form.Item>

        <Form.Item wrapperCol={ { sm: { span: 16, offset: 0 } } } >
          <Button type="primary" htmlType="submit" style={ { marginTop: "10px" } }>Save</Button>
        </Form.Item>
      </Form>
    )
  }
  );

  return (

    <RequestHandler
      loading={ loadingQuery }
      error={ errorQuery }
      data={ dataQueryKey }
    >
      <>
        <Form
          layout="vertical"
        >
          <Form.Item
            initialValue={ `${ unionArrTypes }` }
            name="type"
            label="Type"
            style={ { margin: "10px 0" } }>
            <Radio.Group
              // disabled={ true }
              className="radio-row"
            >
              <Radio value="site">Site</Radio>
              <Radio value="admin">Admin</Radio>
              <Radio value="core">Core</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        <div>
          { arrForms }
        </div>

      </>
    </RequestHandler>
  );
};


export default NormalTranslationKeyForm;
