import React from 'react';
import { ExhibitionEventTabs } from '../components/tabs';


const ExhibitionEventPage = () => {

    return (
        <div className="page-holder bg-white">
            <ExhibitionEventTabs />
        </div>
    );
};

export default ExhibitionEventPage;
